import * as actionTypes from '../actions/actionTypes';
import resourceReducer from './resourceReducer';
import React from 'react';
import activityTypes from '../../constants/activityTypes'
import vendorTypes from '../../constants/vendorTypes';
import { createSelector } from 'reselect';
import formatDate from '../../utils/dateFormatter';
import { getEmptyComment } from '../../utils/commentUtils';
import { statuses } from '../../constants/changelogStatuses';
import consentTypes from '../../constants/YesNoConsent';

const initialResources = [{
    key: Math.random(),
    ref: React.createRef(),
}]
const initialState = {
    id: '',
    editMode: false,
    createdBy: '',
    submitting: false,
    domain: '',
    subDomain: '',
    activityType: '',
    consentType: {
        label: "NO",
        value: 0
    },
    status: '',
    changelogTags: [],
    lastModifiedBy: '',
    activitiesFilteredByExpenseType: [],
    activity: '',
    vendorType: '',
    isChangelogOwner: false,
    vendor: '',
    poWbs: '',
    attachments: [],
    year: '',
    rateYear: '',
    approver: '',
    costType: '',
    poLocation: '',
    clientId: null,
    supplierRepresentative: '',
    formLoaded: false,
    comments: [],
    history: [],
    poNumbers: [],
    dcs: [],
    resources: initialResources,
    resourcesThrottled: initialResources,
    comment: getEmptyComment(),
    scoutId: '',
    isConfirmed: false,
    startDateModalOpen: false,
    endDateModalOpen: false,
    validationCount: 0,
    changelogsWithGivenActivity: [],
    leanixdto: '',
    ePPMDto: '',
    errors: {
        expenseType: '',
        activity: '',
        vendorType: '',
        vendor: '',
        poWbs: '',
        year: '',
        rateYear: '',
        isConfirmed: '',
        resources: [],
        changes: null,
        showChanges: false,
        eppm: ''
    }
}

const getNewErrors = (state, value, errorProperty) => {
    const newErrors = { ...state.errors };
    if (value) {
        newErrors[errorProperty] = ''
    }
    return newErrors;
}

export const shouldComponentUpdate = (state) => {
    if (state.dataLoaded === undefined && state.formLoaded === true) {
        return false
    } else {
        return true;
    }
}

const changelogFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FAILED: {
            return {
                ...state,
                changelogsWithGivenActivity: [],
            }
        }
        case actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FINISHED: {
            return {
                ...state,
                changelogsWithGivenActivity: action.payload
            }
        }
        case actionTypes.CHANGELOG_TAGS_CHANGED: {
            return {
                ...state,
                changelogTags: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_LATEST_CHANGES_CHANGED: {
            return {
                ...state,
                changes: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_SHOW_CHANGES_CHANGED: {
            return {
                ...state,
                showChanges: action.payload
            }
        }
        case actionTypes.DELETE_CHANGELOG_DRAFT_STARTED: {
            return {
                ...state,
                submitting: true
            }
        }
        case actionTypes.DELETE_CHANGELOG_DRAFT_FAILED:
        case actionTypes.DELETE_CHANGELOG_DRAFT_FINISHED: {
            return {
                ...state,
                submitting: false
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_MODAL_CHANGED: {
            return {
                ...state,
                startDateModalOpen: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_MODAL_CHANGED: {
            return {
                ...state,
                endDateModalOpen: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_EDIT_MODE_CHANGED: {
            return {
                ...state,
                editMode: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_DATA_LOADED: {
            return {
                ...state,
                dataLoaded: true
            }
        }
        case actionTypes.CHANGELOG_FORM_SET_STATE: {
            return {
                ...state,
                ...action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_CHANGELOG_CREATED_BY_CHANGED: {
            return {
                ...state,
                createdBy: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_CLIENT_ID_CHANGED: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_COST_TYPE_CHANGED: {
            return {
                ...state,
                costType: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_PO_LOCATION_CHANGED: {
            return {
                ...state,
                poLocation: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_DOMAIN_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'domain'),
                domain: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_SUBDOMAIN_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'subDomain'),
                subDomain: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_ACTIVITY_TYPE_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'activityType'),
                activityType: {
                    label: activityTypes[parseInt(action.payload.value)],
                    value: parseInt(action.payload.value)
                }
            }
        }
        case actionTypes.CONSENT_TYPE_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'consentType'),
                consentType: {
                    label: consentTypes[parseInt(action.payload.value)],
                    value: parseInt(action.payload.value)

                }
            }
        }
        case actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_STARTED:
        case actionTypes.SUBMIT_CHANGELOG_FORM_STARTED: {
            // console.log("Submitting Started... Current state:", state);
            return {
                ...state,
                submitting: true
            }
        }
        case actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_FAILED:
        case actionTypes.SUBMIT_CHANGELOG_FORM_FAILED: {
            // console.log("Submitting failed... Current state:", state);
            return {
                ...state,
                submitting: false,
            }
        }
        case actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_FINISHED:
        case actionTypes.SUBMIT_CHANGELOG_FORM_FINISHED: {
            // console.log("Submitting Completed... Current state:", state);
            return {
                ...state,
                submitting: false,
            }
        }
        case actionTypes.CHANGELOG_FORM_CHANGELOG_ID_CHANGED: {
            return {
                ...state,
                id: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_COMMENTS_CHANGED: {
            return {
                ...state,
                comments: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_LOADED: {
            return {
                ...state,
                formLoaded: true
            }
        }
        case actionTypes.CHANGELOG_FORM_STATUS_CHANGED: {
            return {
                ...state,
                status: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_LAST_MODIFIED_BY_CHANGED: {
            return {
                ...state,
                lastModifiedBy: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_ATTACHMENT_ADDED: {
            const newAttachments = [...state.attachments];
            const file = action.payload.target.files[0];
            file.key = Math.random();
            newAttachments.push(file);
            return {
                ...state,
                attachments: newAttachments
            }
        }
        case actionTypes.CHANGELOG_FORM_ATTACHMENT_DELETED: {
            const deleteFile = state.attachments.find((file) => {
                return file.key === action.payload.key;
            })
            const deleteIndex = state.attachments.indexOf(deleteFile);
            const newAttachments = [
                ...state.attachments.slice(0, deleteIndex),
                ...state.attachments.slice(deleteIndex + 1)
            ];
            return {
                ...state,
                attachments: newAttachments
            }
        }
        case actionTypes.CHANGELOG_FORM_SUPPLIER_CHANGED: {
            return {
                ...state,
                supplierRepresentative: action.payload,
                errors: getNewErrors(state, action.payload, 'supplierRepresentative')
            }
        }
        case actionTypes.CHANGELOG_FORM_APPROVER_CHANGED: {
            return {
                ...state,
                approver: action.payload,
                errors: getNewErrors(state, action.payload, 'approver')
            }
        }
        case actionTypes.CHANGELOG_FORM_LEANIXID_CHANGED: {
            return {
                ...state,
                leanixdto: action.payload,
                errors: getNewErrors(state, action.payload, 'leanix')
            }
        }
        case actionTypes.CHANGELOG_FORM_EPPM_CHANGED: {
            return {
                ...state,
                ePPMDto: action.payload,
                errors: getNewErrors(state, action.payload, 'eppm')
            }
        }
        case actionTypes.CHANGELOG_FORM_ACTIVITIES_FILTERED_BY_EXPENSE_TYPE_CHANGED: {
            return {
                ...state,
                activitiesFilteredByExpenseType: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_ACTIVITY_CHANGED: {
            return {
                ...state,
                activity: action.payload,
                errors: getNewErrors(state, action.payload, 'activity')
            }
        }
        case actionTypes.CHANGELOG_FORM_VENDOR_TYPE_CHANGED: {
            return {
                ...state,
                vendorType: { label: vendorTypes[parseInt(action.payload.value)], value: parseInt(action.payload.value) },
                errors: getNewErrors(state, action.payload, 'vendorType')
            }
        }
        case actionTypes.CHANGELOG_FORM_VENDOR_CHANGED: {
            return {
                ...state,
                vendor: action.payload,
                errors: getNewErrors(state, action.payload, 'vendor')
            }
        }
        case actionTypes.CHANGELOG_FORM_POWBS_CHANGED: {
            return {
                ...state,
                poWbs: action.payload,
                errors: getNewErrors(state, action.payload, 'poWbs')
            }
        }
        case actionTypes.CHANGELOG_FORM_PLANNER_CHANGED: {
            return {
                ...state,
                planner: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_REQUESTOR_CHANGED: {
            return {
                ...state,
                requestor: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_YEAR_CHANGED: {
            const resourcesCopy = [];
            for (let i = 0; i < state.resources.length; i++) {
                resourcesCopy.push({
                    ...state.resources[parseInt(i)],
                    startDate: '',
                    monthAmount: {}
                })
            }
            return {
                ...state,
                year: action.payload,
                resources: resourcesCopy,
                errors: getNewErrors(state, action.payload, 'year')
            }
        }
        case actionTypes.CHANGELOG_FORM_RATE_YEAR_CHANGED: {
            return {
                ...state,
                rateYear: action.payload,
                errors: getNewErrors(state, action.payload, 'rateYear')
            }
        }
        case actionTypes.CHANGELOG_FORM_COMMENT_CHANGED: {
            return {
                ...state,
                comment: action.payload,
            }
        }
        case actionTypes.CHANGELOG_FORM_IS_CONFIRMED_CHANGED: {
            return {
                ...state,
                isConfirmed: action.payload.target.checked,
                errors: getNewErrors(state, action.payload, 'isConfirmed')
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ADDED: {
            const newResources = [...state.resources];
            if (newResources.length < 20) {
                newResources.push({ key: Math.random(), ref: React.createRef() });
            }
            return {
                ...state,
                resources: newResources
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DELETED: {
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.payload),
                    ...state.resources.slice(action.payload + 1)
                ]
            }
        }
        case actionTypes.CHANGELOG_FORM_SUBMIT_VALIDATION_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED: {
            const errors = state.errors.resources && state.errors.resources[action.index] ? state.errors.resources[action.index] : null;
            let newErrors = { ...state.errors };
            if (errors && action.payload.value !== undefined) {
                let newResourceErrors = { ...newErrors.resources };
                newResourceErrors[action.index] = { ...newErrors.resources[action.index], resourceType: '' };
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ],
                errors: newErrors
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED: {
            const errors = state.errors.resources && state.errors.resources[action.index] ? state.errors.resources[action.index] : null;
            let newErrors = { ...state.errors };
            if (errors && action.payload.value) {
                let newResourceErrors = { ...newErrors.resources };
                newResourceErrors[action.index] = { ...newErrors.resources[action.index], expenseType: '' };
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ],
                errors: newErrors
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED: {
            const errors = state.errors.resources ? state.errors.resources[action.index] : null;
            let newErrors = { ...state.errors };
            if (errors) {
                let newResourceErrors = { ...newErrors.resources };
                newResourceErrors[action.index] = { ...newErrors.resources[action.index], startDate: '' };
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ],
                errors: newErrors
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED: {
            const errors = state.errors.resources ? state.errors.resources[action.index] : null;
            let newErrors = { ...state.errors };
            if (errors) {
                let newResourceErrors = { ...newErrors.resources };
                newResourceErrors[action.index] = { ...newErrors.resources[action.index], endDate: '' };
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ],
                errors: newErrors
            }
        }
        case actionTypes.CHANGELOG_FORM_SCOUT_ID_CHANGED: {
            if (state.scoutId === action.payload) {
                return state; // No change, avoid re-render
            }
            return {
                ...state,
                scoutId: action.payload,
                errors: getNewErrors(state, action.payload, 'scoutId')
            };
        }        
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED: {
            const errors = state.errors.resources[action.index];
            let newErrors = { ...state.errors };
            if (errors && action.payload.target.value !== '') {
                let newResourceErrors = { ...newErrors.resources };
                newResourceErrors[action.index] = { ...newErrors.resources[action.index], name: '' };
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ],
                errors: newErrors
            }
        }
        case actionTypes.CHANGELOG_FORM_REST_FORM: {
            return {
                ...initialState
            }
        }
        case actionTypes.GET_CHANGELOG_FORM_HISTORY_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_CHANGELOG_FORM_HISTORY_FAILED: {
            return {
                ...state,
                loading: false,
                history: []
            }
        }
        case actionTypes.GET_CHANGELOG_FORM_HISTORY_FINISHED: {
            return {
                ...state,
                loading: false,
                history: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_DUPLICATE_RESOURCE_ITEM: {
            if (state.resources.length >= 20) {
                return { ...state };
            }
            const item = state.resources.find((resource) => {
                return resource.key === action.payload;
            });
            const copy = {
                ...item,
                key: Math.random(),
                ref: React.createRef()
            }
            delete copy.poNumbers;
            delete copy.dcs;
            delete copy.id;
            delete copy.resourceModelId;
            delete copy.clientId;
            const newResources = [...state.resources, copy]
            return {
                ...state,
                resources: newResources
            }
        }
        case actionTypes.SET_CHANGELOG_RESOURCES_THROTTLED: {
            return {
                ...state,
                resourcesThrottled: action.payload
            }
        }
        case actionTypes.CHANGELOG_RESOURCE_TAGS_CHANGED:
        case actionTypes.CHANGELOG_FORM_PREPAYMENT_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED:
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED: {
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.index),
                    resourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ]
            }
        }
        default: {
            return state
        }
    }
}
const vendorRates = (state) => state.changelogData.vendorRates
export const getRateYears = createSelector([vendorRates], (vendorRates) => {
    const uniqueRateYears = {};
    function onlyUnique(value, index, self) {
        const isValid = !uniqueRateYears[value.rateYear];
        uniqueRateYears[value.rateYear] = true;
        return isValid;
    }
    if (!vendorRates) {
        return []
    }
    return vendorRates.filter(onlyUnique).map((vendorRate) => {
        return {
            label: vendorRate.rateYear,
            value: vendorRate.rateYear
        }
    }).sort((a, b) => { return (parseInt(a.value) - parseInt(b.value)) });
});
export const getYears = createSelector([], () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    years.push({ label: currentYear, value: currentYear });
    for (let i = 1; i <= 4; i++) {
        const year = currentYear + i;
        years.push({ label: year, value: year });
    }
    return years;
});

const wbses = (state) => state.changelogData.poWbses;
const activity = (state) => state.changelogForm.activity;
const getVendorType = (state) => state.changelogForm.vendorType;
const getAllPoLocations = (state) => state.poLocation.allPoLocations;
const getPoLocation = (state) => state.changelogForm.poLocation;
const getAllCostTypes = (state) => state.costTypes.allItems;
const getCostType = (state) => state.changelogForm.costType;
const getVendors = (state) => state.changelogData.vendors;
const getActivities = (state) => state.changelogForm.activitiesFilteredByExpenseType;

export const getWbsesAfterActivity = createSelector([wbses, activity], (wbses, activity) => {
    if (activity && activity.label) {
        wbses = wbses.filter((wbs) => {
            return wbs.activityId === activity.value;
        });
    } else {
        wbses = []
    }
    return wbses;
});

export const getWbsesAfterVendorType = createSelector([getWbsesAfterActivity, getVendorType], (wbses, vendorType) => {
    if (vendorType && vendorType.label) {
        wbses = wbses.filter((wbs) => {
            return wbs.vendorType === vendorType.value;
        })
    } else {
        wbses = [];
    }
    return wbses;
});

export const getWbsesAfterPoLocation = createSelector([getWbsesAfterVendorType, getPoLocation], (wbses, poLocation) => {
    if (poLocation && poLocation.label) {
        wbses = wbses.filter((wbs) => {
            return wbs.poLocation.id === poLocation.value;
        })
    } else {
        wbses = []
    }
    return wbses;
});
export const getWbsesAfterCostType = createSelector([getWbsesAfterPoLocation, getCostType], (wbses, costType) => {
    if (costType && costType.label) {
        wbses = wbses.filter((wbs) => {
            return wbs.costTypeId === costType.value
        })
    } else {
        wbses = []
    }
    return wbses.map((wbs) => {
        return {
            label: `${wbs.identifier} - ${wbs.description}`,
            value: wbs.id
        }
    });
});

export const getCostTypes = createSelector([getWbsesAfterPoLocation, getAllCostTypes], (wbses, costTypes) => {
    const uniqueCostTypes = wbses
        .map((vendor) => { return { [vendor.costTypeId]: true } })
        .reduce((obj1, obj2) => Object.assign(obj1, obj2), {});
    const availableCostTypes = costTypes && costTypes.length ?
        costTypes
            .filter((costType) => {
                return uniqueCostTypes[costType.id]
            })
            .map((costType) => {
                return {
                    label: costType.name,
                    value: costType.id
                }
            }) : [];
    return availableCostTypes;
});
export const getPoLocations = createSelector([getWbsesAfterActivity, getAllPoLocations], (wbses, allPoLocations) => {
    const uniquePoLocations = wbses
        .map((vendor) => { return { [vendor.poLocation.id]: true } })
        .reduce((obj1, obj2) => Object.assign(obj1, obj2), {});
    const availablePoLocations = allPoLocations ? allPoLocations
        .filter((poLocation) => {
            return uniquePoLocations[poLocation.id]
        })
        .map((poLocation) => {
            return {
                label: poLocation.name + ` (${poLocation.number})`,
                value: poLocation.id
            }
        }) : [];
    return availablePoLocations;
});
export const getVendorsParsed = createSelector([getVendors], (vendors) => {
    return vendors ? vendors.map((vendor) => {
        return {
            label: vendor.name,
            value: vendor.id
        }
    }) : []
});

export const getActivitiesParsed = createSelector([getActivities], (activities) => {
    return activities ? activities.map((activity) => {
        return {
            label: activity.name + ` (${activity.workId})`,
            value: activity.id
        };
    }) : []
});

const getVendorTypes = (state) => state.changelogData.vendorTypes;

export const getUniqueVendorTypes = createSelector([getWbsesAfterActivity, getVendorTypes], (wbses, vendorTypesData) => {
    let uniqueVendorTypesInWbs = {};
    if (wbses.length !== 0) {
        uniqueVendorTypesInWbs = wbses
            .map((vendor) => { return { [vendor.vendorType]: true } })
            .reduce((obj1, obj2) => Object.assign(obj1, obj2), {});
    }
    const availableVendorTypes = vendorTypesData ? vendorTypesData
        .filter((vendorType) => {
            return uniqueVendorTypesInWbs[parseInt(vendorType)];
        })
        .map((vendorType) => {
            return {
                label: vendorTypes[vendorType],
                value: vendorType
            }
        }) : [];
    return availableVendorTypes;
});

export const getChanges = (state) => state.changelogForm.changes
export const getShowChanges = (state) => state.changelogForm.showChanges
export const getChangelogChanges = createSelector([getShowChanges, getChanges], (showChanges, changes) => {
    return showChanges ? changes : {}
});

export const getCreatedBy = (state) => state.changelogForm.createdBy;
export const getCreatedDate = (state) => state.changelogForm.createdDate;
export const getCreatedByString = createSelector([getCreatedBy, getCreatedDate], (createdBy, createdDate) => {
    let createdByString = ""
    if (!createdBy) {
        return "";
    }
    createdByString = createdBy;
    if (createdDate) {
        createdByString = createdByString + '\n' + formatDate(createdDate);
    }
    return createdByString;
});

export const getChangelogsWithGivenActivity = (state) => state.changelogForm.changelogsWithGivenActivity;
export const getChangelogId = (state) => state.changelogForm.id;

export const getChangelogsWithGivenActivityPerYear = createSelector([getChangelogsWithGivenActivity, getChangelogId], (changelogs, id) => {
    return changelogs ? changelogs.sort((cl1, cl2) => cl1.id > cl2.id ? 1 : -1).reduce((acc, curr) => {
        if (curr.id === id) {
            return acc;
        }
        const newObject = {
            ...curr,
            status: statuses[curr.status].label.split("-")[0]
        }
        if (acc[curr.year]) {
            acc[curr.year].push(newObject);
        } else {
            acc[curr.year] = [newObject];
        }
        return acc;
    }, {}) : {};
});


export default changelogFormReducer;