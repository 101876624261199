import * as actionTypes from '../actions/actionTypes';

const resourceReducer = (state, action) => {
    switch(action.type) {
        case actionTypes.CHANGELOG_RESOURCE_TAGS_CHANGED: {
            return {
                ...state,
                tags: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_PREPAYMENT_CHANGED: {
            return {
                ...state,
                prepayment: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED: {
            return {
                ...state,
                resourceModelId: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED: {
            return {
                ...state,
                expenseType: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED: {
            return {
                ...state,
                poNumbers: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED: {
            return {
                ...state,
                dcs: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED: {
            return {
                ...state,
                key: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED: {
            return {
                ...state,
                id: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED: {
            return {
                ...state,
                resourceType: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED: {
            return {
                ...state,
                startDate: action.payload.target.value,
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED: {
            return {
                ...state,
                endDate: action.payload.target.value,
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED: {
            return {
                ...state,
                name: action.payload.target.value
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED: {
            return {
                ...state,
                location: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED: {
            return {
                ...state,
                assignmentSite: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED: {
            return {
                ...state,
                grade: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED: {
            return {
                ...state,
                unitPrice: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED: {
            return {
                ...state,
                perDiem: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED: {
            return {
                ...state,
                currency: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED: {
            const newVendorRates = {...state.vendorRates};
            newVendorRates[action.step] = action.payload;
            return {
                ...state,
                vendorRates: newVendorRates
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED: {
            return {
                ...state,
                resourceTypes: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED: {
            return {
                ...state,
                assignmentSites: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED: {
            return {
                ...state,
                locations: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED: {
            return {
                ...state,
                grades: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED: {
            return {
                ...state,
                unitPrices: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED: {
            return {
                ...state,
                perDiemRates: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED: {
            return {
                ...state,
                currencies: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED: {
            return {
                ...state,
                monthAmount: action.payload
            }
        }
        default: {
            return state;
        }    
    }
}
export default resourceReducer;