import React, {useState, useEffect} from 'react';
import Input from '../../../../components/Input/Input';
import UserPicker from '../../../../containers/UserPicker/UserPicker';
import LeanIXPicker from '../../../../containers/LeanIXPicker/LeanIXPicker';
import EPPMPicker from '../../../../containers/ePPMPicker/ePPMPicker';
import formSectionWrapper from './FormSectionWrapper';
import * as rules from '../../../../config/Rules';
import { canEditWithRequestor, isDisabled } from '../../../../utils/changelogFormEditPrivileges';
import { isVendorTypeExternal, isActivityTypeBAU, isConsentTypeYes } from '../../../../utils/typesAssertions';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';

const Roles = (props) => { 
    const [localScoutId, setLocalScoutId] = useState(props.scoutId || "");

    useEffect(() => {
        setLocalScoutId(props.scoutId || ""); // Sync when props.scoutId updates
    }, [props.scoutId]);

    return (
        <React.Fragment>
            <Input label="Planner" width="100%" disabled value={props.planner || ''} className="disabled-form-input" />
            {canEditWithRequestor(props, rules.EDIT_CHANGELOG_DC_APPROVER) ?
                <UserPicker
                    error={props.errors.approver}
                    user={props.approver}
                    onChange={props.approverChanged}
                    label="Delivery Confirmation Approver"
                    className={props.changes.approver ? "input-updated" : ""}
                    tooltipText={props.changes.approver}
                /> :
                <Input
                    label="Delivery Confirmation Approver"
                    width="100%"
                    disabled
                    value={props.approver ? props.approver.label : ''}
                    className={props.changes.approver ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes.approver}
                />
            }
            {canEditWithRequestor(props, rules.EDIT_CHANGELOG_LEANIX) ?
                <LeanIXPicker
                    error={props.errors.leanix}
                    leanix={props.leanixdto}
                    onChange={props.leanixChanged}
                    label="LeanIX ID"
                    className={props.changes.leanixdto ? "input-updated" : ""}
                    tooltipText={props.changes.leanixdto}
                /> :
                <Input
                    label="LeanIX ID"
                    width="100%"
                    disabled
                    value={props.leanixdto ? props.leanixdto.label : ''}
                    className={props.changes.leanixdto ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes.leanixdto}
                />
            }
            <Input
                label="LeanIX System Name"
                width="100%"
                disabled
                value={props.leanixdto ? props.leanixdto.value : ''}
                className={props.changes.leanixdto ? "input-updated" : "disabled-form-input"}
                tooltipText={props.changes.leanixdto}
            />
            {!isActivityTypeBAU(props.activityType.value) ? null :
                <>
                    <RadioButtons
                        header="New IT BAU"
                        horizontal={true}
                        onChange={props.onconsentTypeChanged}
                        options={props.consentTypes}
                        value={props.consentType}
                        disabled={isDisabled(props, rules.EDIT_CHANGELOG_EPPM)}
                        className={props.changes.consentType ? "input-updated" : ""}
                        tooltipText={props.changes.consentType}
                    />
                    {!isConsentTypeYes(props.consentType.value) ? null :
                        <>
                            {canEditWithRequestor(props, rules.EDIT_CHANGELOG_EPPM) ?
                                <EPPMPicker
                                    error={props.errors.eppm}
                                    eppm={props.ePPMDto}
                                    onChange={props.eppmChanged}
                                    label="EPPM Project ID"
                                    className={props.changes.ePPMDto ? "input-updated" : ""}
                                    tooltipText={props.changes.ePPMDto}
                                /> :
                                <Input
                                    label="EPPM Project ID"
                                    width="100%"
                                    disabled
                                    value={props.ePPMDto ? props.ePPMDto.label : ''}
                                    className={props.changes.ePPMDto ? "input-updated" : "disabled-form-input"}
                                    tooltipText={props.changes.ePPMDto}
                                />

                            }
                            <Input
                                label="EPPM Project Name"
                                width="100%"
                                disabled
                                value={props.ePPMDto ? props.ePPMDto.value : ''}
                                className={props.changes.ePPMDto ? "input-updated" : "disabled-form-input"}
                                tooltipText={props.changes.ePPMDto}

                            />
                        </>
                    }
                </>

            }


            {(!props.vendorType || !props.vendorType.label || !isVendorTypeExternal(props.vendorType.value)) ? null :
                <React.Fragment>
                    {canEditWithRequestor(props, rules.EDIT_CHANGELOG_SUPPLIER_REPRESENTATIVE) ?
                        <UserPicker
                            error={props.errors.supplierRepresentative}
                            user={props.supplierRepresentative}
                            onChange={props.supplierChanged}
                            label="Supplier Representative"
                            className={props.changes.supplierRepresentative ? "input-updated" : ""}
                            tooltipText={props.changes.supplierRepresentative || ""}
                        /> :
                        <Input
                            label="Supplier Representative"
                            width="100%"
                            disabled
                            value={props.supplierRepresentative ? props.supplierRepresentative.label : ''}
                            className={props.changes.supplierRepresentative ? "input-updated" : "disabled-form-input"}
                            tooltipText={props.changes.supplierRepresentative || ""}
                        />
                    }
                </React.Fragment>
            }
                        {canEditWithRequestor(props, rules.EDIT_CHANGELOG_SCOUT_ID) ? (
                        <div>
                            <Input 
                                label="Scout ID"
                                value={localScoutId}  
                                onChange={(e) => setLocalScoutId(e.target.value)} // Update local state immediately
                                onBlur={() => {
                                    if (localScoutId !== props.scoutId) { // Only dispatch if value changed
                                        // console.log("Scout ID updated on blur:", localScoutId);
                                        props.onScoutIdChanged(localScoutId);
                                    }
                                }}
                                className={props.changes.scoutId ? "input-updated" : ""}
                                tooltipText={props.changes.scoutId}
                            />
                            <span style={{ fontSize: "9px", color: "grey", display: "block", marginTop: "4px" }}>
                                Kindly include the Scout ID only if the total value exceeds: 100,000 USD/ 100,000 GBP/ 100,000 EUR/ 100,000 CHF 
                                or 100,000 USD if Changelog is in any other currency. Otherwise, please omit the Scout ID to avoid complications in the 
                                purchase order creation process.
                            </span>
                        </div>
                    ) : (
                        <div>
                            <Input 
                                label="Scout ID"
                                value={props.scoutId || ""}  
                                disabled
                                className={props.changes.scoutId ? "input-updated" : "disabled-form-input"}
                                tooltipText={props.changes.scoutId}
                            />
                            {/* <span style={{ fontSize: "9px", color: "grey", display: "block", marginTop: "4px" }}>
                                Kindly include the Scout ID only if the total value exceeds: 100,000 USD/ 100,000 GBP/ 100,000 EUR/ 100,000 CHF 
                                or 100,000 USD if Changelog is in any other currency. Otherwise, please omit the Scout ID to avoid complications in the 
                                purchase order creation process.
                            </span> */}
                        </div>
                    )}
        </React.Fragment>
    )
}
export default formSectionWrapper(Roles, "Roles"); 