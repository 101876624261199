import React, { Component } from 'react';
import ResourcesContainer from './ChangeLogCreation/ResourcesContainer';
import DeliveryInformationContainer from './ChangeLogCreation/DeliveryInformationContainer';
import SupportingDocumentationContainer from './ChangeLogCreation/SupportingDocumentationContainer';
import RolesContainer from './ChangeLogCreation/RolesContainer';
import CommentsContainer from './ChangeLogCreation/CommentsContainer';
import FormConfirmationContainer from './ChangeLogCreation/FormConfirmationContainer';
import FormSubmitPanelContainer from './ChangeLogCreation/FormSubmitPanelContainer';
import ChangelogStatusContainer from './ChangeLogEdit/ChangelogStatusContainer';
import ChangelogCreatedBy from './ChangeLogEdit/ChangelogCreatedByContainer';
import ChangelogLastModifiedBy from './ChangeLogEdit/ChangelogLastModifiedByContainer';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as changelogFormActionsCreators from '../../../store/actions/changeLogFormActions';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SummaryContainer from './ChangeLogCreation/SummaryContainer';
import Can, { canFunction } from '../../../config/Can';
import TagContainer from './ChangeLogCreation/TagContainer';
import * as rules from '../../../config/Rules';
const styles = {
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative',
        minHeight: '100%',
    },
    wrapper: {
        display: 'flex',
        position: 'relative',
        minHeight: '100%'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.changelogData.loading || !state.changelogForm.formLoaded || state.changelogForm.submitting,
        formLoaded: state.changelogForm.formLoaded,
        dataLoaded: state.changelogForm.dataLoaded,
        editMode: state.changelogForm.editMode
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(changelogFormActionsCreators.editChangeLogFormInitialize(payload));
      },
      resetForm: () => {
        dispatch(changelogFormActionsCreators.resetForm());
      }
  });


class ChangeLogCreation extends Component {
    componentDidMount() {
        this.props.loadView(this.props.location.pathname.split('/')[3]);
    }
    componentDidUpdate(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.props.resetForm();
            this.props.loadView(this.props.location.pathname.split('/')[3]);
        }
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render() {
        return (         
            <React.Fragment key={this.props.dataLoaded}> 
            <SummaryContainer />    
            <div className={this.props.classes.container}>
            <LoadingSpinner 
            enablePointerEvents={(!this.props.dataLoaded && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) || (!this.props.dataLoaded && this.props.editMode && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN))} 
            loading={this.props.loading || (!this.props.dataLoaded && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) || (!this.props.dataLoaded && this.props.editMode && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN))} spinnerCentered={true}/>
            {this.props.formLoaded &&
                <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                        <h3 style={{
                            color: '#000',
                            fontSize: 40,
                            fontWeight: 'bold',
                            textAlign: 'left'
                        }}>Change log - ID {this.props.location.pathname.split('/')[3]}</h3>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <ChangelogCreatedBy editing={true}/> */}
                        {/* <ChangelogLastModifiedBy editing={true}/> */}
                        <ChangelogStatusContainer editing={true}/>
                    </Grid>
                    <Can 
                            role={this.props.role}
                            perform={rules.EDIT_FORM_TAGS}
                            yes={() => {
                                return (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TagContainer editing={true}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }}
                        />
                    {/* <Grid item xs={12}>
                        
                    </Grid>   */}
                    <Grid item md={6} xs={12} id="delivery-confirmation-row">
                        <DeliveryInformationContainer editing={true}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RolesContainer editing={true}/>
                    </Grid>                                   
                    <Grid item xs={12}>
                        <ResourcesContainer editing={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SupportingDocumentationContainer editing={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CommentsContainer editing={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormConfirmationContainer editing={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormSubmitPanelContainer editing={true}/>
                    </Grid>
                    <Grid item xs={12}>
                    <Accordion style={{ overflow: 'hidden', wordBreak: 'break-word' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <b>Modification Info</b>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ChangelogCreatedBy editing={true} />
                        </Grid>
                        <Grid item xs={6}>
                            <ChangelogLastModifiedBy editing={true} />
                        </Grid>
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                </Grid>
            }
            </div>
            </React.Fragment>  
        )
    }
}
export default compose(withRouter, withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ChangeLogCreation);