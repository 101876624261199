/* eslint-disable no-undef */
export const GET_PICKLIST_ITEMS_STARTED = 'GET_PICKLIST_ITEMS_STARTED';
export const GET_ALL_PICKLIST_ITEMS_STARTED = "GET_ALL_PICKLIST_ITEMS_STARTED";
export const GET_ALL_PICKLIST_ITEMS_FAILED = "GET_ALL_PICKLIST_ITEMS_FAILED";
export const GET_ALL_PICKLIST_ITEMS_FINISHED = "GET_ALL_PICKLIST_ITEMS_FINISHED";
export const GET_PICKLIST_ITEMS_FAILED = 'GET_PICKLIST_ITEMS_FAILED';
export const GET_PICKLIST_ITEMS_FINISHED = 'GET_PICKLIST_ITEMS_FINISHED';

export const EDIT_PICKLIST_ITEM_STARTED = 'EDIT_PICKLIST_ITEM_STARTED';
export const EDIT_PICKLIST_ITEM_FAILED = 'EDIT_PICKLIST_ITEM_FAILED';
export const EDIT_PICKLIST_ITEM_FINISHED = 'EDIT_PICKLIST_ITEM_FINISHED';

export const NEW_PICKLIST_ITEM_TEXT_CHANGED = 'NEW_PICKLIST_ITEM_TEXT_CHANGED';
export const EDIT_PICKLIST_ITEM_TEXT_CHANGED = 'EDIT_PICKLIST_ITEM_TEXT_CHANGED';

export const ADD_PICKLIST_ITEM_STARTED = 'ADD_PICKLIST_ITEM_STARTED';
export const ADD_PICKLIST_ITEM_FAILED = 'ADD_PICKLIST_ITEM_FAILED';
export const ADD_PICKLIST_ITEM_FINISHED = 'ADD_PICKLIST_ITEM_FINISHED';

export const GET_EXPENSE_TYPES_STARTED = 'GET_EXPENSE_TYPES';
export const GET_EXPENSE_TYPES_FAILED = 'GET_EXPENSE_TYPES_FAILED';
export const GET_EXPENSE_TYPES_FINISHED = 'GET_EXPENSE_TYPES_FINISHED';

export const ADD_EXPENSE_TYPE_STARTED = 'ADD_EXPENSE_TYPE_STARTED';
export const ADD_EXPENSE_TYPE_FAILED = 'ADD_EXPENSE_TYPE_FAILED';
export const ADD_EXPENSE_TYPE_FINISHED = 'ADD_EXPENSE_TYPE_FINISHED';

export const EDIT_EXPENSE_TYPE_STARTED = 'EDIT_EXPENSE_TYPE_STARTED';
export const EDIT_EXPENSE_TYPE_FAILED = 'EDIT_EXPENSE_TYPE_FAILED';
export const EDIT_EXPENSE_TYPE_FINISHED = 'EDIT_EXPENSE_TYPE_FINISHED';

export const GET_EXPENSE_TYPES_BY_DOMAIN_STARTED = 'GET_EXPENSE_TYPES_BY_DOMAIN_STARTED';
export const DELETE_EXPENSE_TYPE_STARTED = 'DELETE_EXPENSE_TYPE_STARTED';
export const DELETE_EXPENSE_TYPE_FAILED = 'DELETE_EXPENSE_TYPE_FAILED';
export const DELETE_EXPENSE_TYPE_FINISHED = 'DELETE_EXPENSE_TYPE_FINISHED';

export const EXPENSE_TYPE_PICKLIST_NEW_ITEM_DOMAIN_CHANGED = 'EXPENSE_TYPE_PICKLIST_NEW_ITEM_DOMAIN_CHANGED';
export const EXPENSE_TYPE_PICKLIST_EDIT_ITEM_TEXT_CHANGED = 'EXPENSE_TYPE_PICKLIST_EDIT_ITEM_TEXT_CHANGED';
export const EXPENSE_TYPE_PICKLIST_NEW_ITEM_EXPENSE_TYPE_CHANGED = 'EXPENSE_TYPE_PICKLIST_NEW_ITEM_EXPENSE_TYPE_CHANGED';

export const GET_CHANGELOG_COLUMNS_STARTED = 'GET_CHANGELOG_COLUMNS_STARTED';
export const GET_CHANGELOG_COLUMNS_FAILED = 'GET_CHANGELOG_COLUMNS';
export const GET_CHANGELOG_COLUMNS_FINISHED = 'GET_CHANGELOG_COLUMNS_FINISHED';

export const CHANGELOG_LIST_VIEW_LOADING_SPINNER_CHANGED = "CHANGELOG_LIST_VIEW_LOADING_SPINNER_CHANGED";

export const INITIALIZE_CHANGELOG_LIST_VIEW_STARTED = "INITIALIZE_CHANGELOG_LIST_VIEW_STARTED";
export const INITIALIZE_CHANGELOG_LIST_VIEW_FAILED = "INITIALIZE_CHANGELOG_LIST_VIEW_FAILED";
export const INITIALIZE_CHANGELOG_LIST_VIEW_FINISHED = "INITIALIZE_CHANGELOG_LIST_VIEW_FINISHED";

export const GET_CHANGELOGS_STARTED = 'GET_CHANGELOGS_STARTED';
export const GET_CHANGELOGS_FAILED = 'GET_CHANGELOGS_FAILED';
export const GET_CHANGELOGS_FINISHED = 'GET_CHANGELOGS_FINISHED';

export const UPDATE_CHANGELOG_COLUMNS_STARTED = "UPDATE_CHANGELOG_COLUMNS_STARTED";
export const UPDATE_CHANGELOG_COLUMNS_FAILED = "UPDATE_CHANGELOG_COLUMNS_FAILED";
export const UPDATE_CHANGELOG_COLUMNS_FINISHED = "UPDATE_CHANGELOG_COLUMNS_FINISHED";
export const CHANGELOG_FORM_LEANIXID_CHANGED = 'CHANGELOG_FORM_LEANIXID_CHANGED';
export const CHANGELOG_FORM_EPPM_CHANGED = 'CHANGELOG_FORM_EPPM_CHANGED';

export const SHOW_DRAWER = "SHOW_DRAWER";
export const HIDE_DRAWER = "HIDE_DRAWER";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const SET_NAVBAR_STATUS_COMMENT = "SET_NAVBAR_STATUS_COMMENT";

export const SELECT_USERNAME = "SELECT_USERNAME";
export const SELECT_USER_ROLE = "SELECT_USER_ROLE";
export const SELECT_USER_DOMAIN = "SELECT_USER_DOMAIN";
export const SELECT_DOMAIN = "SELECT_DOMAIN";

export const INITIALIZE_APP_STATE = "INITIALIZE_APP_STATE";
export const USER_DATA_LOADED = "USER_DATA_LOADED";
export const GET_USER_ROLES_FINISHED = "GET_USER_ROLES_FINISHED";
export const GET_USER_DOMAINS_FINISHED = "GET_USER_DOMAINS_FINISHED";
export const GET_USER_DOMAINS_FAILED = "GET_USER_DOMAINS_FAILED";

export const GET_RESOURCE_TYPE_BY_DOMAIN_STARTED = "GET_RESOURCE_TYPE_BY_DOMAIN_STARTED";
export const GET_RESOURCE_TYPE_BY_DOMAIN_FAILED = "GET_RESOURCE_TYPE_BY_DOMAIN_FAILED";
export const GET_RESOURCE_TYPE_BY_DOMAIN_FINISHED = "GET_RESOURCE_TYPE_BY_DOMAIN_FINISHED";

export const GET_RESOURCE_TYPE_BY_VENDOR_STARTED = "GET_RESOURCE_TYPE_BY_VENDOR_STARTED";
export const GET_RESOURCE_TYPE_BY_VENDOR_FAILED = "GET_RESOURCE_TYPE_BY_VENDOR_FAILED";
export const GET_RESOURCE_TYPE_BY_VENDOR_FINISHED = "GET_RESOURCE_TYPE_BY_VENDOR_FINISHED";

export const EDIT_RESOURCE_TYPE_BY_VENDOR_STARTED = "EDIT_RESOURCE_TYPE_BY_VENDOR_STARTED"
export const EDIT_RESOURCE_TYPE_BY_VENDOR_FAILED = "EDIT_RESOURCE_TYPE_BY_VENDOR_FAILED"
export const EDIT_RESOURCE_TYPE_BY_VENDOR_FINISHED = 'EDIT_RESOURCE_TYPE_BY_VENDOR_FINISHED';


export const CHANGELOG_FORM_COMMENT_CHANGED = "CHANGELOG_FORM_COMMENT_CHANGED";
export const CHANGELOG_FORM_IS_CONFIRMED_CHANGED = "CHANGELOG_FORM_IS_CONFIRMED_CHANGED";

export const CHANGELOG_FORM_SUPPLIER_CHANGED = "CHANGELOG_FORM_SUPPLIER_CHANGED";
export const CHANGELOG_FORM_APPROVER_CHANGED = "CHANGELOG_FORM_APPROVER_CHANGED";

export const CHANGELOG_FORM_DOMAIN_CHANGED = "CHANGELOG_FORM_DOMAIN_CHANGED";
export const CHANGELOG_FORM_SUBDOMAIN_CHANGED = "CHANGELOG_FORM_SUBDOMAIN_CHANGED";
export const CHANGELOG_FORM_ACTIVITY_TYPE_CHANGED = "CHANGELOG_FORM_ACTIVITY_TYPE_CHANGED";
export const CHANGELOG_FORM_ACTIVITY_CHANGED = "CHANGELOG_FORM_ACTIVITY_CHANGED";
export const CHANGELOG_FORM_VENDOR_TYPE_CHANGED = "CHANGELOG_FORM_VENDOR_TYPE_CHANGED";
export const CHANGELOG_FORM_VENDOR_CHANGED = "CHANGELOG_FORM_VENDOR_CHANGED";
export const CHANGELOG_FORM_POWBS_CHANGED = "CHANGELOG_FORM_POWBS_CHANGED";
export const CHANGELOG_FORM_YEAR_CHANGED = "CHANGELOG_FORM_YEAR_CHANGED";
export const CHANGELOG_FORM_YEAR_CHANGED_STARTED = "CHANGELOG_FORM_YEAR_CHANGED_STARTED";
export const CHANGELOG_FORM_RATE_YEAR_CHANGED = "CHANGELOG_FORM_RATE_YEAR_CHANGED";

export const CONSENT_TYPE_CHANGED = "CONSENT_TYPE_CHANGED";


export const GET_SUBDOMAINS_BY_DOMAIN_STARTED = "GET_SUBDOMAINS_BY_DOMAIN_STARTED";
export const GET_SUBDOMAINS_BY_DOMAIN_FAILED = "GET_SUBDOMAINS_BY_DOMAIN_FAILED";
export const GET_SUBDOMAINS_BY_DOMAIN_FINISHED = "GET_SUBDOMAINS_BY_DOMAIN_FINISHED";

export const GET_ACTIVITIES_STARTED = "GET_ACTIVITIES_STARTED";
export const GET_ACTIVITIES_FAILED = "GET_ACTIVITIES_FAILED";
export const GET_ACTIVITIES_FINISHED = "GET_ACTIVITIES_FINISHED";

export const GET_VENDOR_TYPES_BY_DOMAIN_STARTED = "GET_VENDOR_TYPES_BY_DOMAIN_STARTED"; 
export const GET_VENDOR_TYPES_BY_DOMAIN_FAILED = "GET_VENDOR_TYPES_BY_DOMAIN_FAILED";
export const GET_VENDOR_TYPES_BY_DOMAIN_FINISHED = "GET_VENDOR_TYPES_BY_DOMAIN_FINISHED";

export const GET_VENDORS_BY_VENDOR_TYPE_STARTED = "GET_VENDORS_BY_VENDOR_TYPE_STARTED";
export const GET_VENDORS_BY_VENDOR_TYPE_FAILED = "GET_VENDORS_BY_VENDOR_TYPE_FAILED";
export const GET_VENDORS_BY_VENDOR_TYPE_FINISHED = "GET_VENDORS_BY_VENDOR_TYPE_FINISHED";

export const GET_VENDOR_RATES_BY_VENDOR_STARTED = "GET_VENDOR_RATES_BY_VENDOR_STARTED";
export const GET_VENDOR_RATES_BY_VENDOR_FAILED = "GET_VENDOR_RATES_BY_VENDOR_FAILED";
export const GET_VENDOR_RATES_BY_VENDOR_FINISHED = "GET_VENDOR_RATES_BY_VENDOR_FINISHED";

export const GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_STARTED = "GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_STARTED";
export const GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FAILED = "GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FAILED";
export const GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FINISHED = "GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FINISHED";



export const CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED";
export const CHANGELOG_FORM_ACTIVITIES_FILTERED_BY_EXPENSE_TYPE_CHANGED = "CHANGELOG_FORM_ACTIVITIES_FILTERED_BY_EXPENSE_TYPE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED_STARTED = "CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED_STARTED";
export const CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_MODAL_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_MODAL_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED_STARTED = "CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED_STARTED";
export const CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_MODAL_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_MODAL_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_END_DATE_CHANGED";
export const CHANGELOG_FORM_SCOUT_ID_CHANGED = "CHANGELOG_FORM_SCOUT_ID_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED";
export const CHANGELOG_FORM_DUPLICATE_RESOURCE_ITEM = "CHANGELOG_FORM_DUPLICATE_RESOURCE_ITEM";
export const CHANGELOG_FORM_INITIALIZE = "CHANGELOG_FORM_INITIALIZE";
export const CHANGELOG_FORM_INITIALIZED = "CHANGELOG_FORM_INITIALIZED";
export const CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED";

export const GET_PO_WBSES_BY_ACTIVITY_STARTED = "GET_PO_WBSES_BY_ACTIVITY_STARTED";
export const GET_PO_WBSES_BY_ACTIVITY_FAILED = "GET_PO_WBSES_BY_ACTIVITY_FAILED";
export const GET_PO_WBSES_BY_ACTIVITY_FINISHED = "GET_PO_WBSES_BY_ACTIVITY_FINISHED";

export const CHANGELOG_FORM_PLANNER_CHANGED = "CHANGELOG_FORM_PLANNER_CHANGED";
export const CHANGELOG_FORM_REQUESTOR_CHANGED = "CHANGELOG_FORM_REQUESTOR_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_ADDED = "CHANGELOG_FORM_RESOURCE_ITEM_ADDED";
export const CHANGELOG_FORM_RESOURCE_ITEM_DELETED = "CHANGELOG_FORM_RESOURCE_ITEM_DELETED";

export const SUBMIT_CHANGELOG_FORM_STARTED = "SUBMIT_CHANGELOG_FORM_STARTED";
export const SUBMIT_CHANGELOG_FORM_FAILED = "SUBMIT_CHANGELOG_FORM_FAILED";
export const SUBMIT_CHANGELOG_FORM_FINISHED = "SUBMIT_CHANGELOG_FORM_FINISHED";

export const CHANGELOG_FORM_SUBMIT_VALIDATION_ERRORS_CHANGED = "CHANGELOG_FORM_SUBMIT_VALIDATION_ERRORS_CHANGED";
export const CHANGELOG_FORM_ATTACHMENT_ADDED = "CHANGELOG_FORM_ATTACHMENT_ADDED";
export const CHANGELOG_FORM_REST_FORM = "CHANGELOG_FORM_REST_FORM";
export const GET_CHANGELOG_STARTED = "GET_CHANGELOG_STARTED";
export const GET_CHANGELOG_FINISHED = "GET_CHANGELOG_FINISHED";
export const GET_CHANGELOG_FAILED = "GET_CHANGELOG_FAILED";
export const EDIT_CHANGE_LOG_FORM_INITIALIZE = "EDIT_CHANGE_LOG_FORM_INITIALIZE";
export const CHANGELOG_FORM_STATUS_CHANGED = "CHANGELOG_FORM_STATUS_CHANGED";
export const CHANGELOG_FORM_LAST_MODIFIED_BY_CHANGED = "CHANGELOG_FORM_LAST_MODIFIED_BY_CHANGED";
export const CHANGELOG_FORM_COMMENTS_CHANGED = "CHANGELOG_FORM_COMMENTS_CHANGED";
export const CHANGELOG_FORM_ATTACHMENT_DELETED = "CHANGELOG_FORM_ATTACHMENT_DELETED";
export const CHANGELOG_FORM_LOADED = "CHANGELOG_FORM_LOADED";
export const FORCE_LOADING_DATA_SPINNER = "FORCE_LOADING_DATA_SPINNER";
export const CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED";
export const CHANGELOG_FORM_CHANGELOG_ID_CHANGED = "CHANGELOG_FORM_CHANGELOG_ID_CHANGED";
export const SUBMIT_EDIT_CHANGELOG_FORM_STARTED = "SUBMIT_EDIT_CHANGELOG_FORM_STARTED";
export const SUBMIT_EDIT_CHANGELOG_FORM_FAILED = "SUBMIT_EDIT_CHANGELOG_FORM_FAILED";
export const SUBMIT_EDIT_CHANGELOG_FORM_FINISHED = "SUBMIT_EDIT_CHANGELOG_FORM_FINISHED";
export const CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_STARTED = "CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_STARTED";
export const CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FAILED = "CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FAILED";
export const CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FINISHED = "CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FINISHED";


export const CHANGELOG_TAB_SELECTION_CHANGED = "CHANGELOG_TAB_SELECTION_CHANGED";

export const VIEW_CHANGELOG_REVISION_STARTED = "VIEW_CHANGELOG_REVISION_STARTED";
export const VIEW_CHANGELOG_REVISION_FAILED = "VIEW_CHANGELOG_REVISION_FAILED";
export const VIEW_CHANGELOG_REVISION_FINISHED = "VIEW_CHANGELOG_REVISION_FINISHED";

export const POST_BACKOFFICE_CHANGELOG_COMMENT_STARTED = "POST_BACKOFFICE_CHANGELOG_COMMENT_STARTED";
export const POST_BACKOFFICE_CHANGELOG_COMMENT_FAILED = "POST_BACKOFFICE_CHANGELOG_COMMENT_FAILED";
export const POST_BACKOFFICE_CHANGELOG_COMMENT_FINISHED = "POST_BACKOFFICE_CHANGELOG_COMMENT_FINISHED";
export const BACKOFFICE_CHANGELOG_COMMENT_CHANGED = "BACKOFFICE_CHANGELOG_COMMENT_CHANGED";
export const CHANGELOG_FORM_DOWNLOAD_ATTACHMENT = "CHANGELOG_FORM_DOWNLOAD_ATTACHMENT";
export const GET_DELIVERY_CONFIRMATION_COLUMNS_STARTED = "GET_DELIVERY_CONFIRMATION_COLUMNS_STARTED";
export const GET_DELIVERY_CONFIRMATION_COLUMNS_FAILED = "GET_DELIVERY_CONFIRMATION_COLUMNS_FAILED";
export const GET_DELIVERY_CONFIRMATION_COLUMNS_FINISHED = "GET_DELIVERY_CONFIRMATION_COLUMNS_FINISHED";
export const UPDATE_DELIVERY_CONFIRMATION_COLUMNS_STARTED = "UPDATE_DELIVERY_CONFIRMATION_COLUMNS_STARTED";
export const UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FAILED = "UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FAILED";
export const UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FINISHED = "UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FINISHED";
export const GET_CHANGELOG_FORM_HISTORY_STARTED = "GET_CHANGELOG_FORM_HISTORY_STARTED";
export const GET_CHANGELOG_FORM_HISTORY_FAILED = "GET_CHANGELOG_FORM_HISTORY_FAILED";
export const GET_CHANGELOG_FORM_HISTORY_FINISHED = "GET_CHANGELOG_FORM_HISTORY_FINISHED";

export const GET_RESOURCE_MODEL_COLUMNS_STARTED = "GET_RESOURCE_MODEL_COLUMNS_STARTED";
export const GET_RESOURCE_MODEL_COLUMNS_FAILED = "GET_RESOURCE_MODEL_COLUMNS_FAILED";
export const GET_RESOURCE_MODEL_COLUMNS_FINISHED = "GET_RESOURCE_MODEL_COLUMNS_FINISHED";
export const UPDATE_RESOURCE_MODEL_COLUMNS_STARTED = "UPDATE_RESOURCE_MODEL_COLUMNS_STARTED";
export const UPDATE_RESOURCE_MODEL_COLUMNS_FAILED = "UPDATE_RESOURCE_MODEL_COLUMNS_FAILED";
export const UPDATE_RESOURCE_MODEL_COLUMNS_FINISHED = "UPDATE_RESOURCE_MODEL_COLUMNS_FINISHED";


export const INITIALIZE_DELIERY_CONFIRMATION_FORM_STARTED = "INITIALIZE_DELIERY_CONFIRMATION_FORM_STARTED";
export const INITIALIZE_DELIVERY_CONFIRMATION_FORM_FINISHED = "INITIALIZE_DELIVERY_CONFIRMATION_FORM_FINISHED";
export const INITIALIZE_DELIVERY_CONFIRMATION_FORM_FAILED = "INITIALIZE_DELIVERY_CONFIRMATION_FORM_FAILED";

export const DELIVERY_CONFIRMATION_FORM_ERRORS_CHANGED = "DELIVERY_CONFIRMATION_FORM_ERRORS_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ASSIGNMENT_SITE_CHANGED = "DELIVERY_CONFIRMATION_FORM_ASSIGNMENT_SITE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_COMMENTS_CHANGED = "DELIVERY_CONFIRMATION_FORM_COMMENTS_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_REJECT_REASON_CHANGED = "DELIVERY_CONFIRMATION_FORM_REJECT_REASON_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_DC_NUMBER_CHANGED = "DELIVERY_CONFIRMATION_FORM_DC_NUMBER_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_VENDOR_TYPE_CHANGED = "DELIVERY_CONFIRMATION_FORM_VENDOR_TYPE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_EDIT_STATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_EDIT_STATE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTION_BUTTON_CLICKED = "DELIVERY_CONFIRMATION_FORM_ACTION_BUTTON_CLICKED";
export const DELIVERY_CONFIRMATION_FORM_OVERTIME_HOURS_CHANGED = "DELIVERY_CONFIRMATION_FORM_OVERTIME_HOURS_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ID_CHANGED = "DELIVERY_CONFIRMATION_FORM_ID_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_LAST_MODIFIED_BY_CHANGED = "DELIVERY_CONFIRMATION_FORM_LAST_MODIFIED_BY_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_STATUS_CHANGED = "DELIVERY_CONFIRMATION_FORM_STATUS_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_RESOURCE_TYPE_CHANGED = "DELIVERY_CONFIRMATION_FORM_RESOURCE_TYPE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTUALS_SUBMITTED_BY_CHANGED = "DELIVERY_CONFIRMATION_FORM_ACTUALS_SUBMITTED_BY_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTUALS_APPROVED_BY_CHANGED = "DELIVERY_CONFIRMATION_FORM_ACTUALS_APPROVED_BY_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTIVITY_NAME_CHANGED = "DELIVERY_CONFIRMATION_FORM_ACTIVITY_NAME_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_DELIVERABLE_CHANGED = "DELIVERY_CONFIRMATION_FORM_DELIVERABLE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_PO_NUMBER_CHANGED = "DELIVERY_CONFIRMATION_FORM_PO_NUMBER_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_YEAR_CHANGED = "DELIVERY_CONFIRMATION_FORM_YEAR_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_MONTH_CHANGED = "DELIVERY_CONFIRMATION_FORM_MONTH_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_VENDOR_CHANGED = "DELIVERY_CONFIRMATION_FORM_VENDOR_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_SUPPLIER_REPRESENTATIVE_CHANGED = "DELIVERY_CONFIRMATION_FORM_SUPPLIER_REPRESENTATIVE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_DC_APPROVER_CHANGED = "DELIVERY_CONFIRMATION_FORM_DC_APPROVER_CHANGED"
export const DELIVERY_CONFIRMATION_FORM_GRADE_CHANGED = "DELIVERY_CONFIRMATION_FORM_GRADE_CHANGED"
export const DELIVERY_CONFIRMATION_FORM_UNIT_RATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_UNIT_RATE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_PER_DIEM_RATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_PER_DIEM_RATE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_CURRENCY_CHANGED = "DELIVERY_CONFIRMATION_FORM_CURRENCY_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_PLANNED_UNIT_PRICE_CHANGED = "DELIVERY_CONFIRMATION_FORM_PLANNED_UNIT_PRICE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTUAL_UNIT_PRICE_CHANGED = "DELIVERY_CONFIRMATION_FORM_ACTUAL_UNIT_PRICE_CHANGED"
export const DELIVERY_CONFIRMATION_FORM_PLANNED_PER_DIEM_CHANGED = "DELIVERY_CONFIRMATION_FORM_PLANNED_PER_DIEM_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_ACTUAL_PER_DIEM_CHANGED = "DELIVERY_CONFIRMATION_FORM_ACTUAL_PER_DIEM_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_OVERTIME_CHANGED = "DELIVERY_CONFIRMATION_FORM_OVERTIME_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_RATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_RATE_CHANGED";
export const DELIVERY_CONFIRMATION_NUMBER_OF_EXTRA_HOURS_CHANGED = "DELIVERY_CONFIRMATION_NUMBER_OF_EXTRA_HOURS_CHANGED";
export const DELIVERY_CONFIRMATION_ATTACHMENT_ADDED = "DELIVERY_CONFIRMATION_ATTACHMENT_ADDED";
export const DELIVERY_CONFIRMATION_ATTACHMENT_DELETED = "DELIVERY_CONFIRMATION_ATTACHMENT_DELETED";
export const DELIVERY_CONFIRMATION_COMMENT_CHANGED = "DELIVERY_CONFIRMATION_COMMENT_CHANGED";
export const DELIVERY_CONFIRMATION_RESET_FORM = "DELIVERY_CONFIRMATION_RESET_FORM";
export const DELIVERY_CONFIRMATION_FORM_SUBMIT_AND_APPROVE_MODAL_STATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_SUBMIT_AND_APPROVE_MODAL_STATE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_REJECT_MODAL_STATE_CHANGED = "DELIVERY_CONFIRMATION_FORM_REJECT_MODAL_STATE_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_SUBMIT_STARTED = "DELIVERY_CONFIRMATION_FORM_SUBMIT_STARTED";
export const DELIVERY_CONFIRMATION_FORM_SUBMIT_FAILED = "DELIVERY_CONFIRMATION_FORM_SUBMIT_FAILED";
export const DELIVERY_CONFIRMATION_FORM_SUBMIT_FINISHED = "DELIVERY_CONFIRMATION_FORM_SUBMIT_FINISHED";

export const SELECTED_RESOURCE_MODEL_ROWS_CHANGED = "SELECTED_RESOURCE_MODEL_ROWS_CHANGED";
export const RESOURCE_MODAL_SELECTED_MONTHS_CHANGED = "RESOURCE_MODAL_SELECTED_MONTHS_CHANGED";
export const RESOURCE_MODAL_SELECTED_ALL_CHANGED = "RESOURCE_MODAL_SELECTED_ALL_CHANGED"
export const RESOURCE_MODAL_GENERATE_DC_MODAL_OPENED = "RESOURCE_MODAL_GENERATE_DC_MODAL_OPENED";
export const RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED = "RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED";
export const GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_STARTED = "GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_STARTED";
export const GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FAILED = "GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FAILED";
export const GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FINISHED = "GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FINISHED";
export const RESOURCE_MODAL_VERIFIED_FUTURE_MONTHS_CHANGED = "RESOURCE_MODAL_VERIFIED_FUTURE_MONTHS_CHANGED"
export const RESOURCE_MODAL_SHOULD_CONFIRM_FUTURE_MONTHS_CHANGED = "RESOURCE_MODAL_SHOULD_CONFIRM_FUTURE_MONTHS_CHANGED"
export const SUBMIT_RESOURCE_MODAL_STARTED = "SUBMIT_RESOURCE_MODAL_STARTED";
export const SUBMIT_RESOURCE_MODAL_FINISHED = "SUBMIT_RESOURCE_MODAL_FINISHED";
export const SUBMIT_RESOURCE_MODAL_FAILED = "SUBMIT_RESOURCE_MODAL_FAILED";
export const RESOURCE_MODAL_FUTURE_MONTHS_TO_BE_VALIDATED_CHANGED = "RESOURCE_MODAL_FUTURE_MONTHS_TO_BE_VALIDATED_CHANGED";

export const RESOURCE_MODEL_GENERATING_DCS_CHANGED = "RESOURCE_MODEL_GENERATING_DCS_CHANGED";
export const RESOURCE_MODEL_DC_GENERATION_PROGRESS_CHANGED = "RESOURCE_MODEL_DC_GENERATION_PROGRESS_CHANGED";

export const GET_BACKOFFICE_CHANGELOG_COMMENTS_STARTED = "GET_BACKOFFICE_CHANGELOG_COMMENTS_STARTED";
export const GET_BACKOFFICE_CHANGELOG_COMMENTS_FAILED = "GET_BACKOFFICE_CHANGELOG_COMMENTS_FAILED";
export const GET_BACKOFFICE_CHANGELOG_COMMENT_FINISHED = "GET_BACKOFFICE_CHANGELOG_COMMENT_FINISHED";

export const GET_RESOURCE_MODELS_STARTED = "GET_RESOURCE_MODELS_STARTED";
export const GET_RESOURCE_MODELS_FAILED = "GET_RESOURCE_MODELS_FAILED";
export const GET_RESOURCE_MODELS_FINISHED = "GET_RESOURCE_MODELS_FINISHED";


export const GET_RESOURCE_MODEL_STARTED = "GET_RESOURCE_MODEL_STARTED";
export const GET_RESOURCE_MODEL_FAILED = "GET_RESOURCE_MODEL_FAILED";
export const GET_RESOURCE_MODEL_FINISHED = "GET_RESOURCE_MODEL_FINISHED";
export const RESOURCE_MODEL_FORM_PO_CHANGED = "RESOURCE_MODEL_FORM_PO_CHANGED";
export const RESOURCE_MODEL_FORM_ACTUALS_CHANGED = "RESOURCE_MODEL_FORM_ACTUALS_CHANGED";
export const RESOURCE_MODEL_FORM_ATTACHMENT_ADDED = "RESOURCE_MODEL_FORM_ATTACHMENT_ADDED";
export const RESOURCE_MODEL_FORM_ATTACHMENT_DELETED = "RESOURCE_MODEL_FORM_ATTACHMENT_DELETED";
export const RESOURCE_MODEL_FORM_COMMENT_CHANGED = "RESOURCE_MODEL_FORM_COMMENT_CHANGED";
export const RESOURCE_MODEL_FORM_GENERATE_DC_CHANGED = "RESOURCE_MODEL_FORM_GENERATE_DC_CHANGED";
export const RESET_RESOURCE_MODEL_FORM = "RESET_RESOURCE_MODEL_FORM";
export const RESOURCE_MODEL_FORM_SUBMIT_STARTED = "RESOURCE_MODEL_FORM_SUBMIT_STARTED";
export const RESOURCE_MODEL_FORM_SUBMIT_FAILED = "RESOURCE_MODEL_FORM_SUBMIT_FAILED";
export const RESOURCE_MODEL_FORM_SUBMIT_FINISHED = "RESOURCE_MODEL_FORM_SUBMIT_FINISHED";

export const OPEN_ERROR_DIALOG = "OPEN_ERROR_DIALOG";
export const CLOSE_ERROR_DIALOG = "CLOSE_ERROR_DIALOG";
export const GET_STATIC_PICKLISTS = "GET_STATIC_PICKLISTS";
export const RESET_RESOURCE_MODEL_VIEW = "RESET_RESOURCE_MODEL_VIEW";
export const RESOURCE_MODAL_SELECTED_YEAR_CHANGED = "RESOURCE_MODAL_SELECTED_YEAR_CHANGED";
export const SELECT_ALL_RESOURCE_MODELS_CHANGED = "SELECT_ALL_RESOURCE_MODELS_CHANGED";


export const GET_VENDORS_STARTED = "GET_VENDORS_STARTED";
export const GET_VENDORS_FINISHED = "GET_VENDORS_FINISHED";
export const GET_VENDORS_FAILED = "GET_VENDORS_FAILED";
export const ADD_VENDOR_STARTED = "ADD_VENDOR_STARTED";
export const ADD_VENDOR_FINISHED = "ADD_VENDOR_FINISHED";
export const ADD_VENDOR_FAILED = "ADD_VENDOR_FAILED";
export const EDIT_VENDOR_STARTED = "EDIT_VENDOR_STARTED";
export const EDIT_VENDOR_FINISHED = "EDIT_VENDOR_FINISHED";
export const EDIT_VENDOR_FAILED = "EDIT_VENDOR_FAILED";
export const DELETE_VENDOR_STARTED = "DELETE_VENDOR_STARTED";
export const DELETE_VENDOR_FINISHED = "DELETE_VENDOR_FINISHED";
export const DELETE_VENDOR_FAILED = "DELETE_VENDOR_FAILED";
export const NEW_VENDOR_NAME_CHANGED = "NEW_VENDOR_NAME_CHANGED";
export const NEW_VENDOR_SAP_NUMBER_CHANGED = "NEW_VENDOR_SAP_NUMBER_CHANGED";

export const EDIT_VENDOR_NAME = "EDIT_VENDOR_NAME";
export const EDIT_VENDOR_SAP_NUMBER = "EDIT_VENDOR_SAP_NUMBER";

export const GET_ACTIVITES_COLUMNS_STARTED = "GET_ACTIVITES_COLUMNS_STARTED"
export const GET_ACTIVITES_COLUMNS_FAILED = "GET_ACTIVITES_COLUMNS_FAILED"
export const GET_ACTIVITES_COLUMNS_FINISHED = "GET_ACTIVITES_COLUMNS_FINISHED"

export const GET_ACTIVITIES_LIST_STARTED = "GET_ACTIVITIES_LIST_STARTED";
export const GET_ACTIVITIES_LIST_FINISHED = "GET_ACTIVITIES_LIST_FINISHED";
export const GET_ACTIVITIES_LIST_FAILED = "GET_ACTIVITIES_LIST_FAILED";

export const UPDATE_ACTIVITIES_COLUMNS_STARTED = "UPDATE_ACTIVITIES_COLUMNS_STARTED";
export const UPDATE_ACTIVITIES_COLUMNS_FAILED = "UPDATE_ACTIVITIES_COLUMNS_FAILED"
export const UPDATE_ACTIVITIES_COLUMNS_FINISHED = "UPDATE_ACTIVITIES_COLUMNS_FINISHED";


export const RESET_ACTIVITY_FORM = "RESET_ACTIVITY_FORM";
export const ACTIVITY_FORM_INITIALIZE_STARTED = "ACTIVITY_FORM_INITIALIZE_STARTED";
export const ACTIVITY_FORM_INITIALIZE_FAILED = "ACTIVITY_FORM_INITIALIZE_FAILED";
export const ACTIVITY_FORM_INITIALIZE_FINISHED = "ACTIVITY_FORM_INITIALIZE_FINISHED";


export const ACTIVITY_FORM_PROJECT_NUMBER_CHANGED = "ACTIVITY_FORM_PROJECT_NUMBER_CHANGED";
export const ACTIVITY_FORM_DEMAND_NUMBER_CHANGED = "ACTIVITY_FORM_DEMAND_NUMBER_CHANGED";
export const ACTIVITY_FORM_LEGACY_NUMBER_CHANGED = "ACTIVITY_FORM_LEGACY_NUMBER_CHANGED";
export const ACTIVITY_FORM_DOMAIN_CHANGED = "ACTIVITY_FORM_DOMAIN_CHANGED";
export const ACTIVITY_FORM_ACTIVITY_NAME_CHANGED = "ACTIVITY_FORM_ACTIVITY_NAME_CHANGED";
export const ACTIVITY_FORM_WORK_ID_CHANGED = "ACTIVITY_FORM_WORK_ID_CHANGED";
export const ACTIVITY_FORM_PLANNER_CHANGED = "ACTIVITY_FORM_PLANNER_CHANGED";
export const ACTIVITY_FORM_LEADER_CHANGED = "ACTIVITY_FORM_LEADER_CHANGED";
export const ACTIVITY_FORM_SOURCE_DATA_LINK_CHANGED = "ACTIVITY_FORM_SOURCE_DATA_LINK_CHANGED";
export const ACTIVITY_FORM_ERRORS_CHANGED = "ACTIVITY_FORM_ERRORS_CHANGED";

export const SUBMIT_EDIT_ACTIVITY_FORM_STARTED = "SUBMIT_EDIT_ACTIVITY_FORM_STARTED";
export const SUBMIT_CREATE_ACTIVITY_FORM_STARTED = "SUBMIT_CREATE_ACTIVITY_FORM_STARTED";
export const SUBMIT_EDIT_ACTIVITIY_FORM_FAILED = "SUBMIT_EDIT_ACTIVITIY_FORM_FAILED";
export const SUBMIT_CREATE_ACTIVITIY_FORM_FAILED = "SUBMIT_CREATE_ACTIVITIY_FORM_FAILED";
export const SUBMIT_EDIT_ACTIVITIY_FORM_FINISHED = "SUBMIT_EDIT_ACTIVITIY_FORM_FINISHED";
export const SUBMIT_CREATE_ACTIVITIY_FORM_FINISHED = "SUBMIT_CREATE_ACTIVITIY_FORM_FINISHED";

export const ACTIVITY_EDIT_FORM_INITIALIZE_STARTED = "ACTIVITY_EDIT_FORM_INITIALIZE_STARTED";
export const ACTIVITY_EDIT_FORM_INITIALIZE_FAILED = "ACTIVITY_EDIT_FORM_INITIALIZE_FAILED";
export const ACTIVITY_EDIT_FORM_INITIALIZE_FINISHED = "ACTIVITY_EDIT_FORM_INITIALIZE_FINISHED";
export const ACTIVITY_FORM_ID_CHANGED = "ACTIVITY_FORM_ID_CHANGED";



export const GET_WBS_COLUMNS_STARTED = "GET_WBS_COLUMNS_STARTED";
export const GET_WBS_COLUMNS_FAILED = "GET_WBS_COLUMNS_FAILED";
export const GET_WBS_COLUMNS_FINISHED = "GET_WBS_COLUMNS_FINISHED";

export const GET_WBS_LIST_STARTED = "GET_WBS_LIST_STARTED";
export const GET_WBS_LIST_FINISHED = "GET_WBS_LIST_FINISHED";
export const GET_WBS_LIST_FAILED = "GET_WBS_LIST_FAILED";

export const UPDATE_WBS_COLUMNS_STARTED = "UPDATE_WBS_COLUMNS_STARTED";
export const UPDATE_WBS_COLUMNS_FAILED = "UPDATE_WBS_COLUMNS_FAILED"
export const UPDATE_WBS_COLUMNS_FINISHED = "UPDATE_WBS_COLUMNS_FINISHED";

export const INITIALIZE_VENDOR_RATES_VIEWER_STARTED = "INITIALIZE_VENDOR_RATES_VIEWER_STARTED";
export const INITIALIZE_VENDOR_RATES_VIEWER_FAILED = "INITIALIZE_VENDOR_RATES_VIEWER_FAILED";
export const INITIALIZE_VENDOR_RATES_VIEWER_FINISHED = "INITIALIZE_VENDOR_RATES_VIEWER_FINISHED";

export const GET_VENDOR_RATES_COLUMNS_STARTED = "GET_VENDOR_RATES_COLUMNS_STARTED";
export const GET_VENDOR_RATES_COLUMNS_FAILED = "GET_VENDOR_RATES_COLUMNS_FAILED";
export const GET_VENDOR_RATES_COLUMNS_FINISHED = "GET_VENDOR_RATES_COLUMNS_FINISHED";

export const GET_VENDOR_RATES_LIST_STARTED = "GET_VENDOR_RATES_LIST_STARTED";
export const GET_VENDOR_RATES_LIST_FINISHED = "GET_VENDOR_RATES_LIST_FINISHED";
export const GET_VENDOR_RATES_LIST_FAILED = "GET_VENDOR_RATES_LIST_FAILED";

export const UPDATE_VENDOR_RATES_COLUMNS_STARTED = "UPDATE_VENDOR_RATES_COLUMNS_STARTED";
export const UPDATE_VENDOR_RATES_COLUMNS_FAILED = "UPDATE_VENDOR_RATES_COLUMNS_FAILED"
export const UPDATE_VENDOR_RATES_COLUMNS_FINISHED = "UPDATE_VENDOR_RATES_COLUMNS_FINISHED";

export const GET_DELIVERY_CONFIRMATIONS_STARTED = "GET_DELIVERY_CONFIRMATIONS_STARTED";
export const GET_DELIVERY_CONFIRMATIONS_FAILED = "GET_DELIVERY_CONFIRMATIONS_FAILED";
export const GET_DELIVERY_CONFIRMATIONS_FINISHED = "GET_DELIVERY_CONFIRMATIONS_FINISHED";


export const RESET_WBS_FORM = "RESET_WBS_FORM";
export const WBS_FORM_ALREADY_IN_USE_CHANGED = "WBS_FORM_ALREADY_IN_USE_CHANGED";
export const WBS_FORM_PO_WBS_DESCRIPTION_CHANGED = "WBS_FORM_PO_WBS_DESCRIPTION_CHANGED";
export const WBS_FORM_ID_CHANGED = "WBS_FORM_ID_CHANGED";
export const WBS_FORM_INITIALIZE_STARTED = "WBS_FORM_INITIALIZE_STARTED";
export const WBS_FORM_INITIALIZE_FAILED = "WBS_FORM_INITIALIZE_FAILED";
export const WBS_FORM_INITIALIZE_FINISHED = "WBS_FORM_INITIALIZE_FINISHED";
export const WBS_EDIT_FORM_INITIALIZE_STARTED = "WBS_EDIT_FORM_INITIALIZE_STARTED";
export const WBS_EDIT_FORM_INITIALIZE_FAILED = "WBS_EDIT_FORM_INITIALIZE_FAILED";
export const WBS_EDIT_FORM_INITIALIZE_FINISHED = "WBS_EDIT_FORM_INITIALIZE_FINISHED";
export const WBS_FORM_DOMAIN_CHANGED = "WBS_FORM_DOMAIN_CHANGED";
export const WBS_FORM_CARE_CC_CHANGED = "WBS_FORM_CARE_CC_CHANGED"
export const WBS_FORM_COST_TYPE_CHANGED = "WBS_FORM_COST_TYPE_CHANGED";
export const WBS_FORM_VENDOR_TYPE_CHANGED = "WBS_FORM_VENDOR_TYPE_CHANGED";
export const WBS_FORM_C_PROJECT_CHANGED = "WBS_FORM_C_PROJECT_CHANGED";
export const WBS_FORM_C_WBS_CHANGED = "WBS_FORM_C_WBS_CHANGED";
export const WBS_FORM_PO_CC_CHANGED = "WBS_FORM_PO_CC_CHANGED";
export const WBS_FORM_ACTIVITY_NAME_CHANGED = "WBS_FORM_ACTIVITY_NAME_CHANGED";
export const WBS_FORM_PO_WBS_CHANGED = "WBS_FORM_PO_WBS_CHANGED";
export const WBS_FORM_DEPRECATION_START_DATE_CHANGED = "WBS_FORM_DEPRECATION_START_DATE_CHANGED";
export const WBS_FORM_DEPRECATION_DURATION_CHANGED = "WBS_FORM_DEPRECATION_DURATION_CHANGED";
export const WBS_FORM_DEPRECATION_COST_CENTER_CHANGED = "WBS_FORM_DEPRECATION_COST_CENTER_CHANGED";
export const WBS_FORM_ERRORS_CHANGED = "WBS_FORM_ERRORS_CHANGED";
export const SUBMIT_WBS_FORM_STARTED = "SUBMIT_WBS_FORM_STARTED";
export const SUBMIT_WBS_FORM_FAILED = "SUBMIT_WBS_FORM_FAILED";
export const SUBMIT_WBS_FORM_FINISHED = "SUBMIT_WBS_FORM_FINISHED";
export const GET_WBS_STARTED = "GET_WBS_STARTED"
export const GET_WBS_FINISHED = "GET_WBS_FINISHED"
export const GET_WBS_FAILED = "GET_WBS_FAILED"
export const AVAILABLE_WBS_FORM_ACTIVITIES_CHANGED = "AVAILABLE_WBS_FORM_ACTIVITIES_CHANGED";
export const FORCE_WBS_FORM_LOADING_SPINNER = "FORCE_WBS_FORM_LOADING_SPINNER";
export const FORCE_WBS_FORM_CLOSE_LOADING_SPINNER = "FORCE_WBS_FORM_CLOSE_LOADING_SPINNER";
export const WBS_FORM_PO_LOCATION_CHANGED = "WBS_FORM_PO_LOCATION_CHANGED";


export const RESET_VENDOR_RATES_FORM = "RESET_VENDOR_RATES_FORM";
export const VENDOR_RATES_FORM_ALREADY_IN_USE_CHANGED = "VENDOR_RATES_FORM_ALREADY_IN_USE_CHANGED";
export const VENDOR_RATES_FORM_INITIALIZE_STARTED = "VENDOR_RATES_FORM_INITIALIZE_STARTED";
export const VENDOR_RATES_FORM_INITIALIZE_FAILED = "VENDOR_RATES_FORM_INITIALIZE_FAILED";
export const VENDOR_RATES_FORM_INITIALIZE_FINISHED = "VENDOR_RATES_FORM_INITIALIZE_FINISHED";
export const VENDOR_RATES_EDIT_FORM_INITIALIZE_STARTED = "VENDOR_RATES_EDIT_FORM_INITIALIZE_STARTED";
export const VENDOR_RATES_EDIT_FORM_INITIALIZE_FAILED = "VENDOR_RATES_EDIT_FORM_INITIALIZE_FAILED";
export const VENDOR_RATES_EDIT_FORM_INITIALIZE_FINISHED = "VENDOR_RATES_EDIT_FORM_INITIALIZE_FINISHED";

export const VENDOR_RATES_FORM_ERRORS_CHANGED = "VENDOR_RATES_FORM_ERRORS_CHANGED";
export const VENDOR_RATES_FORM_SUBMIT_STARTED = "VENDOR_RATES_FORM_SUBMIT_STARTED";
export const VENDOR_RATES_FORM_SUBMIT_FAILED = "VENDOR_RATES_FORM_SUBMIT_FAILED";
export const VENDOR_RATES_FORM_SUBMIT_FINISHED = "VENDOR_RATES_FORM_SUBMIT_FINISHED";

export const VENDOR_RATES_FORM_ID_CHANGED = "VENDOR_RATES_FORM_ID_CHANGED";
export const VENDOR_RATES_FORM_EXTERNAL_VENDORS_CHANGED = "VENDOR_RATES_FORM_EXTERNAL_VENDORS_CHANGED";
export const VENDOR_RATES_FORM_INTERNAL_VENDORS_CHANGED = "VENDOR_RATES_FORM_INTERNAL_VENDORS_CHANGED";
export const VENDOR_RATES_FORM_VENDOR_CHANGED = "VENDOR_RATES_FORM_VENDOR_CHANGED";
export const VENDOR_RATES_FORM_RATE_YEAR_CHANGED = "VENDOR_RATES_FORM_RATE_YEAR_CHANGED";
export const VENDOR_RATES_FORM_RESOURCE_TYPE_CHANGED = "VENDOR_RATES_FORM_RESOURCE_TYPE_CHANGED";
export const VENDOR_RATES_FORM_ASSIGNMENT_SITE_CHANGED = "VENDOR_RATES_FORM_ASSIGNMENT_SITE_CHANGED";
export const VENDOR_RATES_FORM_RESOURCE_LOCATION_CHANGED = "VENDOR_RATES_FORM_RESOURCE_LOCATION_CHANGED";
export const VENDOR_RATES_FORM_GRADE_CHANGED = "VENDOR_RATES_FORM_GRADE_CHANGED";
export const VENDOR_RATES_FORM_UNIT_PRICE_CHANGED = "VENDOR_RATES_FORM_UNIT_PRICE_CHANGED";
export const VENDOR_RATES_FORM_PER_DIEM_RATE_CHANGED = "VENDOR_RATES_FORM_PER_DIEM_RATE_CHANGED";
export const VENDOR_RATES_FORM_CURRENCY_CHANGED = "VENDOR_RATES_FORM_CURRENCY_CHANGED";
export const VENDOR_RATES_COMMENTS_CHANGED = "VENDOR_RATES_COMMENTS_CHANGED";
export const VENDOR_RATES_FORM_ATTACHMENTS_CHANGED = "VENDOR_RATES_FORM_ATTACHMENTS_CHANGED";
export const VENDOR_RATE_FORM_COMMENT_CHANGED = "VENDOR_RATE_FORM_COMMENT_CHANGED";
export const VENDOR_RATE_FORM_ATTACHMENT_ADDED = "VENDOR_RATE_FORM_ATTACHMENT_ADDED";
export const VENDOR_RATE_FORM_ATTACHMENT_REMOVED = "VENDOR_RATE_FORM_ATTACHMENT_REMOVED";
export const VENDOR_RATE_FORM_INACTIVE_CHANGED = "VENDOR_RATE_FORM_INACTIVE_CHANGED";

export const GET_ALL_DOMAINS_STARTED = "GET_ALL_DOMAINS_STARTED";
export const GET_ALL_DOMAINS_FAILED = "GET_ALL_DOMAINS_FAILED";
export const GET_ALL_DOMAINS_FINISHED = "GET_ALL_DOMAINS_FINISHED";

export const GET_DOMAINS_STARTED = "GET_DOMAINS_STARTED";
export const GET_DOMAINS_FAILED = "GET_DOMAINS_FAILED";
export const GET_DOMAINS_FINISHED = "GET_DOMAINS_FINISHED";
export const ADD_DOMAIN_STARTED = "ADD_DOMAIN_STARTED";
export const ADD_DOMAIN_FAILED = "ADD_DOMAIN_FAILED";
export const ADD_DOMAIN_FINISHED = "ADD_DOMAIN_FINISHED";
export const EDIT_DOMAIN_STARTED = "EDIT_DOMAIN_STARTED";
export const EDIT_DOMAIN_FAILED = "EDIT_DOMAIN_FAILED";
export const EDIT_DOMAIN_FINISHED = "EDIT_DOMAIN_FINISHED";

export const DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED = "DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED";
export const DOMAIN_PICKLIST_NEW_ITEM_PLANNER_CHANGED = "DOMAIN_PICKLIST_NEW_ITEM_PLANNER_CHANGED";
export const DOMAIN_PICKLIST_EDIT_ITEM_DOMAIN_CHANGED = "DOMAIN_PICKLIST_EDIT_ITEM_DOMAIN_CHANGED";
export const DOMAIN_PICKLIST_EDIT_ITEM_PLANNER_CHANGED = "DOMAIN_PICKLIST_EDIT_ITEM_PLANNER_CHANGED";

export const GET_ALL_SUB_DOMAINS_STARTED = "GET_ALL_SUB_DOMAINS_STARTED";
export const GET_ALL_SUB_DOMAINS_FAILED = "GET_ALL_SUB_DOMAINS_FAILED";
export const GET_ALL_SUB_DOMAINS_FINISHED = "GET_ALL_SUB_DOMAINS_FINISHED";

export const GET_SUB_DOMAINS_STARTED = "GET_SUB_DOMAINS_STARTED"
export const GET_SUB_DOMAINS_FAILED = "GET_SUB_DOMAINS_FAILED"
export const GET_SUB_DOMAINS_FINISHED = "GET_SUB_DOMAINS_FINISHED"
export const ADD_SUB_DOMAIN_STARTED = "ADD_SUB_DOMAIN_STARTED"
export const ADD_SUB_DOMAIN_FAILED = "ADD_SUB_DOMAIN_FAILED"
export const ADD_SUB_DOMAIN_FINISHED = "ADD_SUB_DOMAIN_FINISHED"
export const EDIT_SUB_DOMAIN_STARTED = "EDIT_SUB_DOMAIN_STARTED"
export const EDIT_SUB_DOMAIN_FAILED = "EDIT_SUB_DOMAIN_FAILED"
export const EDIT_SUB_DOMAIN_FINISHED = "EDIT_SUB_DOMAIN_FINISHED"
export const DELETE_SUB_DOMAIN_STARTED = "DELETE_SUB_DOMAIN_STARTED"
export const DELETE_SUB_DOMAIN_FAILED = "DELETE_SUB_DOMAIN_FAILED"
export const DELETE_SUB_DOMAIN_FINISHED = "DELETE_SUB_DOMAIN_FINISHED"
export const SUB_DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED = "SUB_DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED"
export const SUB_DOMAIN_PICKLIST_NEW_ITEM_TEXT_CHANGED = "SUB_DOMAIN_PICKLIST_NEW_ITEM_TEXT_CHANGED"
export const SUB_DOMAIN_PICKLIST_EDIT_ITEM_TEXT_CHANGED = "SUB_DOMAIN_PICKLIST_EDIT_ITEM_TEXT_CHANGED"


export const GET_ALL_PO_LOCATIONS_STARTED = "GET_ALL_PO_LOCATIONS_STARTED";
export const GET_ALL_PO_LOCATIONS_FAILED = "GET_ALL_PO_LOCATIONS_FAILED";
export const GET_ALL_PO_LOCATIONS_FINISHED = "GET_ALL_PO_LOCATIONS_FINISHED";

export const GET_PO_LOCATIONS_STARTED = "GET_PO_LOCATIONS_STARTED";
export const GET_PO_LOCATIONS_FINISHED = "GET_PO_LOCATIONS_FINISHED";
export const GET_PO_LOCATIONS_FAILED = "GET_PO_LOCATIONS_FAILED";
export const ADD_PO_LOCATION_STARTED = "ADD_PO_LOCATION_STARTED";
export const DELETE_PO_LOCATION_STARTED = "DELETE_PO_LOCATION_STARTED";
export const EDIT_PO_LOCATION_STARTED = "EDIT_PO_LOCATION_STARTED";
export const ADD_PO_LOCATION_FINISHED = "ADD_PO_LOCATION_FINISHED";
export const DELETE_PO_LOCATION_FINISHED = "DELETE_PO_LOCATION_FINISHED";
export const EDIT_PO_LOCATION_FINISHED = "EDIT_PO_LOCATION_FINISHED";
export const ADD_PO_LOCATION_FAILED = "ADD_PO_LOCATION_FAILED";
export const DELETE_PO_LOCATION_FAILED = "DELETE_PO_LOCATION_FAILED";
export const EDIT_PO_LOCATION_FAILED = "EDIT_PO_LOCATION_FAILED";
export const NEW_PO_LOCATION_NAME_CHANGED = "NEW_PO_LOCATION_NAME_CHANGED";
export const NEW_PO_LOCATION_NUMBER_CHANGED = "NEW_PO_LOCATION_NUMBER_CHANGED";
export const EDIT_PO_LOCATION_NAME = "EDIT_PO_LOCATION_NAME";
export const EDIT_PO_LOCATION_NUMBER = "EDIT_PO_LOCATION_NUMBER";


export const IMPORT_MASTERDATA_FILE_UPLOAD_STARTED = "IMPORT_MASTERDATA_FILE_UPLOAD_STARTED";
export const IMPORT_MASTERDATA_FILE_UPLOAD_FAILED = "IMPORT_MASTERDATA_FILE_UPLOAD_FAILED";
export const IMPORT_MASTERDATA_FILE_UPLOAD_FINISHED = "IMPORT_MASTERDATA_FILE_UPLOAD_FINISHED";

export const IMPORT_MASTERDATA_LOADING_SPINNER_CHANGED = "IMPORT_MASTERDATA_LOADING_SPINNER_CHANGED";
export const IMPORT_MASTERDATA_FILTERS_CHANGED = "IMPORT_MASTERDATA_FILTERS_CHANGED";

export const GET_IMPORT_MASTERDATA_LIST_STARTED = "GET_IMPORT_MASTERDATA_LIST_STARTED";
export const GET_IMPORT_MASTERDATA_LIST_FINISHED = "GET_IMPORT_MASTERDATA_LIST_FINISHED";
export const GET_IMPORT_MASTERDATA_LIST_FAILED = "GET_IMPORT_MASTERDATA_LIST_FAILED";
export const UPDATE_IMPORT_MASTERDATA_COLUMNS_STARTED = "UPDATE_IMPORT_MASTERDATA_COLUMNS_STARTED";
export const UPDATE_IMPORT_MASTERDATA_COLUMNS_FAILED = "UPDATE_IMPORT_MASTERDATA_COLUMNS_FAILED";
export const UPDATE_IMPORT_MASTERDATA_COLUMNS_FINISHED = "UPDATE_IMPORT_MASTERDATA_COLUMNS_FINISHED";
export const GET_IMPORT_MASTERDATA_COLUMNS_FAILED = "GET_IMPORT_MASTERDATA_COLUMNS_FAILED";
export const GET_IMPORT_MASTERDATA_COLUMNS_STARTED = "GET_IMPORT_MASTERDATA_COLUMNS_STARTED";
export const GET_IMPORT_MASTERDATA_COLUMNS_FINISHED = "GET_IMPORT_MASTERDATA_COLUMNS_FINISHED";
export const ATTACHMENT_LOADING_CHANGED = "ATTACHMENT_LOADING_CHANGED";

export const DATA_EXPORT_STARTED = "DATA_EXPORT_STARTED";
export const DATA_EXPORT_FINISHED = "DATA_EXPORT_FINISHED";
export const DATA_EXPORT_FAILED = "DATA_EXPORT_FAILED";
export const EXPORT_DATA_LOADING_CHANGED = "EXPORT_DATA_LOADING_CHANGED";

export const DELIVERY_CONFIRMATION_REJECT_REASON_CHANGED = "DELIVERY_CONFIRMATION_REJECT_REASON_CHANGED";
export const DELIVERY_CONFIRMATION_COMMENT_TEXT_CHANGED = "DELIVERY_CONFIRMATION_COMMENT_TEXT_CHANGED";
export const DELIVERY_CONFIRMATION_APPROVE_DC = "DELIVERY_CONFIRMATION_APPROVE_DC";
export const DELIVERY_CONFIRMATION_RESTART_DC = "DELIVERY_CONFIRMATION_RESTART_DC";
export const DELIVERY_CONFIRMATION_PROCESS_DC = "DELIVERY_CONFIRMATION_PROCESS_DC";
export const DELIVERY_CONFIRMATION_RESTART_MODAL_CHANGED = "DELIVERY_CONFIRMATION_RESTART_MODAL_CHANGED";

export const DELIVERY_CONFIRMATION_PROCESSINGNA_DC = "DELIVERY_CONFIRMATION_PROCESSINGNA_DC";
export const DELIVERY_CONFIRMATION_REJECT_DC_MODAL_CHANGED = "DELIVERY_CONFIRMATION_REJECT_DC_MODAL_CHANGED";
export const DELIVERY_CONFIRMATION_REJECT_DC = "DELIVERY_CONFIRMATION_REJECT_DC";
export const DELIVERY_CONFIRMATION_COMMENT_DC_MODAL_CHANGED = "DELIVERY_CONFIRMATION_COMMENT_DC_MODAL_CHANGED"
export const DELIVERY_CONFIRMATION_COMMENT_DC = "DELIVERY_CONFIRMATION_COMMENT_DC";
export const DELIVERY_CONFIRMATION_INITIALIZE_QUICK_EDIT = "DELIVERY_CONFIRMATION_INITIALIZE_QUICK_EDIT";
export const DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_CHANGED = "DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_CHANGED";
export const DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_SUBMITTED = "DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_SUBMITTED";
export const DELIVERY_CONFIRMATION_SELECTED_DC_CHANGED = "DELIVERY_CONFIRMATION_SELECTED_DC_CHANGED";
export const DELIVERY_CONFIRMATION_LIST_CHANGE_LOADING_SPINNER = "DELIVERY_CONFIRMATION_LIST_CHANGE_LOADING_SPINNER";
export const DELIVERY_CONFIRMATION_FORM_LOADING_SPINNER_CHANGED = "DELIVERY_CONFIRMATION_FORM_LOADING_SPINNER_CHANGED";
export const DELIVERY_CONFIRMATION_VIEWER_FILTERS_CHANGED = "DELIVERY_CONFIRMATION_VIEWER_FILTERS_CHANGED";

export const TABLE_STATE_CHANGED = "TABLE_STATE_CHANGED";

export const SELECTED_PICKLIST_CHANGED = "SELECTED_PICKLIST_CHANGED";
export const DC_SUPPLIER_TABS_VISIBILITY_CHANGED = "DC_SUPPLIER_TABS_VISIBILITY_CHANGED";
export const CHANGELOG_FORM_COST_TYPE_CHANGED = "CHANGELOG_FORM_COST_TYPE_CHANGED";
export const CHANGELOG_FORM_PO_LOCATION_CHANGED = "CHANGELOG_FORM_PO_LOCATION_CHANGED";
export const ACTIVITY_FORM_ACTIVITY_TYPE_CHANGED = "ACTIVITY_FORM_ACTIVITY_TYPE_CHANGED";

export const DELIVERY_CONFIRMATION_IS_SUPPLIER_CHANGED = "DELIVERY_CONFIRMATION_IS_SUPPLIER_CHANGED";
export const DELIVERY_CONFIRMATION_IS_APPROVER_CHANGED = "DELIVERY_CONFIRMATION_IS_APPROVER_CHANGED";
export const SELECT_NAME = "SELECT_NAME";
export const DELIVERY_CONFIRMATION_GENERATE_PDF = "DELIVERY_CONFIRMATION_GENERATE_PDF";
export const DELIVERY_CONFIRMATION_DOWNLOAD_DC_FILE_FROM_LINK = "DELIVERY_CONFIRMATION_DOWNLOAD_DC_FILE_FROM_LINK";
export const INVALID_REQUEST_OCCURED = "INVALID_REQUEST_OCCURED";
export const GET_CURRENTLY_LOGGED_IN_USER = "GET_CURRENTLY_LOGGED_IN_USER";
export const DELIERY_CONFIRMATION_FORM_RM_ID_CHANGED = "DELIERY_CONFIRMATION_FORM_RM_ID_CHANGED";
export const ACTIVITY_FORM_CLIENT_ID_CHANGED = "ACTIVITY_FORM_CLIENT_ID_CHANGED";
export const VENDOR_RATE_FORM_CLIENT_ID_CHANGED = "VENDOR_RATE_FORM_CLIENT_ID_CHANGED";
export const WBS_FORM_CLIENT_ID_CHANGED = "WBS_FORM_CLIENT_ID_CHANGED";
export const CHANGELOG_FORM_CLIENT_ID_CHANGED = "CHANGELOG_FORM_CLIENT_ID_CHANGED";
export const CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED = "CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED";
export const SNACKBAR_CHANGED = "SNACKBAR_CHANGED";
export const RESOURCE_MODEL_LIST_CHANGE_LOADING_SPINNER = "RESOURCE_MODEL_LIST_CHANGE_LOADING_SPINNER";
export const VENDOR_RATE_LIST_CHANGE_LOADING_SPINNER = "VENDOR_RATE_LIST_CHANGE_LOADING_SPINNER";
export const WBS_LIST_CHANGE_LOADING_SPINNER = "WBS_LIST_CHANGE_LOADING_SPINNER";
export const ACTIVITIES_LIST_LOADING_SPINNER_CHANGED = "ACTIVITIES_LIST_LOADING_SPINNER_CHANGED";
export const GET_USER_DOMAINS_STARTED = "GET_USER_DOMAINS_STARTED";
export const DOMAINS_LOADING_CHANGED = "DOMAINS_LOADING_CHANGED";
export const CHANGELOG_FORM_CHANGELOG_CREATED_BY_CHANGED = "CHANGELOG_FORM_CHANGELOG_CREATED_BY_CHANGED";
export const CHANGELOG_FORM_EDIT_MODE_CHANGED = "CHANGELOG_FORM_EDIT_MODE_CHANGED";
export const CHANGELOG_FORM_SET_STATE = "CHANGELOG_FORM_SET_STATE";
export const CHANGELOG_FORM_DATA_LOADED = "CHANGELOG_FORM_DATA_LOADED";
export const CHANGELOG_FORM_PREPAYMENT_CHANGED = "CHANGELOG_FORM_PREPAYMENT_CHANGED";
export const WBS_FORM_INACTIVE_CHANGED = "WBS_FORM_INACTIVE_CHANGED";

export const GET_RESOURCE_MODEL_HISTORY_STARTED = "GET_RESOURCE_MODEL_HISTORY_STARTED";
export const GET_RESOURCE_MODEL_HISTORY_FINISHED = "GET_RESOURCE_MODEL_HISTORY_FINISHED";
export const GET_RESOURCE_MODEL_HISTORY_FAILED = "GET_RESOURCE_MODEL_HISTORY_FAILED";

export const GET_DELIVERY_CONFIRMATION_HISTORY_STARTED = "GET_DELIVERY_CONFIRMATION_HISTORY_STARTED";
export const GET_DELIVERY_CONFIRMATION_HISTORY_FINISHED = "GET_DELIVERY_CONFIRMATION_HISTORY_FINISHED";
export const GET_DELIVERY_CONFIRMATION_HISTORY_FAILED = "GET_DELIVERY_CONFIRMATION_HISTORY_FAILED";
export const SET_CHANGELOG_RESOURCES_THROTTLED = "SET_CHANGELOG_RESOURCES_THROTTLED";
export const ACTIVITY_FORM_INACTIVE_CHANGED = "ACTIVITY_FORM_INACTIVE_CHANGED";
export const DELETE_CHANGELOG_DRAFT_STARTED = "DELETE_CHANGELOG_DRAFT_STARTED";
export const DELETE_CHANGELOG_DRAFT_FINISHED = "DELETE_CHANGELOG_DRAFT_FINISHED";
export const DELETE_CHANGELOG_DRAFT_FAILED = "DELETE_CHANGELOG_DRAFT_FAILED";
export const CREATE_CHANGELOG_PDF = "CREATE_CHANGELOG_PDF";
export const SET_USER_EMAIL_CASE_SENSITIVE = "SET_USER_EMAIL_CASE_SENSITIVE";

export const DATA_TO_EXCEL_STARTED = "DATA_TO_EXCEL_STARTED";
export const DATA_TO_EXCEL_FINISHED = "DATA_TO_EXCEL_FINISHED";
export const DATA_TO_EXCEL_FAILED = "DATA_TO_EXCEL_FAILED";

export const GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_STARTED = "GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_STARTED";
export const GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FAILED = "GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FAILED";
export const GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FINISHED = "GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FINISHED";
export const DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED = "DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED";
export const DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED = "DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED";
export const DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED = "DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED";
export const ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED = "ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED";
export const ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED = "ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED";
export const ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED = "ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED";
export const EXPENSE_TYPE_ITEM_GL_ACCOUNT_CHANGED = "EXPENSE_TYPE_ITEM_GL_ACCOUNT_CHANGED";

export const GET_ALL_COST_CENTERS_STARTED = "GET_ALL_COST_CENTERS_STARTED";
export const GET_ALL_COST_CENTERS_FINISHED = "GET_ALL_COST_CENTERS_FINISHED";
export const GET_ALL_COST_CENTERS_FAILED = "GET_ALL_COST_CENTERS_FAILED";
export const EDIT_COST_CENTER_ACTION_PRESSED = "EDIT_COST_CENTER_ACTION_PRESSED";
export const EDIT_COST_CENTER_PROPERTY = "EDIT_COST_CENTER_PROPERTY";
export const EDIT_NEW_COST_CENTER_PROPERTY = "EDIT_NEW_COST_CENTER_PROPERTY";
export const ADD_COST_CENTER_STARTED = "ADD_COST_CENTER_STARTED";
export const ADD_COST_CENTER_FAILED = "ADD_COST_CENTER_FAILED";
export const ADD_COST_CENTER_FINISHED = "ADD_COST_CENTER_FINISHED";
export const EDIT_COST_CENTER_STARTED = "EDIT_COST_CENTER_STARTED";
export const EDIT_COST_CENTER_FAILED = "EDIT_COST_CENTER_FAILED";
export const EDIT_COST_CENTER_FINISHED = "EDIT_COST_CENTER_FINISHED";

export const SET_USER_LOGIN = 'SET_USER_LOGIN';

export const ACTIVITY_FORM_REGION_TYPE_CHANGED = "ACTIVITY_FORM_REGION_TYPE_CHANGED";
export const ACTIVITY_FORM_SUB_DOMAIN_CHANGED = "ACTIVITY_FORM_SUB_DOMAIN_CHANGED";
export const ACTIVITY_FORM_COST_CENTER_CHANGED = "ACTIVITY_FORM_COST_CENTER_CHANGED";

export const GET_COST_CENTERS_STARTED = "GET_COST_CENTERS_STARTED";
export const GET_COST_CENTERS_FINISHED = "GET_COST_CENTERS_FINISHED";
export const GET_COST_CENTERS_FAILED = "GET_COST_CENTERS_FAILED";

export const REGIONAL_CHANGELOG_DOMAIN_CHANGED = "REGIONAL_CHANGELOG_DOMAIN_CHANGED";
export const REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED = "REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED";
export const REGIONAL_CHANGELOG_COST_CENTER_CHANGED = "REGIONAL_CHANGELOG_COST_CENTER_CHANGED";
export const REGIONAL_CHANGELOG_BUDGET_YEAR_CHANGED = "REGIONAL_CHANGELOG_BUDGET_YEAR_CHANGED";
export const REGIONAL_CHANGELOG_SET_STATE = "REGIONAL_CHANGELOG_SET_STATE";
export const INITIALIZE_REGIONAL_CHANGELOG_FORM = "INITALIZE_REGIONAL_CHANGELOG_FORM";
export const INITIALIZE_REGIONAL_CHANGELOG_FORM_FINISHED = "INITIALIZE_REGIONAL_CHANGELOG_FORM_FINISHED";
export const INITIALIZE_EDIT_REGIONAL_CHANGELOG_FORM = "INITALIZE_EDIT_REGIONAL_CHANGELOG_FORM";

export const REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED";

export const REGIONAL_CHANGELOG_RESOURCE_ITEM_PLANS_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_PLANS_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTUALS_CHANGED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTUALS_CHANGED";

export const REGIONAL_CHANGELOG_ADD_RESOURCE_ITEM = "REGIONAL_CHANGELOG_ADD_RESOURCE_ITEM";
export const REGIONAL_CHANGELOG_DELETE_RESOURCE_ITEM = "REGIONAL_CHANGELOG_DELETE_RESOURCE_ITEM";

export const REGIONAL_CHANGELOG_FORM_SUBMIT_STARTED = "REGIONAL_CHANGELOG_FORM_SUBMIT_STARTED";
export const REGIONAL_CHANGELOG_FORM_SUBMIT_FAILED = "REGIONAL_CHANGELOG_FORM_SUBMIT_FAILED";
export const REGIONAL_CHANGELOG_FORM_SUBMIT_FINISHED = "REGIONAL_CHANGELOG_FORM_SUBMIT_FINISHED";
export const REGIONAL_CHANGELOG_FORM_RESET_FORM = "REGIONAL_CHANGELOG_FORM_RESET_FORM";
export const REGIONAL_CHANGELOG_FORM_ERRORS_CHANGED = "REGIONAL_CHANGELOG_FORM_ERRORS_CHANGED";

export const REGIONAL_CHANGELOG_CHANGE_LOADING_SPINNER = "REGIONAL_CHANGELOG_CHANGE_LOADING_SPINNER";
export const REGIONAL_CHANGELOG_ACTIVITIES_CHANGED = "REGIONAL_CHANGELOG_ACTIVITIES_CHANGED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_STARTED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_STARTED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FAILED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FAILED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED";

export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_STARTED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_STARTED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FAILED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FAILED";
export const REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED = "REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED";


export const INITIALIZE_REGIONAL_CHANGELOG_LIST = "INITIALIZE_REGIONAL_CHANGELOG_LIST";
export const GET_REGIONAL_CHANGELOG_COLUMNS_STARTED = "GET_REGIONAL_CHANGELOG_COLUMNS_STARTED";
export const GET_REGIONAL_CHANGELOG_COLUMNS_FINISHED = "GET_REGIONAL_CHANGELOG_COLUMNS_FINISHED";
export const GET_REGIONAL_CHANGELOG_COLUMNS_FAILED = "GET_REGIONAL_CHANGELOG_COLUMNS_FAILED";
export const GET_REGIONAL_CHANGELOGS_STARTED = "GET_REGIONAL_CHANGELOGS_STARTED";
export const GET_REGIONAL_CHANGELOGS_FINISHED = "GET_REGIONAL_CHANGELOGS_FINISHED";
export const GET_REGIONAL_CHANGELOGS_FAILED = "GET_REGIONAL_CHANGELOGS_FAILED";
export const UPDATE_REGIONAL_CHANGELOG_COLUMNS = "UPDATE_REGIONAL_CHANGELOG_COLUMNS";
export const REGIONAL_CHANGELOGS_TAB_CHANGED = "REGIONAL_CHANGELOGS_TAB_CHANGED";
export const REGIONAL_CHANGELOG_HISTORY_CHANGED = "REGIONAL_CHANGELOG_HISTORY_CHANGED";


export const SAP_ACTUALS_LOADING_SPINNER_CHANGED = "SAP_ACTUALS_LOADING_SPINNER_CHANGED";
export const GET_SAP_ACTUALS_STARTED = "GET_SAP_ACTUALS_STARTED";
export const GET_SAP_ACTUALS_FINISHED = "GET_SAP_ACTUALS_FINISHED";
export const GET_SAP_ACTUALS_FAILED = "GET_SAP_ACTUALS_FAILED";
export const UPDATE_SAP_ACTUALS_COLUMNS_STARTED = "UPDATE_SAP_ACTUALS_COLUMNS_STARTED";
export const UPDATE_SAP_ACTUALS_COLUMNS_FAILED = "UPDATE_SAP_ACTUALS_COLUMNS_FAILED";
export const UPDATE_SAP_ACTUALS_COLUMNS_FINISHED = "UPDATE_SAP_ACTUALS_COLUMNS_FINISHED";
export const GET_SAP_ACTUALS_COLUMNS_FAILED = "GET_SAP_ACTUALS_COLUMNS_FAILED";
export const GET_SAP_ACTUALS_COLUMNS_STARTED = "GET_SAP_ACTUALS_COLUMNS_STARTED";
export const GET_SAP_ACTUALS_COLUMNS_FINISHED = "GET_SAP_ACTUALS_COLUMNS_FINISHED";

export const DELIVERY_CONFIRMATION_FORM_CL_ID_CHANGED = "DELIVERY_CONFIRMATION_FORM_CL_ID_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_CREATED_BY_CHANGED = "DELIVERY_CONFIRMATION_FORM_CREATED_BY_CHANGED";
export const OPEN_INFO_DIALOG = "OPEN_INFO_DIALOG";
export const CLOSE_INFO_DIALOG = "CLOSE_INFO_DIALOG";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_VISIBILITY_CHANGED = "SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_VISIBILITY_CHANGED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_LOADING_CHANGED = "SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_LOADING_CHANGED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_QUESTION_CHANGED = "SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_QUESTION_CHANGED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_STARTED = "SEND_BUDGET_PLAN_NOTIFICATIONS_STARTED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_FINISHED = "SEND_BUDGET_PLAN_NOTIFICATIONS_FINISHED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_FAILED = "SEND_BUDGET_PLAN_NOTIFICATIONS_FAILED";
export const SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_DISABLED_CHANGED = "SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_DISABLED_CHANGED";

export const BACKGROUND_TASK_VISIBILITY_CHANGED = "BACKGROUND_TASK_VISIBILITY_CHANGED";
export const BACKGROUND_TASK_MESSAGE_CHANGED = "BACKGROUND_TASK_MESSAGE_CHANGED";
export const CHANGELOG_FORM_LATEST_CHANGES_CHANGED = "CHANGELOG_FORM_LATEST_CHANGES_CHANGED";
export const CHANGELOG_FORM_SHOW_CHANGES_CHANGED = "CHANGELOG_FORM_SHOW_CHANGES_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_LATEST_CHANGES_CHANGED = "DELIVERY_CONFIRMATION_FORM_LATEST_CHANGES_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_SHOW_CHANGES_CHANGED = "DELIVERY_CONFIRMATION_FORM_SHOW_CHANGES_CHANGED";
export const RESOURCE_MODEL_FORM_LATEST_CHANGES_CHANGED = "RESOURCE_MODEL_FORM_LATEST_CHANGES_CHANGED";
export const RESOURCE_MODEL_FORM_SHOW_CHANGES_CHANGED = "RESOURCE_MODEL_FORM_SHOW_CHANGES_CHANGED";
export const REGIONAL_CHANGELOG_FORM_COMMENT_CHANGED = "REGIONAL_CHANGELOG_FORM_COMMENT_CHANGED";
export const REGIONAL_CHANGELOG_FORM_LATEST_CHANGES_CHANGED = "REGIONAL_CHANGELOG_FORM_LATEST_CHANGES_CHANGED";
export const REGIONAL_CHANGELOG_FORM_SHOW_CHANGES_CHANGED = "REGIONAL_CHANGELOG_FORM_SHOW_CHANGES_CHANGED";

export const ADD_TAG_STARTED = "ADD_TAG_STARTED";
export const ADD_TAG_FAILED = "ADD_TAG_FAILED";
export const ADD_TAG_FINISHED = "ADD_TAG_FINISHED";
export const EDIT_TAG_STARTED = "EDIT_TAG_STARTED";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";
export const EDIT_TAG_FINISHED = "EDIT_TAG_FINISHED";
export const TAG_PICKLIST_NEW_ITEM_CHANGED = "TAG_PICKLIST_NEW_ITEM_CHANGED";
export const TAG_PICKLIST_EDIT_ITEM_CHANGED = "TAG_PICKLIST_EDIT_ITEM_CHANGED";
export const ADD_TAG_CONNECTION_STARTED = "ADD_TAG_CONNECTION_STARTED";
export const DELETE_TAG_CONNECTION_STARTED = "DELETE_TAG_CONNECTION_STARTED";
export const GET_ALL_TAGS_STARTED = "GET_ALL_TAGS_STARTED";
export const ADD_TAG_CONNECTION_FINISHED = "ADD_TAG_CONNECTION_FINISHED";
export const ADD_TAG_CONNECTION_FAILED = "ADD_TAG_CONNECTION_FAILED";
export const DELETE_TAG_CONNECTION_FINISHED = "DELETE_TAG_CONNECTION_FINISHED";
export const DELETE_TAG_CONNECTION_FAILED = "DELETE_TAG_CONNECTION_FAILED";
export const GET_ALL_TAGS_FAILED = "GET_ALL_TAGS_FAILED";
export const GET_ALL_TAGS_FINISHED = "GET_ALL_TAGS_FINISHED";

export const GET_CHANGELOG_RESOURCE_TAGS_FINISHED = "GET_CHANGELOG_RESOURCE_TAGS_FINISHED";
export const GET_CHANGELOG_TAGS_FINISHED = "GET_CHANGELOG_TAGS_FINISHED";
export const CHANGELOG_TAGS_CHANGED = "CHANGELOG_TAGS_CHANGED";
export const CHANGELOG_RESOURCE_TAGS_CHANGED = "CHANGELOG_RESOURCE_TAGS_CHANGED";

export const DELIVERY_CONFIRMATION_FORM_TAGS_CHANGED = "DELIVERY_CONFIRMATION_FORM_TAGS_CHANGED";
export const DELIVERY_CONFIRMATION_FORM_TAGS_SUGGESTIONS_CHANGED = "DELIVERY_CONFIRMATION_FORM_TAGS_SUGGESTIONS_CHANGED";

export const GET_REGIONAL_CHANGELOG_TAGS_FINISHED = "GET_REGIONAL_CHANGELOG_TAGS_FINISHED";
export const REGIONAL_CHANGELOG_TAGS_CHANGED = "REGIONAL_CHANGELOG_TAGS_CHANGED";
export const REGIONAL_CHANGELOG_LINE_TAGS_CHANGED = "REGIONAL_CHANGELOG_LINE_TAGS_CHANGED";
export const GET_REGIONAL_CHANGELOG_LINE_TAGS_FINISHED = "GET_REGIONAL_CHANGELOG_LINE_TAGS_FINISHED";