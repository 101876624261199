export const NAVIGATE_CHANGELOG_VIEWMY = 'navigate:changelog:viewMy';
export const NAVIGATE_CHANGELOG_VIEWALL ='navigate:changelog:viewAll';
export const NAVIGATE_CHANGELOG_CREATE = 'navigate:changelog:create';
export const NAVIGATE_DELIVERY_REVIEW = 'navigate:delivery:review';
export const NAVIGATE_DELIVERY_SUBMIT = 'navigate:delivery:submit';
export const NAVIGATE_DELIVERY_APPROVE = 'navigate:delivery:approve';
export const NAVIGATE_RESOURCEMODEL = 'navigate:resourcemodel';
export const NAVIGATE_EDITPICKLIST = 'navigate:editpicklist';
export const NAVIGATE_DELIVERY_VIEW_ALL = "NAVIGATE_DELIVERY_VIEW_ALL";
export const NAVIGATE_DELIVERY_FILL_IN = "NAVIGATE_DELIVERY_FILL_IN";

export const LANDINGPAGE_VIEWDOMAINS =  'landingPage:viewDomains';

export const VIEW_EXPENSE_TYPE_PICKLIST = "editpicklist:viewExpenseTypePicklist";
export const VIEW_AREAS_PICKLIST = "editpicklist:viewAreasPicklist";
export const VIEW_CURRENCIES_PICKLIST = "editpicklist:viewCurrenciesPicklist";
export const VIEW_GRADES_PICKLIST = "editpicklist:viewGradesPicklist";
export const VIEW_RESOURCE_LOCATIONS_PICKLIST = "editpicklist:viewResourceLocationsPicklist";
export const VIEW_DOMAINS_PICKLIST ="VIEW_DOMAINS_PICKLIST";
export const VIEW_RESOURCE_TYPE_PICKLIST = "VIEW_RESOURCE_TYPE_PICKLIST";
export const VIEW_DOMAIN_PICKLIST = "VIEW_DOMAIN_PICKLIST";
export const VIEW_SUB_DOMAIN_PICKLIST = "VIEW_SUB_DOMAIN_PICKLIST";
export const VIEW_PO_LOCATION_PICKLIST = "VIEW_PO_LOCATION_PICKLIST";
export const VIEW_COST_TYPE_PICKLIST = "VIEW_COST_TYPE_PICKLIST";
export const VIEW_TAGS_PICKLIST = "VIEW_TAGS_PICKLIST";

export const EDIT_FORM_TAGS = "EDIT_FORM_TAGS";
export const EDIT_CHANGELOG_STATUS="EDIT_CHANGELOG_STATUS";
export const VIEW_EDIT_CHANGELOG_MENU="VIEW_EDIT_CHANGELOG_MENU";
export const EDIT_CHANGELOG_EXPENSE_TYPE = "EDIT_CHANGELOG_EXPENSE_TYPE";
export const EDIT_CHANGELOG_ACTIVITY_NAME = "EDIT_CHANGELOG_ACTIVITY_NAME";
export const EDIT_CHANGELOG_CONSENT_NAME = "EDIT_CHANGELOG_CONSENT_NAME";
export const EDIT_CHANGELOG_VENDOR_TYPE = "EDIT_CHANGELOG_VENDOR_TYPE";
export const EDIT_CHANGELOG_VENDOR = "EDIT_CHANGELOG_VENDOR";
export const EDIT_CHANGELOG_PO_WBS = "EDIT_CHANGELOG_PO_WBS";
export const EDIT_CHANGELOG_YEAR = "EDIT_CHANGELOG_YEAR";
export const EDIT_CHANGELOG_RATE_YEAR = "EDIT_CHANGELOG_RATE_YEAR";
export const EDIT_CHANGELOG_ADD_NEW_RESOURCE = "EDIT_CHANGELOG_ADD_NEW_RESOURCE";
export const EDIT_CHANGELOG_DOMAIN = "EDIT_CHANGELOG_DOMAIN";
export const EDIT_CHANGELOG_SUB_DOMAIN = "EDIT_CHANGELOG_SUB_DOMAIN";
export const EDIT_CHANGELOG_ACTIVITY_TYPE = "EDIT_CHANGELOG_ACTIVITY_TYPE";
export const EDIT_CHANGELOG_CONSENT_TYPE = "EDIT_CHANGELOG_CONSENT_TYPE";


export const CAN_DUPLICATE_CHANGELOG_RESOURCE = "CAN_DUPLICATE_CHANGELOG_RESOURCE";
export const EDIT_CHANGELOG_RESOURCE_PREPAYMENT = "EDIT_CHANGELOG_RESOURCE_PREPAYMENT";

export const EDIT_CHANGELOG_LOCATION = "EDIT_CHANGELOG_LOCATION";
export const EDIT_CHANGELOG_ASSIGNMENT_SITE = "EDIT_CHANGELOG_ASSIGNMENT_SITE";
export const EDIT_CHANGELOG_GRADE = "EDIT_CHANGELOG_GRADE";
export const EDIT_CHANGELOG_UNIT_PRICE = "EDIT_CHANGELOG_UNIT_PRICE";
export const EDIT_CHANGELOG_PER_DIEM = "EDIT_CHANGELOG_PER_DIEM";
export const EDIT_CHANGELOG_CURRENCY = "EDIT_CHANGELOG_CURRENCY";
export const EDIT_CHANGELOG_DELETE_RESOURCE = "EDIT_CHANGELOG_DELETE_RESOURCE";
export const EDIT_CHANGELOG_RESOURCE_TYPE = "EDIT_CHANGELOG_RESOURCE_TYPE";
export const EDIT_CHANGELOG_RESOURCE_NAME = "EDIT_CHANGELOG_RESOURCE_NAME";
export const EDIT_CHANGELOG_SUPPLIER_REPRESENTATIVE = "EDIT_CHANGELOG_SUPPLIER_REPRESENTATIVE";
export const EDIT_CHANGELOG_DC_APPROVER = "EDIT_CHANGELOG_DC_APPROVER";
export const EDIT_CHANGELOG_LEANIX = "EDIT_CHANGELOG_LEANIX";
export const EDIT_CHANGELOG_EPPM = "EDIT_CHANGELOG_EPPM";

export const EDIT_CHANGELOG_START_DATE = "EDIT_CHANGELOG_START_DATE";
export const EDIT_CHANGELOG_END_DATE = "EDIT_CHANGELOG_END_DATE";
export const EDIT_CHANGELOG_SCOUT_ID = "EDIT CHANGELOG_SCOUT_ID"; 
export const SUBMIT_EDIT_CHANGELOG = "SUBMIT_EDIT_CHANGELOG";
export const EDIT_CHANGELOG_DELETE_ATTACHMENT = "EDIT_CHANGELOG_DELETE_ATTACHMENT";
export const EDIT_CHANGELOG_ADD_ATTACHMENT = "EDIT_CHANGELOG_ADD_ATTACHMENT";
export const EDIT_CHANGELOG_MONTH_VALUES = "EDIT_CHANGELOG_MONTH_VALUES";
export const EDIT_CHANGELOG_COMMENT = "EDIT_CHANGELOG_COMMENT";

export const VIEW_VENDOR_PICKLIST = "VIEW_VENDOR_PICKLIST";
export const VIEW_ACTIVITIES = "VIEW_ACTIVITIES"
export const VIEW_ACTIVITY_FORM = "VIEW_ACTIVITY_FORM"
export const VIEW_WBSES = "VIEW_WBSES";
export const VIEW_VENDOR_RATES = "VIEW_VENDOR_RATES";
export const VIEW_WBS_FORM = "VIEW_WBS_FORM";
export const VIEW_VENDOR_RATE_FORM = "VIEW_VENDOR_RATE_FORM";



export const EDIT_DC_FORM_USERS = "EDIT_DC_FORM_USERS";

export const DC_back_External = 'DC_back_External';
export const DC_Comment_External = 'DC_Comment_External';
export const DC_edit_External = 'DC_edit_External';
export const DC_save_External = 'DC_save_External';
export const DC_generatePdf_External = 'DC_generatePdf_External';
export const DC_submitForApproval_External = 'DC_submitForApproval_External';
export const DC_submitAndApprove_External = 'DC_submitAndApprove_External';
export const DC_submitUpdated_External = 'DC_submitUpdated_External';
export const DC_restart_External = 'DC_restart_External';
export const DC_process_External = 'DC_process_External';
export const DC_approve_External = 'DC_approve_External';
export const DC_reject_External = 'DC_reject_External';

export const DC_processingNotApplicable_External = 'DC_processingNotApplicable_External';
export const EDIT_DC_INTERNAL_ACTUAL_PRICE = "EDIT_DC_INTERNAL_ACTUAL_PRICE";
export const EDIT_DC_COMMENTS = "EDIT_DC_COMMENTS";
export const EDIT_DC_ATTACHMENTS = "EDIT_DC_ATTACHMENTS";

export const CHANGE_CHANGELOG_STATUS = "CHANGE_CHANGELOG_STATUS";
export const VIEW_CHANGELOG_COMMENTS = "VIEW_CHANGELOG_COMMENTS";
export const VIEW_CHANGELOG_HISTORY = "VIEW_CHANGELOG_HISTORY";
export const VIEW_IMPORT_MASTERDATA = 'VIEW_IMPORT_MASTERDATA';
export const EXPORT_DATA = "EXPORT_DATA";
export const NAVIGATE_DELIVERY_TO_BE_PROCESSED = "NAVIGATE_DELIVERY_TO_BE_PROCESSED";
export const HAS_ENABLED_CHANGELOG_EDIT_FORM_TICKBOX_BY_DEFAULT = "HAS_ENABLED_CHANGELOG_EDIT_FORM_TICKBOX_BY_DEFAULT";
export const IS_BACKOFFICE_OR_ADMIN = "IS_BACKOFFICE_OR_ADMIN";
export const IS_SUPPLIER = "IS_SUPPLIER";
export const SAVE_CHANGELOG_DRAFT = "SAVE_CHANGELOG_DRAFT";
export const IS_REQUESTOR = "IS_REQUESTOR";
export const IS_SUPER_REQUESTOR = "IS_SUPER_REQUESTOR";
export const VIEW_COST_CENTER_PICKLIST = "VIEW_COST_CENTER_PICKLIST";
export const NAVIGATE_CREATE_BUDGET_PLAN_BY_CC = "NAVIGATE_CREATE_BUDGET_PLAN_BY_CC";
export const NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY = "NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY";
export const NAVIGATE_VIEW_ALL_BUDGET_PLANS = "NAVIGATE_VIEW_ALL_BUDGET_PLANS";
export const NAVIGATE_VIEW_MY_BUDGET_PLANS = "NAVIGATE_VIEW_MY_BUDGET_PLANS";

export const EDIT_BUDGET_PLAN_DOMAIN = "EDIT_BUDGET_PLAN_DOMAIN";
export const EDIT_BUDGET_PLAN_SUBDOMAIN = "EDIT_BUDGET_PLAN_SUBDOMAIN";
export const EDIT_BUDGET_PLAN_COST_CENTER = "EDIT_BUDGET_PLAN_COST_CENTER";
export const EDIT_BUDGET_PLAN_BUDGET_YEAR = "EDIT_BUDGET_PLAN_BUDGET_YEAR";
export const EDIT_BUDGET_PLAN_ITEM_COST_TYPE = "EDIT_BUDGET_PLAN_ITEM_COST_TYPE";
export const EDIT_BUDGET_PLAN_ITEM_EXPENSE_TYPE = "EDIT_BUDGET_PLAN_ITEM_EXPENSE_TYPE";
export const EDIT_BUDGET_PLAN_ITEM_CURRENCY = "EDIT_BUDGET_PLAN_ITEM_CURRENCY";
export const EDIT_BUDGET_PLAN_DELETE_ITEM = "EDIT_BUDGET_PLAN_DELETE_ITEM";
export const EDIT_BUDGET_PLAN_ADD_ITEM = "EDIT_BUDGET_PLAN_ADD_ITEM";
export const EDIT_BUDGET_PLAN_ITEM_COST_CENTER = "EDIT_BUDGET_PLAN_ITEM_COST_CENTER";
export const EDIT_BUDGET_PLAN_ITEM_ACTIVITY = "EDIT_BUDGET_PLAN_ITEM_ACTIVITY";
export const EDIT_BUDGET_PLAN_ITEM_WBS = "EDIT_BUDGET_PLAN_ITEM_WBS";
export const EDIT_BUDGET_PLAN_ITEM_ALL_PLANS = "EDIT_BUDGET_PLAN_ITEM_ALL_PLANS";
export const EDIT_BUDGET_PLAN_ITEM_PLANS = "EDIT_BUDGET_PLAN_ITEM_PLANS";
export const EDIT_BUDGET_PLAN_ITEM_ACTUALS = "EDIT_BUDGET_PLAN_ITEM_ACTUALS";
export const VIEW_SAP_ACTUALS = "VIEW_SAP_ACTUALS";

export const VIEW_CHANGELOG_ACTIVITIES_CHANGELOGS = 'VIEW_CHANGELOG_ACTIVITIES_CHANGELOGS';