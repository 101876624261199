import React, { Component } from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableWithServerSizePagination from '../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import * as changelogActionCreators from '../../../store/actions/Changelogs';
import * as userActionCreators from '../../../store/actions/User';
import history from '../../../history';
import { statuses } from '../../../constants/changelogStatuses';
import { canFunction } from '../../../config/Can';
import * as rules from '../../../config/Rules';
import DataExportContainer from '../../../containers/DataExport/DataToExcelContainer';
import ExcelTypes from '../../../constants/excelTypes';

let defaultColumns;
const defaultDcApprover = [
    {
        header: "CL ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: "CL status",
        dataProperty: 'status',
        width: 350,
        isVisible: true,
    },
    {
        header: 'Changelog draft',
        dataProperty: 'isDraft',
        width: 100,
        isVisible: true
    },
    {
        header: 'Cost Type',
        dataProperty: 'costTypeName',
        width: 120,
        isVisible: true,
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "POWBS",
        dataProperty: 'poWbs',
        width: 110,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'supplier',
        width: 110,
        isVisible: true,
    },
    {
        header: "DC approver",
        dataProperty: 'approver',
        width: 140,
        isVisible: true,
    },
    {
        header: "Supplier Representative",
        dataProperty: 'supplierRepresentative',
        width: 180,
        isVisible: true,
    },
    {
        header: "Year",
        width: 70,
        dataProperty: 'year',
        isVisible: true
    },
    {
        header: "Created By",
        width: 120,
        dataProperty: 'createdBy',
        isVisible: true
    },
    {
        header: "Created Date",
        width: 140,
        dataProperty: 'createdDate',
        disabledFiltering: true,
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    },
    {
        header: "EPPM Project ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "EPPM Project Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "Scout ID",
        dataProperty: 'scoutId',
        width: 140,
        isVisible: true,
    }
]
const defaultBoth = [
    {
        header: "CL ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: "CL status",
        dataProperty: 'status',
        width: 350,
        isVisible: true,
    },
    {
        header: 'Cost Type',
        dataProperty: 'costTypeName',
        width: 120,
        isVisible: true,
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "POWBS",
        dataProperty: 'poWbs',
        width: 110,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'supplier',
        width: 110,
        isVisible: true,
    },
    {
        header: "DC approver",
        dataProperty: 'approver',
        width: 140,
        isVisible: true,
    },
    {
        header: "Planner",
        width: 140,
        dataProperty: 'planner',
        isVisible: true
    },
    {
        header: "Supplier Representative",
        dataProperty: 'supplierRepresentative',
        width: 180,
        isVisible: true,
    },
    {
        header: "Year",
        width: 70,
        dataProperty: 'year',
        isVisible: true
    },
    {
        header: 'Last modified date',
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true,
    },
    {
        header: "Last modified by",
        width: 140,
        dataProperty: 'lastModifiedBy',
        isVisible: true
    },
    {
        header: "Created By",
        width: 120,
        dataProperty: 'createdBy',
        isVisible: true
    },
    {
        header: "Created Date",
        width: 140,
        dataProperty: 'createdDate',
        disabledFiltering: true,
        isVisible: true
    },
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "EPPM Project ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "EPPM Project Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    },
    {
        header: "Scout ID",
        dataProperty: 'scoutId',
        width: 140,
        isVisible: true,
    }

]
const defaultBo = [
    {
        header: "CL ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: "CL status",
        dataProperty: 'status',
        width: 350,
        isVisible: true,
    },
    {
        header: 'Cost Type',
        dataProperty: 'costTypeName',
        width: 120,
        isVisible: true,
    },
    {
        header: "Supplier/Team",
        dataProperty: 'supplier',
        width: 110,
        isVisible: true,
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "POWBS",
        dataProperty: 'poWbs',
        width: 110,
        isVisible: true
    },
    {
        header: "DC approver",
        dataProperty: 'approver',
        width: 140,
        isVisible: true,
    },
    {
        header: "Planner",
        width: 140,
        dataProperty: 'planner',
        isVisible: true
    },
    {
        header: "Year",
        width: 70,
        dataProperty: 'year',
        isVisible: true
    },
    {
        header: 'Last modified date',
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true,
    },
    {
        header: "Last modified by",
        width: 140,
        dataProperty: 'lastModifiedBy',
        isVisible: true
    },
    {
        header: "Created By",
        width: 120,
        dataProperty: 'createdBy',
        isVisible: true
    },
    {
        header: "Created Date",
        width: 140,
        dataProperty: 'createdDate',
        disabledFiltering: true,
        isVisible: true
    },
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    },
    {
        header: "EPPM Project ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "EPPM Project Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "Scout ID",
        dataProperty: 'scoutId',
        width: 140,
        isVisible: true,
    }
]
const years = [];
const currentYear = new Date().getFullYear();
years.push({ label: currentYear - 2, value: currentYear - 2 });
years.push({ label: currentYear - 1, value: currentYear - 1 });
years.push({ label: currentYear, value: currentYear });

const exportToExcelConfig = {
    'Activity Name': ExcelTypes.Text,
    'Activity Type': ExcelTypes.Text,
    'Assignment Site': ExcelTypes.Text,
    'Changelog ID': ExcelTypes.WholeNumber,
    'Changelog URL': ExcelTypes.Text,
    'Cost Type': ExcelTypes.Text,
    'Created By': ExcelTypes.Text,
    'Created Date': ExcelTypes.Date,
    'Currency': ExcelTypes.Text,
    'Delivery Confirmation Approver': ExcelTypes.Text,
    'Expense Type': ExcelTypes.Text,
    'Last modified by': ExcelTypes.Text,
    'Last modified date': ExcelTypes.Date,
    'PO Location': ExcelTypes.Text,
    'PO WBS': ExcelTypes.Text,
    'Per Diem Rate': ExcelTypes.DecimalNumber,
    'Plan Apr': ExcelTypes.DecimalNumber,
    'Plan Aug': ExcelTypes.DecimalNumber,
    'Plan Dec': ExcelTypes.DecimalNumber,
    'Plan Feb': ExcelTypes.DecimalNumber,
    'Plan Jan': ExcelTypes.DecimalNumber,
    'Plan Jul': ExcelTypes.DecimalNumber,
    'Plan Jun': ExcelTypes.DecimalNumber,
    'Plan Mar': ExcelTypes.DecimalNumber,
    'Plan May': ExcelTypes.DecimalNumber,
    'Plan Nov': ExcelTypes.DecimalNumber,
    'Plan Oct': ExcelTypes.DecimalNumber,
    'Plan Sep': ExcelTypes.DecimalNumber,
    'Planner': ExcelTypes.Text,
    'Platform': ExcelTypes.Text,
    'Profile/Grade': ExcelTypes.Text,
    'Rate Year': ExcelTypes.WholeNumber,
    'Resource Location': ExcelTypes.Text,
    'Resource Name': ExcelTypes.Text,
    'Resource Type': ExcelTypes.Text,
    'Start Date': ExcelTypes.Date,
    'End Date': ExcelTypes.Date,
    'Status': ExcelTypes.Text,
    'Sub-platform': ExcelTypes.Text,
    'Supplier Representative': ExcelTypes.Text,
    'Supplier/Team': ExcelTypes.Text,
    'Unit Price': ExcelTypes.DecimalNumber,
    'Vendor Type': ExcelTypes.Text,
    'Year': ExcelTypes.WholeNumber,
    'LeanIX ID': ExcelTypes.Text,
    'LeanIX Name': ExcelTypes.Text,
    'EPPM Project ID': ExcelTypes.Text,
    'EPPM Project Name': ExcelTypes.Text,
    'Scout ID': ExcelTypes.Text
}
for (let i = 1; i <= 4; i++) {
    const year = currentYear + i;
    years.push({ label: year, value: year });
}
const mapStateToProps = (state) => {
    let role = 0;
    if (state.user.roles.indexOf("backOffice") !== -1 || state.user.roles.indexOf("admin") !== -1) {
        role = role + 2;
    }
    if (state.user.roles.indexOf("dcApprover") !== -1) {
        role = role + 1;
    }
    if (history.location.pathname.indexOf("viewAll") === -1) {
        defaultColumns = defaultDcApprover
    } else if (role === 3) {
        defaultColumns = defaultBoth
    } else if (role === 2) {
        defaultColumns = defaultBo
    } else {
        defaultColumns = defaultDcApprover
    }
    if (history.location.pathname.indexOf("viewAll") !== -1) {
        role = role + 10
    }
    const columns = state.changelogs.columns && state.changelogs.columns.length !== 0 ? state.changelogs.columns : defaultColumns;
    let domains;
    if (history.location.pathname.indexOf("changelogs/my") !== -1) {
        domains = [...(state.user.userDomains['my'] || [])];
    } else {
        domains = [...state.domains.domains];
    }

    domains.push({ name: "view all", id: "view all" });
    return {
        tableState: state.table.parsedFilters,
        // userDomains: state.user.userDomains || [],
        roleIndex: role,
        userDomains: domains,
        selectedDomain: state.user.selectedDomain,
        selectedRole: state.user.selectedRole,
        domains: domains || [],
        columns: columns,
        loading: state.changelogs.loading,
        changelogsCount: state.changelogs.changelogs && state.changelogs.changelogs.itemsCount ? state.changelogs.changelogs.itemsCount : 0,
        currentPage: state.changelogs.changelogs && state.changelogs.changelogs.currentPage ? state.changelogs.changelogs.currentPage : 1,
        changelogs: state.changelogs.changelogs && state.changelogs.changelogs.items ? state.changelogs.changelogs.items : [],
        tabLoading: state.domains.loading || state.user.loading
    }
};

const mapDispatchToProps = (dispatch) => ({
    loadView: (payload) => {
        dispatch(changelogActionCreators.getChangelogColumnsStarted({ domain: payload.domain.name, role: payload.role }));
        let params;
        if (payload.queryParameters) {
            params = payload.queryParameters
        } else {
            params = {}
        }
        dispatch(changelogActionCreators.intializeChangelogListViewStarted({ userOnly: payload.userOnly, params: params, domain: payload.domain }));
    },
    updateColumns: (payload) => {
        dispatch(changelogActionCreators.updateChangelogColumnsStarted(payload));
    },
    getChangeLogs: (payload) => {
        dispatch(changelogActionCreators.getChangelogsStarted(payload));
    },
    selectDomain: (payload) => {
        dispatch(userActionCreators.selectDomain(payload))
    },
    getColumns: (payload) => {
        dispatch(changelogActionCreators.getChangelogColumnsStarted(payload))
    }
});

class ChangeLogViewer extends Component {
    //should return different domains based on viewall/my changelogs tab
    isUserOnly = () => {
        return this.props.location.pathname.indexOf('/my') !== -1;
    }

    getSelectedDomain() {
        return this.props.selectedDomain;
    }

    isBo = () => {
        return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
    }
    componentDidMount() {
        const viewAll = { name: "view all", id: "view all" };
        const selectedDomain = this.getSelectedDomain();
        const tableState = this.props.tableState[this.getTableName(selectedDomain)];
        if (tableState) {
            let domain = this.props.selectedDomain;
            if (!this.isUserOnly()) {
                domain = viewAll
            }
            const queryParameters = { ...tableState };
            this.props.loadView({
                queryParameters: queryParameters,
                userOnly: this.isUserOnly(),
                domain: selectedDomain,
                role: this.props.roleIndex
            });
        } else {
            this.props.loadView({
                domain: selectedDomain,
                userOnly: this.isUserOnly(),
                role: this.props.roleIndex
            });
        }
    }
    getTableName = (selectedDomain) => {
        return `changelog${this.isUserOnly() ? "-user" : ""}${selectedDomain && selectedDomain.name !== "view all" ? `-${selectedDomain.id}` : ""}`
    }

    handleDeliveryConfirmationClick = (data) => {
    }

    handleHistoryClick = (data, e) => {
        if (e && e.button === 2) {
            return;
        }
        if (this.isUserOnly()) {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin + `/changelogs/my/${data.id}`, "_blank");
            } else {
                history.push(`/changelogs/my/${data.id}`);
            }
        } else {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin + `/changelogs/viewAll/${data.id}`, "_blank");
            } else {
                history.push(`/changelogs/viewAll/${data.id}`);
            }
        }
    }

    handleChangeDomain = (e, index) => {
        if (this.isUserOnly()) {
            this.props.selectDomain(this.props.userDomains[parseInt(index)]);
            this.props.getColumns({ domain: this.props.userDomains[parseInt(index)].name, role: this.props.roleIndex });
            const tableState = this.props.tableState[this.getTableName(this.props.userDomains[parseInt(index)])];
            if (tableState) {
                const domain = this.props.userDomains[parseInt(index)];
                const queryParameters = { ...tableState };
                this.props.getChangeLogs({ userOnly: true, params: queryParameters, wait: false, domain });
            } else {
                this.props.getChangeLogs({ userOnly: true, params: {}, wait: false, domain: this.props.userDomains[parseInt(index)] });
            }

        } else {
            const tableState = this.props.tableState[this.getTableName(this.props.userDomains[parseInt(index)])];
            this.props.selectDomain(this.props.domains[parseInt(index)]);
            this.props.getColumns({ domain: this.props.domains[parseInt(index)].name, role: this.props.roleIndex });
            if (tableState) {
                const domain = this.props.domains[parseInt(index)];
                const queryParameters = { ...tableState };
                this.props.getChangeLogs({ userOnly: false, params: queryParameters, wait: false, domain });
            } else {
                this.props.getChangeLogs({ userOnly: false, params: {}, domain: this.props.domains[parseInt(index)] });
            }
        }
    }

    onFetchData = (data, wait) => {
        const userOnly = this.isUserOnly();
        let domain = this.props.selectedDomain;
        const queryParameters = data;
        this.props.getChangeLogs({ userOnly: userOnly, params: queryParameters, wait: wait, domain });
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        if (this.isUserOnly()) {
            this.props.updateColumns({ domain: this.props.selectedDomain.name, columns: payload, role: this.props.roleIndex });
        } else {
            this.props.updateColumns({ domain: this.props.selectedDomain.name, columns: payload, role: this.props.roleIndex });
        }
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        if (this.isUserOnly()) {
            this.props.updateColumns({ domain: this.props.selectedDomain.name, columns: payload, role: this.props.roleIndex });
        } else {
            this.props.updateColumns({ domain: this.props.selectedDomain.name, columns: payload, role: this.props.roleIndex });
        }
    }

    createActionMenu = (data) => {
        let label = 'Edit'
        // if ((!this.isUserOnly() && canFunction('', rules.EDIT_CHANGELOG_STATUS)) || this.isUserOnly()) {
        //     label = "Edit";
        // } else {
        //     label = "View";
        // }
        return (
            <SimpleMenu
                icon={MoreVert}
                items={
                    [
                        // {onClick: this.handleDeliveryConfirmationClick, label: 'Delivery Confirmations'},
                        { onClick: this.handleHistoryClick, label: label }
                    ]
                }
                rowData={data}
            />
        )
    }
    getFileName = () => {
        if (this.isUserOnly()) {
            return "Changelogs-MyChangelogs"
        } else {
            return "Changelogs-ViewAll"
        }
    }

    render() {
        let selectedDomain;
        let domains
        if (this.isUserOnly()) {
            domains = this.props.userDomains;
            selectedDomain = this.props.selectedDomain;
        } else {
            domains = this.props.domains
            selectedDomain = this.props.selectedDomain;
        }
        let cols = [...this.props.columns]

        if (selectedDomain && selectedDomain.name === "view all") {
            const item = cols.find((col) => {
                return col.header === "Platform";
            });
            const index = cols.indexOf(item);
            if (index === -1) {
                cols.unshift({
                    header: "Platform",
                    dataProperty: 'domain',
                    width: 140,
                    isVisible: true,
                })
            }
        }

        let minWidth = 0;
        cols.forEach((column) => {
            if (column.isVisible) {
                minWidth += column.width;
            }
        });
        minWidth += 40;
        return (
            <React.Fragment>
                <Tabs onTabChanged={this.handleChangeDomain} selectedTab={selectedDomain} tabs={domains} loading={this.props.tabLoading} />
                <div style={{ marginRight: 48, marginTop: 32 }}>
                    <DataExportContainer
                        url={`api${this.isUserOnly() ? "/user" : ""}/changelogs/export${selectedDomain && selectedDomain.name !== "view all" ? `/domain/${selectedDomain.id}` : ""}`}
                        tableName={this.getTableName(selectedDomain)}
                        fileName={this.getFileName()}
                        fieldsTypes={exportToExcelConfig}
                    />
                    <DataTableWithServerSizePagination
                        sortable
                        navigateToDetail={this.handleHistoryClick}
                        key={selectedDomain.name + this.isUserOnly()}
                        tableName={this.getTableName(selectedDomain)}
                        minWidth={minWidth}
                        customColumnsWidthEnabled={true}
                        customFilters={{
                            status: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="status"
                                        label="Status"
                                        small
                                        width="100%"
                                        minWidth={350}
                                        suggestions={statuses}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            year: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="year"
                                        label="Year"
                                        small
                                        width="100%"
                                        minWidth={100}
                                        suggestions={years}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            isDraft: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="isDraft"
                                        label="Changelog Draft"
                                        small
                                        width="100%"
                                        minWidth={100}
                                        suggestions={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            }
                        }}
                        loading={this.props.loading}
                        count={this.props.changelogsCount}
                        general={{}}
                        columns={cols}
                        onFetchData={this.onFetchData}
                        data={this.props.changelogs}
                        actionMenu={this.createActionMenu}
                        onColumnsOrderChanged={this.onColumnOrderChanged}
                        onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                        currentPage={this.props.currentPage}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogViewer);