import React, { Component } from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableWithServerSizePagination from '../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import * as resourceModelActionCreators from '../../../store/actions/resourceModelActions';
import * as userActionCreators from '../../../store/actions/User';
import * as domainsActions from '../../../store/actions/domains';
import LaunchIcon from '@material-ui/icons/Launch';
import Button from '@material-ui/core/Button';
import GenerateDCsModal from './GenerateDCsModal';
import history from '../../../history';
import LinearProgress from '@material-ui/core/LinearProgress';
import DataExportContainer from '../../../containers/DataExport/DataToExcelContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import resourceTypes from '../../../constants/resourceTypes';
import { statuses } from '../../../constants/changelogStatuses';
import excelTypes from '../../../constants/excelTypes'

const defaultColumns = [
    {
        header: "RM ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: "CL status",
        dataProperty: 'changelogStatus',
        width: 350,
        isVisible: true,
    },
    {
        header: 'Cost Type',
        dataProperty: 'costType',
        width: 120,
        isVisible: true,
    },
    {
        header: 'Expense Type',
        dataProperty: 'expenseType',
        width: 120,
        isVisible: true,
    },
    {
        header: "Prepayment",
        dataProperty: 'prepayment',
        width: 80,
        isVisible: true,
    },
    {
        header: 'CL ID',
        dataProperty: 'changelogId',
        isVisible: true,
        width: 80
    },
    {
        header: "Activity Name",
        dataProperty: 'activity',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: "Cost Center",
        dataProperty: 'costCenter',
        width: 110,
        isVisible: true,
    },
    {
        header: "PO WBS",
        dataProperty: 'powbs',
        width: 110,
        isVisible: true
    },
    {
        header: "Resource Type",
        dataProperty: 'resourceType',
        width: 110,
        isVisible: true,
    },
    {
        header: "DC Approver",
        dataProperty: 'approver',
        width: 140,
        isVisible: true,
    },
    {
        header: "Supplier/Team",
        dataProperty: 'supplier',
        width: 140,
        isVisible: true,
    },
    {
        header: "Supplier Representative",
        dataProperty: 'supplierRepresentative',
        width: 180,
        isVisible: true,
    },
    {
        header: "Year",
        width: 70,
        dataProperty: 'year',
        isVisible: true
    },
    {
        header: "DC Y/N",
        width: 80,
        dataProperty: 'dcyn',
        isVisible: true
    },
    {
        header: "Last modified date",
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Last modified by",
        width: 140,
        dataProperty: 'lastModifiedBy',
        isVisible: true
    },
    {
        header: "Scout ID",
        dataProperty: 'scoutId',
        width: 140,
        isVisible: true,
    }

]
const years = [];
const currentYear = new Date().getFullYear();
years.push({label: currentYear -2, value: currentYear -2});
years.push({label: currentYear -1, value: currentYear -1});
years.push({label: currentYear, value: currentYear});
for (let i = 1; i <= 4; i++) {
    const year = currentYear + i;
    years.push({label: year, value: year});
}
const resourceTypeSuggestios = resourceTypes.map((res, index) => {
    return {
        label: res,
        value: index
    }
})
const exportToExcelConfig = {
    'Activity Name': excelTypes.Text,
    'Activity Type': excelTypes.Text,
    'Actual number of per diems Apr': excelTypes.DecimalNumber,
    'Actual number of per diems Aug': excelTypes.DecimalNumber,
    'Actual number of per diems Dec': excelTypes.DecimalNumber,
    'Actual number of per diems Feb': excelTypes.DecimalNumber,
    'Actual number of per diems Jan': excelTypes.DecimalNumber,
    'Actual number of per diems Jul': excelTypes.DecimalNumber,
    'Actual number of per diems Jun': excelTypes.DecimalNumber,
    'Actual number of per diems Mar': excelTypes.DecimalNumber,
    'Actual number of per diems May': excelTypes.DecimalNumber,
    'Actual number of per diems Nov': excelTypes.DecimalNumber,
    'Actual number of per diems Oct': excelTypes.DecimalNumber,
    'Actual number of per diems Sep': excelTypes.DecimalNumber,
    'Actuals Units Apr': excelTypes.DecimalNumber,
    'Actuals Units Aug': excelTypes.DecimalNumber,
    'Actuals Units Dec': excelTypes.DecimalNumber,
    'Actuals Units Feb': excelTypes.DecimalNumber,
    'Actuals Units Jan': excelTypes.DecimalNumber,
    'Actuals Units Jul': excelTypes.DecimalNumber,
    'Actuals Units Jun': excelTypes.DecimalNumber,
    'Actuals Units Mar': excelTypes.DecimalNumber,
    'Actuals Units May': excelTypes.DecimalNumber,
    'Actuals Units Nov': excelTypes.DecimalNumber,
    'Actuals Units Oct': excelTypes.DecimalNumber,
    'Actuals Units Sep': excelTypes.DecimalNumber,
    'Assignment Site': excelTypes.Text,
    'Cost Type': excelTypes.Text,
    'Created by': excelTypes.Text,
    'Created date': excelTypes.Date,
    'Currency': excelTypes.Text,
    'DC Approver': excelTypes.Text,
    'Expense Type': excelTypes.Text,
    'Last modification date': excelTypes.Date,
    'Last modified by': excelTypes.Text,
    'PO Apr': excelTypes.Text,
    'PO Aug': excelTypes.Text,
    'PO Dec': excelTypes.Text,
    'PO Feb': excelTypes.Text,
    'PO Jan': excelTypes.Text,
    'PO Jul': excelTypes.Text,
    'PO Jun': excelTypes.Text,
    'PO Location': excelTypes.Text,
    'PO Mar': excelTypes.Text,
    'PO May': excelTypes.Text,
    'PO Nov': excelTypes.Text,
    'PO Oct': excelTypes.Text,
    'PO Sep': excelTypes.Text,
    'PO WBS': excelTypes.Text,
    'Per Diem Rate': excelTypes.DecimalNumber,
    'Plan Apr': excelTypes.DecimalNumber,
    'Plan Aug': excelTypes.DecimalNumber,
    'Plan Dec': excelTypes.DecimalNumber,
    'Plan Feb': excelTypes.DecimalNumber,
    'Plan Jan': excelTypes.DecimalNumber,
    'Plan Jul': excelTypes.DecimalNumber,
    'Plan Jun': excelTypes.DecimalNumber,
    'Plan Mar': excelTypes.DecimalNumber,
    'Plan May': excelTypes.DecimalNumber,
    'Plan Nov': excelTypes.DecimalNumber,
    'Plan Oct': excelTypes.DecimalNumber,
    'Plan Sep': excelTypes.DecimalNumber,
    'Planner': excelTypes.Text,
    'Platform': excelTypes.Text,
    'Profile/Grade': excelTypes.Text,
    'RM ID': excelTypes.WholeNumber,
    'RM URL': excelTypes.Text,
    'Rate Year': excelTypes.WholeNumber,
    'Resource Location': excelTypes.Text,
    'Resource Name': excelTypes.Text,
    'Resource Type': excelTypes.Text,
    'Start Date': excelTypes.Date,
    'End Date': excelTypes.Date,
    'Sub-platform': excelTypes.Text,
    'Supplier Name': excelTypes.Text,
    'Supplier Representative': excelTypes.Text,
    'Unit Price': excelTypes.DecimalNumber,
    'Year': excelTypes.WholeNumber,
    'Scout ID': excelTypes.Text,
}

const mapStateToProps = (state) => {
    const columns = state.resourceModel.columns && state.resourceModel.columns.length !== 0 ? state.resourceModel.columns : defaultColumns;
    const domains = [...state.domains.domains];
    domains.push({name:"view all", id: "view all"});
    return {
        selectedItems: state.resourceModel.selectedResourceModels || {},
        selectedDomain: state.user.selectedDomain,
        domains: domains,
        columns: columns,
        tableState: state.table.parsedFilters,
        generatingDCs: state.resourceModel.generatingDCs,
        progress: state.resourceModel.dcGenerationProgress,
        loading: state.resourceModel.loading,
        resourceModels: state.resourceModel.resourceModels && state.resourceModel.resourceModels.items ? state.resourceModel.resourceModels.items : [],
        currentPage: state.resourceModel.resourceModels && state.resourceModel.resourceModels.currentPage ? state.resourceModel.resourceModels.currentPage : 1,
        resourceModelsCount: state.resourceModel.resourceModels && state.resourceModel.resourceModels.itemsCount ? state.resourceModel.resourceModels.itemsCount : 0,
        tabLoading: state.domains.loading
    }
};
  
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
            let params;
            if (payload.queryParameters) {
            params = payload.queryParameters
        } else {
            params = { }
        }
        dispatch(resourceModelActionCreators.getResourceModelColumnsStarted({domain: payload.domain.name}));
        dispatch(resourceModelActionCreators.getResourceModelsStarted({params: params, domain: payload.domain }));
        dispatch(domainsActions.getDomainsStarted());
      },
      updateColumns: (payload) => {
        dispatch(resourceModelActionCreators.updateResourceModelColumnsStarted(payload));
      },
      selectDomain: (payload) => {
        dispatch(userActionCreators.selectDomain(payload))
      },
      getColumns: (payload) => {
        dispatch(resourceModelActionCreators.getResourceModelColumnsStarted(payload))
      },
      openGenerateDCsModal: (payload) => {
          dispatch(resourceModelActionCreators.resourceModalGenerateDcModalOpened(payload))
      },
      getResourceModels: (payload) => {
          dispatch(resourceModelActionCreators.getResourceModelsStarted(payload))
      },
      selectedResourceModelRowsChanged: (payload) => {
          dispatch(resourceModelActionCreators.selectedResourceModelRowsChanged(payload))
      },
      resetView: (payload) => {
          dispatch(resourceModelActionCreators.resetResourceModelView())
      }
  });

class ResourceModelViewer extends Component {
    selectedItemsChanged = (e) => {
        const selectedRowsCopy = {...this.props.selectedItems}
        selectedRowsCopy[e.target.name] = e.target.checked;
        if (!e.target.checked) {
            delete selectedRowsCopy[e.target.name]
        }
        this.props.selectedResourceModelRowsChanged(selectedRowsCopy);
    }

    getTableName = (selectedDomain) => {
        return `rm${selectedDomain && selectedDomain.name !=="view all" ? `-${selectedDomain.id}` : ""}`
    }

    componentDidMount() {
        let selectedDomain = this.props.selectedDomain
        const tableState = this.props.tableState[this.getTableName(selectedDomain)];
        if (tableState) {
            const queryParameters = {...tableState};
            this.props.loadView({
                queryParameters: queryParameters,
                domain: selectedDomain
            });
        } else {
            this.props.loadView({
                domain: selectedDomain,
            });
        }
    }
    componentWillUnmount() {
        this.props.resetView();
    }

    handleEditClick(data, e) {
        if (e && e.button === 2) {
            return;
        }
        if (e && (e.ctrlKey || e.button === 1)) {
            window.open(window.location.origin + `/resourcemodel/${data.id}`, "_blank"); 
        } else {
        history.push(`/resourcemodel/${data.id}`);
        }
    }

    handleChangeDomain = (e, index) => {
        this.props.selectDomain(this.props.domains[parseInt(index)]);
        this.props.getColumns({domain: this.props.domains[parseInt(index)].name});
        const tableState = this.props.tableState[this.getTableName(this.props.domains[parseInt(index)])];
        if (tableState) {
            const domain = this.props.domains[parseInt(index)];
            const queryParameters = {...tableState};
            this.props.getResourceModels({params: queryParameters, domain});
        } else {
            this.props.getResourceModels({ params: {}, domain: this.props.domains[parseInt(index)]});
        }
    }

    onFetchData = (data, wait) => {
        const domain = this.props.selectedDomain;
        const queryParameters = data;
        this.props.getResourceModels({params: queryParameters, wait: wait, domain});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({domain: this.props.selectedDomain.name, columns: payload});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({domain: this.props.selectedDomain.name, columns: payload});
    }


    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleEditClick, label: 'Edit'}
                    ]
                }
                rowData={data}
            />
        )
    }
    render() {
        let selectedDomain = this.props.selectedDomain;
        let domains = this.props.domains;
        let cols = [...this.props.columns]
        if (selectedDomain && selectedDomain.name ==="view all") {
            const item = cols.find((col) => {
                return col.header === "Platform";
            });
            const index = cols.indexOf(item);
            if (index === -1) {
                cols.unshift({
                    header: "Platform",
                    dataProperty: 'domain',
                    width: 140,
                    isVisible: true,
                })
            }
        }
        let minWidth = 0;
        cols.forEach((column) => {
            if (column.isVisible) {
                minWidth += column.width;
            }
        });
        minWidth +=40;
        return (
            <React.Fragment>
                <Tabs onTabChanged={this.handleChangeDomain} selectedTab={selectedDomain} tabs={domains} loading={this.props.tabLoading}/>
                <GenerateDCsModal />
                <div style={{marginRight: 48, marginTop: 32} }>
                <div>
                <div style={{display: 'flex', marginBottom: 10}}>  
                    <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                        <Button
                            style={{marginRight: 'auto'}} 
                            onClick={this.props.openGenerateDCsModal.bind(this, false)} 
                            disabled={Object.keys(this.props.selectedItems).length === 0}
                            className="removed-text-padding blue-color">
                            <LaunchIcon />
                            GENERATE DCs for selected resource models
                        </Button>
                    </div> 
                    <div style={{display: 'flex', marginLeft: 20,  paddingRight: 8, marginTop: 10}}>
                        <Button
                            style={{marginRight: 'auto'}} 
                            onClick={this.props.openGenerateDCsModal.bind(this, true)} 
                            className="removed-text-padding blue-color">
                            <LaunchIcon />
                            GENERATE DCs for all resource models
                        </Button>
                    </div> 
                </div>  
                </div>
                {this.props.generatingDCs ?
                    <div>
                        <div style={{display: 'flex'}}>
                            <div style={{position: 'relative', width: 40, height: 0, marginRight: 5}}>
                                <CircularProgress size={24}/>
                            </div>
                            <span>DC generation status</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', flex: '1', height: 20, }}>
                            <LinearProgress variant="determinate" value={this.props.progress}/>
                        </div>
                    </div> 
                     : null
                }
                <DataExportContainer 
                    url={`api/resourceModel/export${selectedDomain && selectedDomain.name !=="view all" ? `/domain/${selectedDomain.id}` : ""}`}
                    tableName={this.getTableName(this.props.selectedDomain)}
                    fileName="ResourceModel"
                    fieldsTypes={exportToExcelConfig}
                />    
                <DataTableWithServerSizePagination
                    sortable
                    navigateToDetail={this.handleEditClick}
                    key={selectedDomain.name}
                    minWidth={minWidth}
                    customFilters={{
                        changelogStatus: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="changelogStatus"
                                    label="CL Status"
                                    small
                                    width="100%"
                                    minWidth={350}
                                    suggestions={statuses}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                        resourceType: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="resourceType"
                                    label="Resource Type"
                                    small
                                    width="100%"
                                    minWidth={150}
                                    suggestions={resourceTypeSuggestios}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                        year: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="year"
                                    label="Year"
                                    small
                                    width="100%"
                                    minWidth={100}
                                    suggestions={years}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                        dcyn: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="dcyn"
                                    label="DC Y/N"
                                    small
                                    width="100%"
                                    minWidth={80}
                                    suggestions={[{label: 'Yes', value: true},{label: 'No', value: false}]}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                        prepayment: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="prepayment"
                                    label="Prepayment"
                                    small
                                    width="100%"
                                    minWidth={80}
                                    suggestions={[{label: 'Yes', value: true},{label: 'No', value: false}]}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        }
                    }
                }
                    customColumnsWidthEnabled={true}
                    tableName={this.getTableName(this.props.selectedDomain)}
                    onSelectedChanged={this.selectedItemsChanged}
                    selected={this.props.selectedItems}
                    loading={this.props.loading}
                    count={this.props.resourceModelsCount}
                    general={{selectionEnabled: true, canSelectRowProperty: "canGenerateDC", tooltipProperty: "generationStatus"}}
                    columns={cols}
                    onFetchData={this.onFetchData}
                    data={this.props.resourceModels}   
                    actionMenu={this.createActionMenu}
                    onColumnsOrderChanged={this.onColumnOrderChanged}
                    onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                    currentPage={this.props.currentPage}
                />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceModelViewer);