import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import ResourceItem from '../components/ResourceItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import formSectionWrapper from '../components/FormSectionWrapper'
import * as rules from '../../../../config/Rules';
import {canFunction} from '../../../../config/Can';
import { canEditWithRequestor } from '../../../../utils/changelogFormEditPrivileges';
import { getExpenseTypesParsed } from '../../../../store/reducers/OneColumnPicklistReducer';
import { shouldComponentUpdate, getChangelogChanges } from '../../../../store/reducers/ChangeLogFormReducer';
import Dialog from '../../../../components/Dialog/Dialog';
import NumberFormat from 'react-number-format';

const objPlaceholder = {}

const mapStateToProps = (state) => {
    return {
        changelogResourceTagsSuggestions: state.changelogData.changelogResourceTags,
        vendorBaseName: state.changelogForm.vendorBaseName,
        isDraft: state.changelogForm.isDraft,
        year: state.changelogForm.year,
        resourcesLength: (state.changelogForm.resources || []).length,
        resources: state.changelogForm.resourcesThrottled || [],
        errors: state.changelogForm.errors,
        role: state.user.selectedRole,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        editMode: state.changelogForm.editMode,    
        expenseTypes: getExpenseTypesParsed(state),
        startDateModalOpen: state.changelogForm.startDateModalOpen,
        endDateModalOpen: state.changelogForm.endDateModalOpen,
        shouldUpdate: shouldComponentUpdate(state.changelogForm),
        changes: getChangelogChanges(state)
    }
};
const mapDispatchToProps = (dispatch) => ({
    resourceTagsChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogResourceTagsChanged(index, payload)),
    prepaymentChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormPrepaymentChanged(index, payload)),
    resourceAdd: () =>
        dispatch(changelogFormActionsCreators.changelogFormAddResourceItem()),    
    resourceDeleted: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemDeleted(payload)),
    resourceTypeChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemTypeChanged(index, payload)),    
    resourceStartDateChanged: (index, payload) => {
        const currentDate = payload ? payload.utc().format() : null; // Handle null case
        dispatch(changelogFormActionsCreators.changelogFormResourceItemStartDateChangedStarted(index, { target: { value: currentDate } }));
    
        if (!payload) {
            // If startDate is cleared, reset endDate to null
            dispatch(changelogFormActionsCreators.changelogFormResourceItemEndDateChangedStarted(index, { target: { value: null } }));
        }
    },    
    resourceEndDateChanged: (index, payload) => {
        const currentDate = payload.utc().format();
        const dateString = currentDate;
        dispatch(changelogFormActionsCreators.changelogFormResourceItemEndDateChangedStarted(index, {target:{value: dateString}})) 
    },
    expenseTypeChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemExpenseTypeChanged(index, payload)),
    resourceNameChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemNameChanged(index, payload)),
    resourceLocationChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemLocationChanged(index, payload)),
    resourceAssignmentSiteChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemAssignmentSiteChanged(index, payload)),
    resourceGradeChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemGradeChanged(index, payload)),
    resourceUnitPriceChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemUnitPriceChanged(index, payload)),
    resourcePerDiemChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemPerDiemRateChanged(index, payload)),
    resourceCurrencyChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemCurrencyChanged(index, payload)),    
    resourceMonthAmountChanged: (index, payload) =>
        dispatch(changelogFormActionsCreators.changelogFormResourceItemMonthAmountChanged(index, payload)),
    changelogFormDuplicateResourceItem: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormDuplicateResourceItem(payload)),
    closeModal: () => {
        dispatch(changelogFormActionsCreators.changelogFormResourceItemStartDateModalChanged(false));
        dispatch(changelogFormActionsCreators.changelogFormResourceItemEndDateModalChanged(false))
    }                
})

class ResourcesContainer extends Component {
    shouldComponentUpdate() {
        return this.props.shouldUpdate;
    }
    canEdit = () => {
        return canEditWithRequestor(this.props, rules.EDIT_CHANGELOG_ADD_NEW_RESOURCE);
    }
    constructor(props) {
        super(props);
        this.state = {
            totalPrices: {}
        };
    }
    handleTotalPriceCalculated = (totalPrice, currency) => {
        this.setState((prevState) => {
            const updatedTotalPrices = { ...prevState.totalPrices };
            updatedTotalPrices[currency] = (updatedTotalPrices[currency] || 0) + totalPrice;
            return { totalPrices: updatedTotalPrices };
        });
    };

    handleResourceRemoved = (totalPrice, currency) => {
        this.setState((prevState) => {
            const updatedTotalPrices = { ...prevState.totalPrices };
            updatedTotalPrices[currency] = (updatedTotalPrices[currency] || 0) - totalPrice;

            // Remove the currency entry if the total price is zero
            if (updatedTotalPrices[currency] <= 0) {
                delete updatedTotalPrices[currency];
            }

            return { totalPrices: updatedTotalPrices };
        });
    };
    render() {
        const {resources, errors, loadView, changes, ...other} = this.props;
        const { totalPrices } = this.state;
        return (
            <div>   
                <Dialog 
                    open={this.props.startDateModalOpen}
                    onClose={this.props.closeModal}
                    cancelButtonText="close"
                    title={"Start date cannot be changed"}
                >
                    <span>There is a plan in past months. In order to change the start date please remove the plan or move it to the future months.</span>
                </Dialog>
                <Dialog 
                    open={this.props.endDateModalOpen}
                    onClose={this.props.closeModal}
                    cancelButtonText="close"
                    title={"End date cannot be changed"}
                >
                    <span>There is a plan in future months. In order to change the future date please remove the plan or move it to the previous months.</span>
                </Dialog>
                {this.props.resources.map((resource, index) => {
                return (
                <ResourceItem
                        additionDisabled={this.props.resourcesLength >=20}
                        isDraft={this.props.isDraft}
                        role={this.props.role}
                        changes={changes.resources ? changes.resources.find((res) => res.id === resource.id) || objPlaceholder: objPlaceholder}
                        isChangelogOwner={this.props.isChangelogOwner}
                        editing={this.props.editing}
                        editMode={this.props.editMode}
                        errors={this.props.errors.resources && this.props.errors.resources[parseInt(index)] ? this.props.errors.resources[parseInt(index)] : null}
                        key={resource.key}
                        index={index}
                        resource={resource}
                        onTotalPriceCalculated={this.handleTotalPriceCalculated}
                        onResourceRemoved={this.handleResourceRemoved}
                        {...other}
                />
                )
                })}
                {this.canEdit() && this.props.resourcesLength < 20 ?
                <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                    <Button color="primary" 
                        style={{marginLeft: 'auto'}} 
                        onClick={this.props.resourceAdd} 
                        className="removed-text-padding">
                        <AddIcon />
                        ADD NEW RESOURCE
                    </Button>
                </div> : null 
                }
                <div>
                    <h3 style={{
                        color: '#000',
                        fontSize: 15,
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Total Price Breakdown by Currency</h3>
                    {Object.entries(totalPrices).map(([currency, total]) => (
                        <div key={currency} style={{ marginTop: '2px' }}>
                            <span style={{
                                color: '#595959',
                                fontSize: 16
                            }}>
                                In {currency}:
                            </span>
                            <span style={{
                                color: '#262626',
                                fontSize: 16,
                                paddingLeft: 12
                            }}>
                                <NumberFormat
                                    value={total}
                                    displayType={'text'}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowedDecimalSeparators={[".", ","]}
                                    isNumericString
                                />
                            </span>
                        </div>
                    ))}
                </div>
           </div>
        )
    }
}

export default formSectionWrapper(connect(mapStateToProps, mapDispatchToProps)(ResourcesContainer), "Resource");