import React from 'react';
import DataTable from '../../../../components/DataTable/DataTable2';

const columns = [
    {
        header: '#',
        dataProperty: 'num',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: 'Name',
        dataProperty: 'name',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: 'Type',
        dataProperty: 'type',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Jan",
        dataProperty: '1',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Feb",
        dataProperty: '2',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Mar",
        dataProperty: '3',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Apr",
        dataProperty: '4',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "May",
        dataProperty: '5',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Jun",
        dataProperty: '6',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Jul",
        dataProperty: '7',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Aug",
        dataProperty: '8',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Sep",
        dataProperty: '9',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Oct",
        dataProperty: '10',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Nov",
        dataProperty: '11',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
    {
        header: "Dec",
        dataProperty: '12',
        isVisible: true,
        width: 60,
        headerOnly: true
    },
]

const ResourceItemTable = (props) => {
    const data = [];
    if (props.resources) {
        props.resources.forEach((resource, index) => {
            if (!resource.resourceType) {
                return;
            }
            data.push({
                id: resource.key,
                num: index + 1,
                name: resource.name,
                type: resource.resourceType.label,
                ...resource.monthAmount
            })
        });
    }
    const createCustomRowCellsObject = function(monthStart, monthEnd) {
        const columnsCopyFromMonth = [...columns];
        const customCellRendererObject = columnsCopyFromMonth.reduce((obj, item) => {
            if (item.dataProperty === 'name') {
                obj[item.dataProperty] = (data, id, test) => {
                    return (
                        <span style={{color:"#39acff", cursor:'pointer'}} onClick={props.handleResourceNameClick.bind('', id)}>{data}</span>
                    )
                }
            }
            return obj;
        }, {});
        return customCellRendererObject;
    }


    return (
        <React.Fragment>
        { data.length !== 0 ?
        <DataTable 
            minWidth={880}
            maxHeight={200}
            customRowCells={createCustomRowCellsObject(props.monthStart, props.monthEnd)}
            customColumnsWidthEnabled={true}
            stickyHeader={true}
            general={{}}
            data={data}
            columns={columns}
            tableClass="changelog-popup"
        />
        : null
        }
        </React.Fragment>
    )
}
export default ResourceItemTable;

