import * as rules from './Rules';

export default {
    backOffice: {
        static: [
            rules.VIEW_SAP_ACTUALS,
            rules.EDIT_BUDGET_PLAN_ITEM_ALL_PLANS,
            rules.EDIT_BUDGET_PLAN_DOMAIN,
            rules.EDIT_BUDGET_PLAN_SUBDOMAIN,
            rules.EDIT_BUDGET_PLAN_COST_CENTER,
            rules.EDIT_BUDGET_PLAN_BUDGET_YEAR,
            rules.EDIT_BUDGET_PLAN_ITEM_COST_TYPE,
            rules.EDIT_BUDGET_PLAN_ITEM_EXPENSE_TYPE,
            rules.EDIT_BUDGET_PLAN_ITEM_CURRENCY,
            rules.EDIT_BUDGET_PLAN_DELETE_ITEM,
            rules.EDIT_BUDGET_PLAN_ADD_ITEM,
            rules.EDIT_BUDGET_PLAN_ITEM_COST_CENTER,
            rules.EDIT_BUDGET_PLAN_ITEM_ACTIVITY,
            rules.EDIT_BUDGET_PLAN_ITEM_WBS,
            rules.EDIT_BUDGET_PLAN_ITEM_PLANS,
            rules.EDIT_BUDGET_PLAN_ITEM_ACTUALS,
            rules.NAVIGATE_VIEW_ALL_BUDGET_PLANS,
            rules.NAVIGATE_VIEW_MY_BUDGET_PLANS,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY,
            rules.DC_reject_External,
            rules.DC_approve_External,
            rules.DC_processingNotApplicable_External,
            rules.DC_Comment_External,
            rules.DC_process_External,
            rules.DC_restart_External,
            rules.DC_back_External,
            rules.DC_edit_External,
            rules.DC_save_External,
            rules.DC_generatePdf_External,
            rules.NAVIGATE_CHANGELOG_VIEWALL,
            rules.NAVIGATE_RESOURCEMODEL,
            rules.LANDINGPAGE_VIEWDOMAINS,
            rules.EDIT_CHANGELOG_STATUS,
            rules.VIEW_EDIT_CHANGELOG_MENU,
            rules.EDIT_CHANGELOG_EXPENSE_TYPE,
            rules.EDIT_CHANGELOG_ACTIVITY_NAME,
            rules.EDIT_CHANGELOG_VENDOR_TYPE,
            rules.EDIT_CHANGELOG_VENDOR,
            rules.EDIT_CHANGELOG_PO_WBS,
            rules.EDIT_CHANGELOG_YEAR,
            rules.EDIT_CHANGELOG_RATE_YEAR,
            rules.EDIT_CHANGELOG_ADD_NEW_RESOURCE,
            rules.EDIT_CHANGELOG_LOCATION,
            rules.EDIT_CHANGELOG_ASSIGNMENT_SITE,
            rules.EDIT_CHANGELOG_GRADE,
            rules.EDIT_CHANGELOG_UNIT_PRICE,
            rules.EDIT_CHANGELOG_PER_DIEM,
            rules.EDIT_CHANGELOG_CURRENCY,
            rules.EDIT_CHANGELOG_DELETE_RESOURCE,
            rules.EDIT_CHANGELOG_RESOURCE_TYPE,
            rules.EDIT_CHANGELOG_RESOURCE_NAME,
            rules.EDIT_CHANGELOG_SUPPLIER_REPRESENTATIVE,
            rules.EDIT_CHANGELOG_DC_APPROVER,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,
            rules.EDIT_CHANGELOG_START_DATE,
            rules.EDIT_CHANGELOG_END_DATE,
            rules.EDIT_CHANGELOG_SCOUT_ID,
            rules.EDIT_CHANGELOG_DELETE_ATTACHMENT,
            rules.EDIT_CHANGELOG_ADD_ATTACHMENT,
            rules.EDIT_CHANGELOG_MONTH_VALUES,
            rules.NAVIGATE_DELIVERY_VIEW_ALL,
            rules.EDIT_DC_FORM_USERS,
            rules.EDIT_DC_INTERNAL_ACTUAL_PRICE,
            rules.EDIT_DC_COMMENTS,
            rules.EDIT_DC_ATTACHMENTS,
            rules.CHANGE_CHANGELOG_STATUS,
            rules.VIEW_CHANGELOG_COMMENTS,
            rules.VIEW_CHANGELOG_HISTORY,
            rules.EDIT_CHANGELOG_DOMAIN,
            rules.EDIT_CHANGELOG_SUB_DOMAIN,
            rules.EDIT_CHANGELOG_ACTIVITY_TYPE,
            rules.SUBMIT_EDIT_CHANGELOG,
            rules.EDIT_CHANGELOG_COMMENT,
            rules.NAVIGATE_DELIVERY_TO_BE_PROCESSED,
            rules.NAVIGATE_CHANGELOG_CREATE,
            rules.CAN_DUPLICATE_CHANGELOG_RESOURCE,
            rules.HAS_ENABLED_CHANGELOG_EDIT_FORM_TICKBOX_BY_DEFAULT,
            rules.VIEW_ACTIVITIES,
            rules.VIEW_ACTIVITY_FORM,
            rules.VIEW_WBSES,
            rules.VIEW_VENDOR_RATES,
            rules.VIEW_WBS_FORM,
            rules.VIEW_VENDOR_RATE_FORM,
            rules.VIEW_VENDOR_PICKLIST,
            rules.VIEW_GRADES_PICKLIST,
            rules.IS_BACKOFFICE_OR_ADMIN,
            rules.EDIT_CHANGELOG_RESOURCE_PREPAYMENT,
            rules.VIEW_TAGS_PICKLIST,
            rules.EDIT_FORM_TAGS,
            rules.VIEW_CHANGELOG_ACTIVITIES_CHANGELOGS
        ]
    },
    dcApprover: {
        static: [
            rules.DC_processingNotApplicable_External,
            rules.DC_Comment_External,
            rules.DC_reject_External,
            rules.DC_approve_External,
            rules.DC_submitAndApprove_External,
            rules.DC_generatePdf_External,
            rules.DC_back_External,
            rules.NAVIGATE_CHANGELOG_CREATE,
            // rules.NAVIGATE_CHANGELOG_VIEWALL,
            rules.NAVIGATE_CHANGELOG_VIEWMY,
            rules.NAVIGATE_DELIVERY_APPROVE,
            rules.SAVE_CHANGELOG_DRAFT,
            rules.NAVIGATE_DELIVERY_REVIEW,
            rules.VIEW_EDIT_CHANGELOG_MENU,
            rules.VIEW_CHANGELOG_HISTORY,
            rules.IS_REQUESTOR,
            rules.EDIT_CHANGELOG_RESOURCE_PREPAYMENT,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,
            rules.EDIT_CHANGELOG_SCOUT_ID
        ]
    },
    superRequestor: {
        static: [
            rules.DC_processingNotApplicable_External,
            rules.DC_Comment_External,
            rules.DC_reject_External,
            rules.DC_approve_External,
            rules.DC_submitAndApprove_External,
            rules.DC_generatePdf_External,
            rules.DC_back_External,
            rules.NAVIGATE_CHANGELOG_CREATE,
            rules.NAVIGATE_CHANGELOG_VIEWALL,
            rules.NAVIGATE_CHANGELOG_VIEWMY,
            rules.NAVIGATE_DELIVERY_APPROVE,
            rules.SAVE_CHANGELOG_DRAFT,
            rules.NAVIGATE_DELIVERY_REVIEW,
            rules.VIEW_EDIT_CHANGELOG_MENU,
            rules.VIEW_CHANGELOG_HISTORY,
            rules.IS_REQUESTOR,
            rules.IS_SUPER_REQUESTOR,
            rules.EDIT_CHANGELOG_RESOURCE_PREPAYMENT,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,
            rules.EDIT_CHANGELOG_SCOUT_ID
        ]
    },

    vendor: {
        static: [
            rules.DC_submitUpdated_External,
            rules.DC_Comment_External,
            rules.DC_submitForApproval_External,
            rules.DC_generatePdf_External,
            rules.DC_back_External,
            rules.NAVIGATE_DELIVERY_REVIEW,
            rules.NAVIGATE_DELIVERY_SUBMIT,
            rules.IS_SUPPLIER,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,
        ]
    },
    regionalRequestor: {
        static: [
            rules.NAVIGATE_VIEW_ALL_BUDGET_PLANS,
            rules.NAVIGATE_VIEW_MY_BUDGET_PLANS,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY,
            rules.EDIT_BUDGET_PLAN_ADD_ITEM,
            rules.EDIT_BUDGET_PLAN_ITEM_PLANS,
             rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,
            rules.EDIT_CHANGELOG_SCOUT_ID
        ]
    },

    admin: {
        static: [
            rules.VIEW_SAP_ACTUALS,
            rules.EDIT_BUDGET_PLAN_ITEM_ALL_PLANS,
            rules.EDIT_BUDGET_PLAN_DOMAIN,
            rules.EDIT_BUDGET_PLAN_SUBDOMAIN,
            rules.EDIT_BUDGET_PLAN_COST_CENTER,
            rules.EDIT_BUDGET_PLAN_BUDGET_YEAR,
            rules.EDIT_BUDGET_PLAN_ITEM_COST_TYPE,
            rules.EDIT_BUDGET_PLAN_ITEM_EXPENSE_TYPE,
            rules.EDIT_BUDGET_PLAN_ITEM_CURRENCY,
            rules.EDIT_BUDGET_PLAN_DELETE_ITEM,
            rules.EDIT_BUDGET_PLAN_ADD_ITEM,
            rules.EDIT_BUDGET_PLAN_ITEM_COST_CENTER,
            rules.EDIT_BUDGET_PLAN_ITEM_ACTIVITY,
            rules.EDIT_BUDGET_PLAN_ITEM_WBS,
            rules.EDIT_BUDGET_PLAN_ITEM_PLANS,
            rules.EDIT_BUDGET_PLAN_ITEM_ACTUALS,
            rules.NAVIGATE_VIEW_ALL_BUDGET_PLANS,
            rules.NAVIGATE_VIEW_MY_BUDGET_PLANS,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC,
            rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY,
            rules.LANDINGPAGE_VIEWDOMAINS,
            rules.DC_processingNotApplicable_External,
            rules.DC_reject_External,
            rules.DC_approve_External,
            rules.DC_process_External,
            rules.DC_restart_External,
            rules.DC_Comment_External,
            rules.DC_generatePdf_External,
            rules.DC_back_External,
            rules.DC_edit_External,
            rules.DC_save_External,
            rules.NAVIGATE_CHANGELOG_CREATE,
            rules.NAVIGATE_CHANGELOG_VIEWALL,
            rules.NAVIGATE_RESOURCEMODEL,
            rules.NAVIGATE_EDITPICKLIST,
            rules.VIEW_EXPENSE_TYPE_PICKLIST,
            rules.VIEW_COST_CENTER_PICKLIST,
            rules.VIEW_AREAS_PICKLIST,
            rules.VIEW_CURRENCIES_PICKLIST,
            rules.VIEW_DOMAINS_PICKLIST,
            rules.VIEW_GRADES_PICKLIST,
            rules.VIEW_RESOURCE_LOCATIONS_PICKLIST,
            rules.EDIT_CHANGELOG_STATUS,
            rules.VIEW_EDIT_CHANGELOG_MENU,
            rules.EDIT_CHANGELOG_EXPENSE_TYPE,
            rules.EDIT_CHANGELOG_ACTIVITY_NAME,
            rules.EDIT_CHANGELOG_VENDOR_TYPE,
            rules.EDIT_CHANGELOG_VENDOR,
            rules.EDIT_CHANGELOG_PO_WBS,
            rules.EDIT_CHANGELOG_YEAR,
            rules.EDIT_CHANGELOG_RATE_YEAR,
            rules.EDIT_CHANGELOG_ADD_NEW_RESOURCE,
            rules.EDIT_CHANGELOG_LOCATION,
            rules.EDIT_CHANGELOG_ASSIGNMENT_SITE,
            rules.EDIT_CHANGELOG_GRADE,
            rules.EDIT_CHANGELOG_UNIT_PRICE,
            rules.EDIT_CHANGELOG_PER_DIEM,
            rules.EDIT_CHANGELOG_CURRENCY,
            rules.EDIT_CHANGELOG_DELETE_RESOURCE,
            rules.EDIT_CHANGELOG_RESOURCE_TYPE,
            rules.EDIT_CHANGELOG_RESOURCE_NAME,
            rules.EDIT_CHANGELOG_SUPPLIER_REPRESENTATIVE,
            rules.EDIT_CHANGELOG_DC_APPROVER,
            rules.EDIT_CHANGELOG_START_DATE,
            rules.EDIT_CHANGELOG_END_DATE,
            rules.EDIT_CHANGELOG_SCOUT_ID,
            rules.EDIT_CHANGELOG_DELETE_ATTACHMENT,
            rules.EDIT_CHANGELOG_ADD_ATTACHMENT,
            rules.EDIT_CHANGELOG_MONTH_VALUES,
            rules.VIEW_RESOURCE_TYPE_PICKLIST,
            rules.VIEW_VENDOR_PICKLIST,
            rules.CAN_DUPLICATE_CHANGELOG_RESOURCE,
            rules.HAS_ENABLED_CHANGELOG_EDIT_FORM_TICKBOX_BY_DEFAULT,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM,

            rules.VIEW_ACTIVITIES,
            rules.VIEW_ACTIVITY_FORM,
            rules.VIEW_WBSES,
            rules.VIEW_VENDOR_RATES,
            rules.VIEW_WBS_FORM,
            rules.VIEW_VENDOR_RATE_FORM,
            rules.VIEW_IMPORT_MASTERDATA,

            rules.NAVIGATE_DELIVERY_VIEW_ALL,
            rules.EDIT_DC_FORM_USERS,
            rules.EDIT_DC_INTERNAL_ACTUAL_PRICE,
            rules.EDIT_DC_COMMENTS,
            rules.EDIT_DC_ATTACHMENTS,
            rules.CHANGE_CHANGELOG_STATUS,
            rules.VIEW_CHANGELOG_COMMENTS,
            rules.VIEW_CHANGELOG_HISTORY,
            rules.VIEW_DOMAIN_PICKLIST,
            rules.VIEW_SUB_DOMAIN_PICKLIST,
            rules.VIEW_PO_LOCATION_PICKLIST,
            rules.VIEW_COST_TYPE_PICKLIST,
            rules.EDIT_CHANGELOG_DOMAIN,
            rules.EDIT_CHANGELOG_SUB_DOMAIN,
            rules.EDIT_CHANGELOG_ACTIVITY_TYPE,
            rules.SUBMIT_EDIT_CHANGELOG,
            rules.EDIT_CHANGELOG_COMMENT,
            
            rules.EXPORT_DATA,
            rules.NAVIGATE_DELIVERY_TO_BE_PROCESSED,
            rules.IS_BACKOFFICE_OR_ADMIN,
            rules.EDIT_CHANGELOG_RESOURCE_PREPAYMENT,
            rules.VIEW_TAGS_PICKLIST,
            rules.EDIT_FORM_TAGS,
            rules.VIEW_CHANGELOG_ACTIVITIES_CHANGELOGS,
            rules.EDIT_CHANGELOG_LEANIX,
            rules.EDIT_CHANGELOG_EPPM
        ]
    }
}