import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextWithIcon from '../../../../components/Input/TextWithIcon';
import Input from '../../../../components/Input/Input';
import DataTable from '../../../../components/DataTable/DataTable2';
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import activityTypes from '../../../../constants/activityTypes';
import vendorTypes from '../../../../constants/vendorTypes'; 
import AttachmentRevision from '../components/AttachmentRevision';
import TextArea from '../../../../components/Input/TextArea';
import AutoSelect from '../../../../components/Input/AutoSelect';
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme =>({
    sectionWrapper: {
        '& > *': {
            paddingTop: 8,
            paddingBottom: 8,
        }
    },
    header: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '28px',
        color: '#262626'
    },
    twoColumnWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap:'wrap'
    },
    wrapper: {
        border: '1px solid #c8cace',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    wrapperBlue: {
        border: '1px solid #c8cace',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: -15,
        '& > *': {
            flexBasis: 350,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    secondRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -15,
        '& > *': {
            flexBasis: 128,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    totalPriceWrapper: {
        paddingTop: 5
    },
    totalPriceLabel: {
        color: '#595959',
        fontSize: 16
    },
    totalPriceValue: {
        color: '#262626',
        fontSize: 16,
        paddingLeft: 12
    },
    commentsListContainer: {
        paddingTop: 18
    },
    commentContainer: {

    },
    commentHeader: {
        color: '#595959',
        fontSize: 12,
        fontWeight: '500'
    },
    commentContent: {
        color: '#262626',
        lineHeight: '20px',
        fontSize: 16,
        paddingTop: 10
    }
}))
let defaultValue = "Default";

const ChangelogRevisionComparator = ({index, history}) => {
    const classes = useStyles();
    let currentRevision = history[index];
    if (currentRevision.resources) {
        currentRevision = {...currentRevision};
        currentRevision.resources = [...currentRevision.resources].sort((a,b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (b.id > a.id) {
                return -1;
            }
            return 0;
        });
    }
    let previousRevision = history[index +1];
    let sortedResourcesOnPreviousArray = [];
    //create new resource array here. Resources are matched by id, if id cannot be found in previous revision
    //it means that this is a new resource and undefined is pushed to array to keep resources order
    if (previousRevision) {
        previousRevision = {...previousRevision};
        previousRevision.rateYear = previousRevision.resources[0] ? previousRevision.resources[0].vendorRate.rateYear : null;
        let comparisonProperty;
        if (previousRevision.resources[0] && previousRevision.resources[0].id !== undefined) {
            comparisonProperty = "id";
            currentRevision.resources.forEach((resource) => {
                const oldRes = previousRevision.resources.find((old) => {
                    return old[comparisonProperty] === resource[comparisonProperty];
                });
                sortedResourcesOnPreviousArray.push(oldRes);
            });
        } else {
            sortedResourcesOnPreviousArray = [...currentRevision.resources]
        }

        previousRevision.resources = sortedResourcesOnPreviousArray;
    }


    let changes =  getRevisionDifferences(currentRevision, previousRevision);
    return (
        <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                    <div style={{position: 'relative', paddingTop: 20}}>
                        <TextWithIcon label="Last modified by" value={currentRevision.modifiedBy || defaultValue} icon={AssignmentIndOutlined}/>
                    </div>
                    <div className={classes.sectionWrapper}>
                        <Input 
                            label="Status"
                            value={currentRevision.status || defaultValue} 
                            className={changes.status ? "input-updated" : ""}
                            tooltipText={changes.status}
                            disabled
                        />
                    </div>
                    </Grid>
                    {canFunction('', rules.EDIT_FORM_TAGS) ? 
                        <Grid item xs={12}>
                            <AutoSelect 
                                label="Tags" 
                                isMulti
                                width="100%" 
                                disabled={true}
                                value={currentRevision.tags || ""} 
                                className={changes.tags ? "input-updated": ""}
                                tooltipText={changes.tags}
                            />
                        </Grid> : null
                    }
                    <Grid item xs={12}>
                    </Grid>

                    <Grid item md={6} xs={12} id="delivery-confirmation-row">
                        <div className={classes.sectionWrapper}>
                            <h3 className={classes.header}>Delivery Information</h3>
                    <React.Fragment>
                    <Input 
                        label="Platform"
                        value={currentRevision.domainName|| defaultValue}
                        disabled
                        className={changes.domainName ? "input-updated" : ""}
                        tooltipText={changes.domainName}
                    />
                    <Input 
                        label="Sub-platform"
                        value={currentRevision.subDomainName|| defaultValue}
                        disabled
                        className={changes.subDomainName ? "input-updated" : ""}
                        tooltipText={changes.subDomainName}
                    />
                    <Input
                        label="Activity Type"
                        tooltipText={changes.activityType}
                        className={changes.activityType !== undefined ? "input-updated" : ""}
                        value={activityTypes[currentRevision.activityType] || defaultValue}
                        disabled={true}
                    />
                    <Input 
                        label="Activity name"
                        value={currentRevision.activityName || defaultValue}
                        className={changes.activityName ? "input-updated" : ""}
                        tooltipText={changes.activityName}
                        disabled
                    />
                    <Input
                        label="Vendor Type"
                        tooltipText={changes.vendorType}
                        className={changes.vendorType !== undefined ? "input-updated" : ""}
                        value={vendorTypes[currentRevision.vendorType] || defaultValue}
                        disabled={true}
                    />
                    <Input 
                        label="Supplier/Team"
                        value={currentRevision.vendor || defaultValue}
                        className={changes.vendor ? "input-updated" : ""}
                        tooltipText={changes.vendor}
                        disabled
                    />
                    <Input 
                        label="PO Location"
                        value={currentRevision.poLocationName || defaultValue}
                        tooltipText={changes.poLocationName}
                        className={changes.poLocationName ? "input-updated" : ""}
                        disabled
                    />
                    <Input 
                        label="Cost Type"
                        value={currentRevision.costTypeName || defaultValue}
                        tooltipText={changes.costTypeName}
                        className={changes.costTypeName ? "input-updated" : ""}
                        disabled
                    />
                    <Input 
                        label="PO WBS"
                        value={currentRevision.wbsIdentifier || defaultValue}
                        className={changes.wbsIdentifier ? "input-updated" : ""}
                        tooltipText={changes.wbsIdentifier}
                        disabled
                    />
                    <div className={classes.twoColumnWrapper}>
                        <Input 
                            label="Year"
                            value={currentRevision.year || defaultValue}
                            className={changes.year ? "input-updated" : ""}
                            tooltipText={changes.year}
                            disabled
                            width="45%"
                        />
                        <Input 
                            label="Rate Year"
                            value={currentRevision.resources && currentRevision.resources[0] ? currentRevision.resources[0].vendorRate.rateYear : defaultValue}
                            className={changes.rateYear ? "input-updated" : ""}
                            tooltipText={changes.rateYear}
                            disabled
                            width="45%"
                        />
                    </div>
                    </React.Fragment>
                    </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                    <div className={classes.sectionWrapper}>
                        <h3 className={classes.header}>Roles</h3> 
                        <Input 
                            label="Planner"  
                            width="100%" 
                            disabled 
                            value={currentRevision.planner || defaultValue} 
                            tooltipText={changes.planner} 
                            className={changes.planner ? "input-updated" : ""} 
                        />
                        <Input 
                            label="Delivery Confirmation Approver"  
                            width="100%" 
                            disabled 
                            value={currentRevision.approver.name}
                            tooltipText={changes.approver && changes.approver.name ? changes.approver.name :  ""} 
                            className={changes.approver && changes.approver.name ? "input-updated" : ""} 
                        />
                        {currentRevision.supplierRepresentative ?
                            <Input 
                                label="Supplier Representative" 
                                width="100%" 
                                disabled 
                                value={currentRevision.supplierRepresentative ? currentRevision.supplierRepresentative.name : defaultValue} 
                                className={changes.supplierRepresentative && changes.supplierRepresentative.name ? "input-updated" : ""} 
                                tooltipText={changes.supplierRepresentative && changes.supplierRepresentative.name ? changes.supplierRepresentative.name : ""} 
                            />
                            : null
                        }
                            <Input 
                                label="Scout ID" 
                                width="100%" 
                                disabled 
                                value={currentRevision.scoutId || ""} 
                                className={changes.scoutId !== undefined && changes.scoutId !== currentRevision.scoutId ? "input-updated" : ""} 
                                tooltipText={changes.scoutId || ""} 
                            />
                    </div>
                    </Grid> 
                    
                    <Grid item xs={12}>
                    <div className={classes.sectionWrapper}>
                            <h3 className={classes.header}>Resources</h3>
                    {currentRevision.resources.map((resource, index) => {
                        const data = {id:1};
                        const resourceChanges = changes.resources[index];
                        resource.values.forEach((value) => {
                            data[value.month] = value.value;
                        });
                        const getTotalPrice = () => {
                            let totalMonthDays = 0;
                                Object.keys(data).forEach(key => {
                                    if (Number.isNaN(data[key]) || key === 'id') {
                                        return;
                                    }
                                    totalMonthDays += (parseFloat(data[key])* parseFloat(resource.vendorRate.unitPrice));
                                    if (resource.vendorRate.resourceType  && resource.vendorRate.resourceType === 'T&M') {
                                        if (resource.vendorRateassignmentSite === "Landed" && resource.vendorRate.perDiemRate) {
                                            totalMonthDays += (parseFloat(data[key]) * parseFloat(resource.vendorRate.perDiemRate));
                                        }
                                    }
                                })
                            return  <NumberFormat 
                                value={totalMonthDays}
                                displayType={'text'}
                                thousandSeparator 
                                decimalScale={2}
                                fixedDecimalScale={2}
                                allowedDecimalSeparators={[".",","]}
                                isNumericString
                                />;
                        }
                        const getTotalAmountOfManDays = () => {
                            let totalMonthDays = 0;
                            Object.keys(data).forEach(key => {
                                if (Number.isNaN(data[key]) || key === 'id') {
                                    return;
                                }
                                totalMonthDays += parseFloat(data[key]);
                            })
                        return parseFloat(totalMonthDays.toFixed(2));
                        }
                        const createCustomRowCellsObject = function(monthStart) {
                            const columnsCopyFromMonth = [...columns];
                            const customCellRendererObject = columnsCopyFromMonth.reduce((obj, item) => {
                                if (parseInt(item.dataProperty) >= monthStart) {
                                    obj[item.dataProperty] = (data, id, test) => {
                                        const previousMonthRecord = resourceChanges && resourceChanges.values && resourceChanges.values.find((value) => {
                                            return parseInt(value.month) === parseInt(item.dataProperty) 
                                        });
                                        let input = (
                                            <Input
                                                width="62px"
                                                minWidth="62px"
                                                className={previousMonthRecord ? "input-updated-table" : ""}
                                                tooltipText={previousMonthRecord ? previousMonthRecord.value + " " : ""}
                                                type="number"
                                                disabled={true}
                                                inputProps={{name:item.dataProperty, step:0.01, min:0 }}
                                                value={data ? data : ""}
                                            />
                                        )
                                        return input
                                    }
                                } else {
                                    obj[item.dataProperty] = (data, id) => {
                                        return (
                                            <div style={{minWidth: monthStart === 13 && parseInt(item.dataProperty) === 12 ? 66 : 62}}></div>
                                        )
                                    }
                                }
                                return obj;
                            }, {});
                            return customCellRendererObject;
                        }                       
                        const columns = [
                            {
                                header: "Jan",
                                dataProperty: '1',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Feb",
                                dataProperty: '2',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Mar",
                                dataProperty: '3',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Apr",
                                dataProperty: '4',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "May",
                                dataProperty: '5',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Jun",
                                dataProperty: '6',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Jul",
                                dataProperty: '7',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Aug",
                                dataProperty: '8',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Sep",
                                dataProperty: '9',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Oct",
                                dataProperty: '10',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Nov",
                                dataProperty: '11',
                                isVisible: true,
                                headerOnly: true
                            },
                            {
                                header: "Dec",
                                dataProperty: '12',
                                isVisible: true,
                                headerOnly: true
                            },
                        ]
                            let secondRow = null;
                            const location =(<Input width={128} className={changes.resources[index] && changes.resources[index].vendorRate.resourceLocation !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.resourceLocation} label="Location" value={resource.vendorRate.resourceLocation} disabled/>);
                            const assignmentSite = (<Input width={128} className={changes.resources[index] &&changes.resources[index].vendorRate.assignmentSite !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.assignmentSite} label="Assignment Site" value={resource.vendorRate.assignmentSite} disabled />);
                            const profileGrade = (<Input width={128} className={changes.resources[index] && changes.resources[index].vendorRate.grade !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.grade} label="Profile/Grade" value={resource.vendorRate.grade} disabled/>);
                            const unitPrice = (<Input width={128} className={changes.resources[index] && changes.resources[index].vendorRate.unitPrice !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.unitPrice} label="Unit Price" value={resource.vendorRate.unitPrice} disabled />);
                            const perDiem =  resource.vendorRate.assignmentSite === "Landed" ? (<Input width={128} className={changes.resources[index] && changes.resources[index].vendorRate.perDiemRate !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.perDiemRate} label="Per Diem" value={resource.vendorRate.perDiem} disabled />) : null;
                            const unitPriceDisabled = (<Input width={128} label="Unit Price" className={changes.resources[index] && changes.resources[index].vendorRate.unitPrice !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.unitPrice} disabled={true} value={resource.vendorRate.unitPrice}/>)
                            const currency = (<Input width={128} label="Currency" className={changes.resources[index] && changes.resources[index].vendorRate.currency !== undefined ? "input-updated" : ""} tooltipText={changes.resources[index] && changes.resources[index].vendorRate.currency} value={resource.vendorRate.currency} disabled />);
                            switch (resource.vendorRate.resourceType) {
                                case 'T&E': {
                                    secondRow = (
                                        <div className={classes.secondRow}>
                                            {unitPriceDisabled}
                                            {currency}
                                        </div>
                                    )
                                    break;
                                }
                                case 'T&M': {
                                    secondRow = (
                                        <div className={classes.secondRow}>
                                            {location}
                                            {assignmentSite}
                                            {profileGrade}
                                            {unitPrice}
                                            {perDiem}
                                            {currency}
                                        </div>
                                    )
                                    break;
                                }
                                case 'FXP': {
                                    secondRow = (
                                        <div className={classes.secondRow}>
                                            {unitPriceDisabled}
                                            {currency}
                                        </div>
                                    )
                                    break;
                                }
                                case 'FMS': {
                                    secondRow = (
                                        <div className={classes.secondRow}>
                                            {unitPriceDisabled}
                                            {currency}
                                        </div>
                                    )
                                    break;
                                }
                                case 'Business':
                                case 'IT': {
                                    secondRow = (
                                        <div className={classes.secondRow}>
                                            {location}
                                            {profileGrade}
                                            {unitPrice}
                                            {currency}
                                        </div>
                                    )
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        return (
                        <div className={previousRevision && previousRevision.resources[index] === undefined  ? classes.wrapperBlue: classes.wrapper} key={index} style={resource.prepayment ? {borderColor: '#39acff'} : {}}>
                            <div style={{
                                            position:'relative',
                                            top:-14,
                                            left:-6,
                                            background: 'transparent',
                                            textAlign: 'center',
                                            borderRadius: '50%',
                                            color: "#a9a9a9",
                                            width: '1.1em',
                                            height: '1.1em',
                                            padding: '0.25em',
                                            boxSizing: 'content-box',
                                            lineHeight: '16px',
                                            border: '0.1em solid #a9a9a9',
                                            fontSize: '16px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                            }}
                                        >
                                            {parseInt(index) + 1}
                            </div>
                                {resource.prepayment &&
                                    <div style={{
                                        position:'absolute',
                                        width:180,
                                        height: 25,
                                        borderBottomLeftRadius: '16px',
                                        borderBottomRightRadius: '16px',
                                        top:-1,
                                        right:'calc(50% - 90px)',
                                        color: '#fff',
                                        border:'1px solid #39acff',
                                        background: '#39acff',
                                        textAlign: 'center',
                                        fontWeight: '600'
                                        }}
                                    >
                                        Prepayment Required
                                    </div>}
                                    {canFunction('', rules.EDIT_FORM_TAGS) ? 
                                    <div className={classes.row}>
                                        <AutoSelect 
                                            label="Tags" 
                                            isMulti
                                            width="100%" 
                                            disabled={true}
                                            value={resource.tags || ""} 
                                            tooltipText={changes.resources[index] && changes.resources[index].tags} 
                                            className={changes.resources[index] && changes.resources[index].tags ? "input-updated" : ""} 
                                        />
                                    </div> : null
                                    }

                        <div className={classes.row}>
                            <Input 
                                width={128} 
                                label="Resource Type" 
                                value={resource.vendorRate.resourceType} 
                                disabled
                                tooltipText={changes.resources[index] && changes.resources[index].vendorRate.resourceType} 
                                className={changes.resources[index] && changes.resources[index].vendorRate.resourceType !== undefined ? "input-updated" : ""} 
                            />
                            <Input 
                                width={128} 
                                label="Start Date" 
                                value={formatDate(resource.startDate)} 
                                disabled 
                                tooltipText={changes.resources[index] && changes.resources[index].startDate && formatDate(changes.resources[index].startDate)} 
                                className={changes.resources[index] && changes.resources[index].startDate !== undefined ? "input-updated" : ""} 
                            />
                            <Input 
                                label="Name" 
                                value={resource.name}
                                tooltipText={changes.resources[index] && changes.resources[index].name} 
                                className={changes.resources[index] && changes.resources[index].name !== undefined ? "input-updated" : ""} 
                            />
                            <Input 
                                width={128} 
                                label="End Date" 
                                value={resource.endDate ? formatDate(resource.endDate) : ""} 
                                disabled 
                                tooltipText={changes.resources[index]?.endDate ? formatDate(changes.resources[index].endDate) : undefined} 
                                className={changes.resources[index]?.endDate !== undefined ? "input-updated" : ""} 
                            />
                            <Input 
                                label="Expense type"
                                value={resource.expenseTypeName || defaultValue}
                                tooltipText={changes.resources[index] && changes.resources[index].expenseTypeName}
                                className={changes.resources[index] && changes.resources[index].expenseTypeName !== undefined ? "input-updated" : ""} 
                                disabled
                            />
                        </div>
                            {secondRow}
                        <div>
                        <DataTable 
                            minWidth={840}
                            data={[data]}
                            customRowCells={createCustomRowCellsObject(resource.startDate ? new Date(resource.startDate).getMonth() + 1 : 13)}
                            columns={columns}
                            tableClass="month-table"
                        />
                        </div>
                        <div className={classes.checkboxWrapper}>
                        <Input 
                                width={128} 
                                label="Prepayment" 
                                value={resource.prepayment ? "Yes" : "No"} 
                                disabled
                                tooltipText={changes.resources[index] && changes.resources[index].prepayment !== undefined  ? changes.resources[index].prepayment : ""} 
                                className={changes.resources[index] && changes.resources[index].prepayment !== undefined ? "input-updated" : ""} 
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            {resource.vendorRate && resource.vendorRate.currency &&
                            <div className={classes.totalPriceWrapper}>
                                <span className={classes.totalPriceLabel}>
                                    Total price
                                </span>
                                <span className={classes.totalPriceValue}>
                                {getTotalPrice()} { resource.vendorRate.currency}
                                </span>
                            </div>
                            }
                        </div>
                        <div>
                        {
                        resource.vendorRate.resourceType === 'T&M' &&
                            <div className={classes.totalPriceWrapper}>
                                <span className={classes.totalPriceLabel}>
                                    Total number of man-days: 
                                </span>
                                <span className={classes.totalPriceValue}>
                                {getTotalAmountOfManDays()} 
                                </span>
                            </div>
                        }
                        </div>
                    </div>
                    )
                })
            }
            </div>
            </Grid>
            <Grid item xs={12}>
            <div className={classes.sectionWrapper}>
                <h3 className={classes.header}>Supporting Documentation</h3>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '* >': {
                        paddingLeft: 8,
                        paddingRight: 8
                    }
                }}>
                    <AttachmentRevision attachments={changes.attachments} />
                </div>
            </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.sectionWrapper}>
                    <h3 className={classes.header}>Comment</h3>
                    <div className={classes.commentsListContainer}>
                        <TextArea disabled  value={currentRevision.comment ? currentRevision.comment.message : ""}/>
                    </div>
                </div>
            </Grid>

                </Grid>
    )
}

function getRevisionDifferences(currentRevision, previousRevision) {
    if (!previousRevision) {
        const attachmentsParsed = currentRevision.attachments.map((att) => {
            return {
                name: att,
                type: 'unchanged'
            }
        })
        return {resources: [], attachments: attachmentsParsed}
    }
    const differences = {};

    differences.tags = ""
    let tagsChanged = false;
    if (currentRevision.tags) {
        currentRevision.tags.forEach((tag) => {
            if (!previousRevision.tags || !previousRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        })
    }
    if (previousRevision.tags) {
        previousRevision.tags.forEach((tag) => {
            if (!currentRevision.tags || !currentRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        }) 
    }
    if (tagsChanged) {
        differences.tags = previousRevision.tags ? previousRevision.tags.map(tag => tag.label).join(', ') : "Default"
    }


     if (currentRevision.domainName !== previousRevision.domainName) {
         differences.domainName = previousRevision.domainName || defaultValue;
     }
     if (currentRevision.planner !== previousRevision.planner) {
         differences.planner = previousRevision.planner || defaultValue;
     }
     if (currentRevision.subDomainName !== previousRevision.subDomainName) {
         differences.subDomainName = previousRevision.subDomainName || defaultValue;
     }
     if (currentRevision.costTypeName !== previousRevision.costTypeName) {
         differences.costTypeName = previousRevision.costTypeName || defaultValue;
     }
     if (currentRevision.poLocationName !== previousRevision.poLocationName) {
         differences.poLocationName = previousRevision.poLocationName || defaultValue;
     }
     if (currentRevision.activityType !== previousRevision.activityType) {
         differences.activityType = activityTypes[previousRevision.activityType];
     }
     if (currentRevision.vendorType !== previousRevision.vendorType) {
         differences.vendorType = vendorTypes[previousRevision.vendorType];
     }
     if (currentRevision.poLocationName !== previousRevision.poLocationName) {
         differences.poLocationName = previousRevision.poLocationName || defaultValue;
     }
     if (currentRevision.activityName !== previousRevision.activityName) {
         differences.activityName = previousRevision.activityName || defaultValue;
     }
     if (currentRevision.vendor !== previousRevision.vendor) {
         differences.vendor = previousRevision.vendor || defaultValue;
     }
     if (currentRevision.wbsIdentifier !== previousRevision.wbsIdentifier) {
         differences.wbsIdentifier = previousRevision.wbsIdentifier || defaultValue;
     }
     if (currentRevision.year !== previousRevision.year) {
         differences.year = previousRevision.year || defaultValue;
     }
     if (currentRevision.scoutId !== previousRevision.scoutId) {
        differences.scoutId = previousRevision.scoutId || "";
    }
     currentRevision = {...currentRevision};
     currentRevision.resources = currentRevision.resources || [];
     previousRevision = {...previousRevision};
     previousRevision.resources = previousRevision.resources || [];
     if ((!currentRevision.resources[0] && previousRevision.rateYear) ||
         (currentRevision.resources[0] && !previousRevision.rateYear) ||
         (currentRevision.resources[0] && previousRevision.rateYear && (currentRevision.resources[0].vendorRate.rateYear !== previousRevision.rateYear))) {
             differences.rateYear = previousRevision.rateYear || defaultValue;
     }
     if ((!currentRevision.approver && previousRevision.approver) ||
         (currentRevision.approver && !previousRevision.approver) ||
         (currentRevision.approver && previousRevision.approver && (currentRevision.approver.name !== previousRevision.approver.name))) {
             differences.approver = previousRevision.approver;
     }
     if ((!currentRevision.supplierRepresentative && previousRevision.supplierRepresentative) ||
         (currentRevision.supplierRepresentative && !previousRevision.supplierRepresentative) ||
         (currentRevision.supplierRepresentative && previousRevision.supplierRepresentative && (currentRevision.supplierRepresentative.name !== previousRevision.supplierRepresentative.name))) {
             differences.supplierRepresentative = previousRevision.supplierRepresentative;
     }
     if (currentRevision.status !== previousRevision.status) {
         differences.status = previousRevision.status || defaultValue;
     }
     differences.resources = [];
     currentRevision.resources.forEach((resource, index) => {
         const resourceDiff = {vendorRate: {}};
         differences.resources.push(resourceDiff);
         if (!previousRevision.resources || !previousRevision.resources[index]) {
             return;
         }
         const previousRevisionResource = previousRevision.resources[index];
         if (previousRevisionResource.prepayment !== undefined && resource.prepayment !== previousRevisionResource.prepayment) {
             resourceDiff.prepayment = previousRevisionResource.prepayment ? "Yes" : "No";
         }
         if (resource.name !== previousRevisionResource.name) {
             resourceDiff.name = previousRevisionResource.name;
         }
         if (formatDate(resource.startDate) !== formatDate(previousRevisionResource.startDate)) {
             resourceDiff.startDate = previousRevisionResource.startDate|| defaultValue;
         }
         if (
            (resource.endDate || previousRevisionResource.endDate) && // Ensure at least one value exists
            formatDate(resource.endDate || "") !== formatDate(previousRevisionResource.endDate || "")
        ) {
            resourceDiff.endDate = previousRevisionResource.endDate ?? ""; // Assign empty string if endDate is missing
        }
        
         if (resource.expenseTypeName !== previousRevisionResource.expenseTypeName) {
             resourceDiff.expenseTypeName = previousRevisionResource.expenseTypeName || defaultValue;
         }

         if (resource.vendorRate.assignmentSite !== previousRevisionResource.vendorRate.assignmentSite) {
             resourceDiff.vendorRate.assignmentSite = previousRevisionResource.vendorRate.assignmentSite || defaultValue;
         }
         if (resource.vendorRate.resourceType !== previousRevisionResource.vendorRate.resourceType) {
             resourceDiff.vendorRate.resourceType = previousRevisionResource.vendorRate.resourceType || defaultValue;
         }
         if (resource.vendorRate.resourceLocation !== previousRevisionResource.vendorRate.resourceLocation) {
             resourceDiff.vendorRate.resourceLocation = previousRevisionResource.vendorRate.resourceLocation || defaultValue;
         }
         if (resource.vendorRate.grade !== previousRevisionResource.vendorRate.grade) {
             resourceDiff.vendorRate.grade = previousRevisionResource.vendorRate.grade || defaultValue;
         }
         if (resource.vendorRate.unitPrice !== previousRevisionResource.vendorRate.unitPrice) {
             resourceDiff.vendorRate.unitPrice = previousRevisionResource.vendorRate.unitPrice;
             if (previousRevisionResource.vendorRate.unitPrice === undefined) {
                 resourceDiff.vendorRate.unitPrice = defaultValue;
             }
         }
         if (resource.vendorRate.perDiemRate !== previousRevisionResource.vendorRate.perDiemRate) {
             resourceDiff.vendorRate.perDiemRate = previousRevisionResource.vendorRate.perDiemRate;
             if (previousRevisionResource.vendorRate.perDiemRate === undefined) {
                 resourceDiff.vendorRate.perDiemRate = defaultValue;
             }
         }
         if (resource.vendorRate.currency !== previousRevisionResource.vendorRate.currency) {
             resourceDiff.vendorRate.currency = previousRevisionResource.vendorRate.currency || defaultValue;
         }
         resourceDiff.values = [];
         for (let i = 1; i <= 12; i++) {
             const current = resource.values.find((val) => {
                 return val.month === i;
             })
             const previous = previousRevisionResource.values.find((val) => {
                 return val.month === i;
             });

             if ((!current && previous)
             || (current && !previous)
             || (current && previous && current.value !== previous.value)) {
                 resourceDiff.values.push(previous || {month: i, value: 0});
             }
         }
         resourceDiff.tags = ""
         let tagsChanged = false;
         if (resource.tags) {
            resource.tags.forEach((tag) => {
                 if (!previousRevisionResource.tags || !previousRevisionResource.tags.find((prevtag) => {
                     return tag.label === prevtag.label;
                 })) {
                     tagsChanged = true;
                 }
             })
         }
         if (previousRevisionResource.tags) {
            previousRevisionResource.tags.forEach((tag) => {
                 if (!resource.tags || !resource.tags.find((prevtag) => {
                     return tag.label === prevtag.label;
                 })) {
                     tagsChanged = true;
                 }
             }) 
         }
         if (tagsChanged) {
            resourceDiff.tags = previousRevisionResource.tags ? previousRevisionResource.tags.map(tag => tag.label).join(', ') : "Default"
         }
     });
     differences.attachments = [];
     currentRevision.attachments.forEach((attachment) => {
         if (previousRevision.attachments.find((prevAttachment) => {
             return prevAttachment === attachment;
         })) {
             differences.attachments.push({name:attachment, type: "unchanged"})
         } else {
             differences.attachments.push({name: attachment, type: "added"});
         }
     });
     previousRevision.attachments.forEach((attachment) => {
         if(!currentRevision.attachments.find((currAttachment) => {
             return currAttachment === attachment
         })) {
             differences.attachments.push({name: attachment, type: "deleted"});
         }
     });
     return differences;
 }
 function formatDate(date) {
    if (!date) return ""; // Handle null, undefined, or empty string

    try {
        const sanitizedDate = date.split("Z")[0] + "Z"; // Remove and re-add "Z" to enforce UTC
        const currentDate = new Date(sanitizedDate);
        if (isNaN(currentDate.getTime())) return ""; // Handle invalid dates

        return (
            ("0" + currentDate.getUTCDate()).slice(-2) + "-" +
            ("0" + (currentDate.getUTCMonth() + 1)).slice(-2) + "-" +
            currentDate.getUTCFullYear()
        );
    } catch (error) {
        return ""; // Return empty string if an unexpected error occurs
    }
}

export default ChangelogRevisionComparator;